import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { locales } from '../locales';
const NotFound = () => {
  return (
    <>
      {' '}
      <Box
        sx={{
          paddingTop: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          fontSize: '24px',
          textAlign: 'center',
          // color: '#fff',
        }}>
        {locales[process.env.REACT_APP_LOCALE].pageNotFound}
      </Box>
    </>
  );
};

export default NotFound;
