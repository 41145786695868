import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { currencyFormat } from '../utils/currencyFormat';
import { Box, Tooltip, Typography } from '@mui/material';
import { randomIntFromInterval } from '../utils/randomNumber';
import { useEffect } from 'react';
import { useState } from 'react';
import { pagination } from '../utils/pagination';
import axios from 'axios';
import { getTop100Users, resetGetTop100Users } from '../redux/slices/user.slice';
import { PhotoCamera } from '@mui/icons-material';
import { locales } from '../locales';

export default function Top100Table({ onlyMonth = false }) {
  const [paginationData, setPaginationData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const {
    getTop100UsersState: { data: topList, loading: topLoading },
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (topList && topList?.length !== 0) {
      setPaginationData([...paginationData, ...topList]);
    } else {
      setPaginationData([]);
    }
  }, [topList]);

  useEffect(() => {
    // if (activePage < 5) {
    dispatch(getTop100Users({ page: activePage, onlyMonth }));
    // }
  }, [activePage]);
  useEffect(() => {
    return () => {
      dispatch(resetGetTop100Users());
      // setPaginationData([]);
      // setActivePage(1);
    };
  }, []);

  return (
    <Box sx={{ background: '#Fff' }}>
      <Box sx={{ mb: 2, fontSize: '14px' }}>{onlyMonth ? locales[process.env.REACT_APP_LOCALE].top100MonthText : locales[process.env.REACT_APP_LOCALE].top100Text}</Box>
      {paginationData?.length !== 0 ? (
        <div>
          <>
            {/* {value:"Asia Pacific - Eden"}
              {value:"Asia Pacific - Nova"}
              {value:"Asia Pacific - Ruby"}
              {value:"Asia Pacific - Babel"}
              {value:"Asia Pacific - Fate"}
              {value:"Asia Pacific - Gomap"}
              {value:"Asia Pacific - Pluto"}
              {value:"Asia Pacific - Sushi"}
              {value:"Asia Pacific - Venus"}
              {value:"Asia Pacific - Galaxy"}
              {value:"Asia Pacific - Memory"}
              {value:"Asia Pacific - Oxygen"}
              {value:"Asia Pacific - Sakura"}
              {value:"Asia Pacific - Seeker"}
              {value:"Asia Pacific - Shinya"}
              {value:"Asia Pacific - Stella"}
              {value:"Asia Pacific - Uranus"}
              {value:"Asia Pacific - Utopia"}
              {value:"Asia Pacific - Jupiter"}
              {value:"Asia Pacific - Sweetie"}
              {value:"Asia Pacific - Atlantis"}
              {value:"Asia Pacific - Daybreak"}
              {value:"Asia Pacific - Takoyaki"}
              {value:"Asia Pacific - Adventure"}
              {value:"Asia Pacific - Yggdrasil"}
              {value:"Asia Pacific - Cocoaiteruyo", },
              {value:"Asia Pacific - Food Fighter", },
              {value:"Asia Pacific - Mars", },
              {value:"Asia Pacific - Vega", },
              {value:"Asia Pacific - Neptune", },
              {value:"Asia Pacific - Tenpura", },
              {value:"Asia Pacific - Moon", },
              {value:"Asia Pacific - Mihashira", },
              {value:"Asia Pacific - Cocokonderu", },
              {value:"Europe - Aimanium", },
              {value:"Europe - Alintheus", },
              {value:"Europe - Andoes", },
              {value:"Europe - Anomora", },
              {value:"Europe - Astora", },
              {value:"Europe - Valstamm", },
              {value:"Europe - Blumous", },
              {value:"Europe - Celestialrise", },
              {value:"Europe - Cosmos", },
              {value:"Europe - Dyrnwyn", },
              {value:"Europe - Elypium", },
              {value:"Europe - Excalibur", },
              {value:"Europe - Espoir IV", },
              {value:"Europe - Estrela", },
              {value:"Europe - Ether", },
              {value:"Europe - Ex Nihilor", },
              {value:"Europe - Futuria", },
              {value:"Europe - Hephaestus", },
              {value:"Europe - Midgard", },
              {value:"Europe - Iter", },
              {value:"Europe - Kuura", },
              {value:"Europe - Lycoris", },
              {value:"Europe - Lyramiel", },
              {value:"Europe - Magenta", },
              {value:"Europe - Magia Przygoda Aida", },
              {value:"Europe - Olivine", },
              {value:"Europe - Omnium Prime", },
              {value:"Europe - Turmus", },
              {value:"Europe - Transport Hub", },
              {value:"Europe - The Lumina", },
              {value:"Europe - Seaforth Dock", },
              {value:"Europe - Slivercoast Lab", },
              {value:"Europe - Karst Cave", },
              {value:"North America - The Glades", },
              {value:"North America - Nightfall", },
              {value:"North America - Frontier", },
              {value:"North America - Libera", },
              {value:"North America - Solaris", },
              {value:"North America - Freedom-Oasis", },
              {value:"North America - The Worlds Between", },
              {value:"North America - Radiant", },
              {value:"North America - Tempest", },
              {value:"North America - New Era", },
              {value:"North America - Observer", },
              {value:"North America - Lunalite", },
              {value:"North America - Starlight", },
              {value:"North America - Myriad", },
              {value:"North America - Lighthouse", },
              {value:"North America - Oumuamua", },
              {value:"North America - Eternium Phantasy", },
              {value:"North America - Sol-lll", },
              {value:"North America - Silver Bridge", },
              {value:"North America - Azure Plane", },
              {value:"North America - Nirvana", },
              {value:"North America - Ozera", },
              {value:"North America - Polar", },
              {value:"South America - Orion", },
              {value:"South America - Luna Azul", },
              {value:"South America - Tiamat", },
              {value:"South America - Hope", },
              {value:"South America - Tanzanite", },
              {value:"South America - Calodesma Seven", },
              {value:"South America - Antlia", },
              {value:"South America - Pegasus", },
              {value:"South America - Phoenix", },
              {value:"South America - Centaurus", },
              {value:"South America - Cepheu", },
              {value:"South America - Columba", },
              {value:"South America - Corvus", },
              {value:"South America - Cygnus", },
              {value:"South America - Grus", },
              {value:"South America - Hydra", },
              {value:"South America - Lyra", },
              {value:"South America - Ophiuchus", },
              {value:"South America - Pyxis", },
              {value:"Southeast Asia - Phantasia", },
              {value:"Southeast Asia - Mechafield", },
              {value:"Southeast Asia - Ethereal Dream", },
              {value:"Southeast Asia - Odyssey", },
              {value:"Southeast Asia - Aestral-Noa", },
              {value:"Southeast Asia - Osillron", },
              {value:"Southeast Asia - Chandra", },
              {value:"Southeast Asia - Saeri", },
              {value:"Southeast Asia - Aeria", },
              {value:"Southeast Asia - Scarlet", },
              {value:"Southeast Asia - Gumi Gumi", },
              {value:"Southeast Asia - Fantasia", },
              {value:"Southeast Asia - Oryza", },
              {value:"Southeast Asia - Stardust", },
              {value:"Southeast Asia - Arcania", },
              {value:"Southeast Asia - Animus", },
              {value:"Southeast Asia - Mistilteinn", },
              {value:"Southeast Asia - Valhalla", },
              {value:"Southeast Asia - Illyrians", },
              {value:"Southeast Asia - Florione", },
              {value:"Southeast Asia - Oneiros", },
              {value:"Southeast Asia - Famtosyana", },
              {value:"Southeast Asia - Edenia", }, */}

            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
              <Table sx={{ minWidth: 0, overflow: 'hidden' }} aria-label="simple table">
                <TableHead sx={{ display: 'none' }}>
                  <TableRow sx={{ background: '#fff' }}>
                    <TableCell align="center" width="5px"></TableCell>
                    <TableCell align="center" width="55px">
                      ,{locales[process.env.REACT_APP_LOCALE].avatar}
                    </TableCell>
                    <TableCell align="center">,{locales[process.env.REACT_APP_LOCALE].nick}</TableCell>
                    {/* <TableCell align="center" width="50px", },</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginationData?.map((row, rowIndex) => (
                    <>
                      <TableRow
                        sx={{
                          background: '#fff',
                          '&:last-child td, &:last-child th': { border: 0 },
                          '& td': { borderBottom: '1px solid rgb(81, 81, 81,0.12)' },
                          '&:nth-child(1)': { borderBottom: '2px solid #edbc0b' },
                          '&:nth-child(2)': { borderBottom: '2px solid #C0C0C0' },
                          '&:nth-child(3)': { borderBottom: '2px solid #CD7F32' },
                        }}>
                        <TableCell width="5px" sx={{ paddingRight: '5px' }}>
                          {rowIndex + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          width="55px"
                          sx={{
                            // padding: '25px',
                            ...((rowIndex == 0 || rowIndex == 1 || rowIndex == 2) && {
                              transform: 'translate(-1px,4px)',
                              padding: '25px 16px',
                              position: 'relative',
                              '& img': {
                                transform: 'translate(2px, 2px) scale(107%)',
                              },
                              '&:after': {
                                content: '""',
                                position: 'absolute',
                                top: rowIndex == 0 ? '45%' : rowIndex == 1 ? '50%' : rowIndex == 2 ? '54%' : '',
                                left: rowIndex == 0 ? '51%' : rowIndex == 1 ? '50%' : rowIndex == 2 ? '50%' : '',
                                width: '74px',
                                height: '82px',
                                transform: 'translate(-50%,-50%)',
                                backgroundImage: rowIndex == 0 ? 'url(/Gold.svg)' : rowIndex == 1 ? 'url(/Silver.svg)' : rowIndex == 2 ? 'url(/Bronze.svg)' : '',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                              },
                            }),
                          }}>
                          {row?.user?.avatar ? (
                            <img style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${row?.user?.avatar}`} />
                          ) : (
                            <Box sx={{ position: 'relative', width: '50px', height: '50px', borderRadius: '50%', border: `2px solid rgb(81, 81, 81,0.12)` }}>
                              <PhotoCamera sx={{ fontSize: '24px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }} />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="left">{row?.user?.name}</TableCell>
                        {/* <TableCell width="30px" align="left", },
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {' '}
                            <img style={{ width: '25px', height: '25px', objectFit: 'contain' }} src={`/game-icon-${row?.typeGameId}.png`} />
                          </Box>
                        </TableCell> */}
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </div>
      ) : (
        <div></div>
      )}
    </Box>
  );
}
