import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { currencyFormat } from '../utils/currencyFormat';
import { Typography, Box } from '@mui/material';
import { getSupportList, resetGetSupportList } from '../redux/slices/user.slice';
import { PhotoCamera } from '@mui/icons-material';
import { locales } from '../locales';

export default function SupportListTable() {
  const {
    getSupportListState: { data: transList },
  } = useSelector((state) => state.user);
  const disaptch = useDispatch();
  React.useEffect(() => {
    disaptch(getSupportList());
    return () => {
      disaptch(resetGetSupportList());
    };
  }, []);

  return (
    <Box sx={{}}>
      {/*<Typography variant="h6" sx={{ mb: 1, textAlign: 'left', fontWeight: '600' }}></Typography>*/}
      <Box sx={{ mb: 2, fontSize: '14px' }}>{locales[process.env.REACT_APP_LOCALE].supportListText}</Box>
      {transList?.length !== 0 && transList ? (
        <div>
          <Typography variant="h6" sx={{ mb: 2, textAlign: 'left', fontWeight: '600' }}></Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{ display: 'none' }}>
                <TableRow>
                  <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].date}</TableCell>
                  <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].numPayment}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transList?.map((row) => (
                  <TableRow sx={{ backgroundColor: '#fff', '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left" width="55px" sx={{ paddingRight: '10px' }}>
                      {row?.user?.avatar ? (
                        <img style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${row?.user?.avatar}`} />
                      ) : (
                        <Box sx={{ position: 'relative', width: '50px', height: '50px', borderRadius: '50%', border: `2px solid rgb(81, 81, 81,0.12)` }}>
                          <PhotoCamera sx={{ fontSize: '24px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }} />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="left">{row?.user?.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
}
