import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReCAPTCHA from 'react-google-recaptcha';
import GoogleIcon from '@mui/icons-material/Google';
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment, makeStyles, Paper, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import { createUser, createUserReset, getUser, googleLoginReset, googleLoginUser, loginUser, loginUserReset, resetPasswordUserReset, userReset } from '../redux/slices/user.slice';
import { Navigate, useNavigate } from 'react-router';
import axios from 'axios';
import HomeLayout from '../components/HomeLayout';
import Footer from '../components/Footer';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ResetPassModal from '../components/ResetPassModal';
import SuccessModal from '../components/SuccessModal';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { locales } from '../locales';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Loader from '../components/Loader';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AuthPage({ meta }) {
  const [value, setValue] = React.useState(0);
  const [showLoginSucc, setShowLoginSucc] = React.useState(false);
  const loginForm = useForm({});
  const createForm = useForm({});

  const {
    createUserState: { loading: loadingCreate, data: dataCreate, error: errorCreate },
    googleLoginUserState: { loading: googleAuthLoading, data: googleAuthData, error: googleAuthError },
  } = useSelector((state) => state.user);
  const {
    loginUserState: { loading: loadingLogin, data: dataLogin, error: errorLogin },
  } = useSelector((state) => state.user);
  const {
    getUserState: { loading: loadingGet, data: dataGet, error: errorGet },
  } = useSelector((state) => state.user);
  const {
    resetPasswordUserState: { loading: resetLoading, data: resetData, error: resetError },
  } = useSelector((state) => state.user);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (dataLogin && !loadingLogin && !errorLogin) {
      dispatch(getUser());
      dispatch(loginUserReset());
      navigate('/account');
    }
  }, [dataLogin, loadingLogin, errorLogin]);

  React.useEffect(() => {
    if (dataCreate && !loadingCreate && !errorCreate) {
      setShowLoginSucc(true);
      // dispatch(getUser());
      dispatch(createUserReset());
      // navigate('/account');
    }
  }, [dataCreate, loadingCreate, errorCreate]);

  React.useEffect(() => {
    if (googleAuthData && !googleAuthLoading) {
      dispatch(getUser());
      dispatch(googleLoginReset());
      navigate('/account');
    }
  }, [googleAuthData, googleAuthLoading]);
  React.useEffect(() => {
    if (googleAuthError && !googleAuthLoading) {
      setGoogleLoading(false);
    }
  }, [googleAuthError]);
  React.useEffect(() => {
    createForm.register('gToken', { required: true });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(loginUser({ email: data.email, password: data.password }));
  };
  const onSubmitRegister = (data) => {
    dispatch(createUser({ email: data.email, password: data.password, name: data.name, referralCode: data.referralCode?.toUpperCase(), gToken: data.gToken }));
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showPasswordLogin, setShowPasswordLogin] = React.useState(false);
  const handleClickShowPasswordLogin = () => setShowPasswordLogin(!showPasswordLogin);
  const handleMouseDownPasswordLogin = () => setShowPasswordLogin(!showPasswordLogin);
  const [openResetPassModal, setOpenResetPassModal] = React.useState(false);
  const [openResetPassSuccModal, setOpenResetPassSuccModal] = React.useState(false);
  const handleHideResetSuccModal = () => {
    setOpenResetPassSuccModal(false);
  };
  const handleHideResetModal = () => {
    setOpenResetPassModal(false);
    dispatch(resetPasswordUserReset());
  };
  React.useEffect(() => {
    if (resetData) {
      setOpenResetPassModal(false);
      setOpenResetPassSuccModal(true);

      dispatch(resetPasswordUserReset());
    }
  }, [resetData]);
  function onChange(value) {
    createForm.setValue('gToken', value);
  }
  const handleLogin = (data) => {
    console.log(data);
    dispatch(googleLoginUser({ gToken: data?.credential, referralCode: createForm.getValues('referralCode') }));
  };
  const handleError = (data) => {
    setGoogleLoading(false);
  };
  const [googleLoading, setGoogleLoading] = React.useState(false);
  const handleRequest = (data) => {
    setGoogleLoading(true);
  };
  return !dataGet && !loadingGet && errorGet ? (
    <HomeLayout>
      {meta}
      <Paper>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
          {showLoginSucc && !errorCreate ? (
            <>
              {' '}
              <Box sx={{ padding: '250px 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <MailOutlineIcon sx={{ opacity: 0.5, fontSize: '40px', marginBottom: '15px' }} />
                <Box sx={{ textAlign: 'center', marginBottom: '20px', fontSize: '22px', maxWidth: '350px' }}>На ваш email отправлена ссылка для подтверждения регистрации</Box>
                <Box sx={{ marginBottom: '30px', opacity: '0.8', maxWidth: '600px', textAlign: 'center' }}>
                  Проверьте обязательно папки спам и оповещения, письма иногда приходят туда. Если не найдете письмо, то напишите нам в чат (кнопка снизу "Чат с поддержкой"). Письмо может прийти с задержкой до 2-3 минут.
                </Box>
                <Button
                  onClick={() => {
                    navigate('/');
                  }}
                  variant="contained">
                  На главную
                </Button>
              </Box>
            </>
          ) : (
            <>
              <div style={{ background: '#Fff', borderRadius: '10px', padding: '0 20px 20px 20px', marginTop: '100px', marginBottom: '50px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        justifyContent: 'center',
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    <Tab label={<Box sx={{ fontWeight: '500 !important' }}>{locales[process.env.REACT_APP_LOCALE].signIn}</Box>} {...a11yProps(0)} />
                    <Tab label={<Box sx={{ fontWeight: '500 !important' }}>{locales[process.env.REACT_APP_LOCALE].register}</Box>} {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div style={{ marginLeft: '-24px', marginRight: '-24px', display: 'flex', flexDirection: 'column' }}>
                    <Controller
                      control={loginForm.control}
                      rules={{ required: true }}
                      type="email"
                      name="email"
                      defaultValue=""
                      render={({ field }) => <TextField {...field} error={loginForm.formState.errors?.email} label={locales[process.env.REACT_APP_LOCALE].email} variant="outlined" size="small" />}
                    />
                    <Controller
                      control={loginForm.control}
                      rules={{ required: true }}
                      name="password"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          type={showPasswordLogin ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton disableRipple sx={{ p: 0, opacity: 0.5 }} onClick={handleClickShowPasswordLogin} onMouseDown={handleMouseDownPasswordLogin} aria-label="toggle password visibility">
                                  {showPasswordLogin ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={loginForm.formState?.errors?.password}
                          {...field}
                          sx={{ mt: '16px' }}
                          label={locales[process.env.REACT_APP_LOCALE].password}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                    <Button
                      onClick={() => {
                        setOpenResetPassModal(true);
                      }}
                      disableRipple
                      sx={{
                        textUnderlineOffset: '2px',
                        textDecoration: 'underline',
                        color: 'primary.main',
                        width: 'min-content',
                        p: 0,
                        whiteSpace: 'nowrap',
                        textTransform: 'initial',
                        // color: 'rgba(0, 0, 0, 0.87)',
                        mt: 0.5,
                        '&:hover': {
                          background: 'transparent',
                        },
                      }}>
                      {locales[process.env.REACT_APP_LOCALE].forgotPassword}
                    </Button>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label={locales[process.env.REACT_APP_LOCALE].rememberMail} />
                    </FormGroup>
                    <Button disabled={loadingLogin} variant="contained" sx={{ mt: '8px' }} onClick={loginForm.handleSubmit(onSubmit)}>
                      {locales[process.env.REACT_APP_LOCALE].signIn}
                    </Button>

                    {!loadingLogin && !dataLogin && errorLogin && (
                      <Box sx={{ fontSize: '14px', color: 'error.main', mt: '8px', mx: 'auto' }}>{errorLogin?.error === 'ACCOUNT_NOT_ACTIVE' ? locales[process.env.REACT_APP_LOCALE].acceptEmail : locales[process.env.REACT_APP_LOCALE].incorrectLoginOrPass} </Box>
                    )}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div style={{ marginLeft: '-24px', marginRight: '-24px', display: 'flex', flexDirection: 'column' }}>
                    <Controller
                      control={createForm.control}
                      rules={{
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address',
                        },
                      }}
                      name="email"
                      defaultValue=""
                      render={({ field }) => <TextField {...field} error={createForm.formState.errors?.email} label={locales[process.env.REACT_APP_LOCALE].email} variant="outlined" size="small" autoComplete="off" />}
                    />
                    <Controller
                      control={createForm.control}
                      rules={{ required: true }}
                      name="password"
                      defaultValue=""
                      autoComplete="off"
                      render={({ field }) => (
                        <TextField
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton disableRipple sx={{ p: 0, opacity: 0.5 }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} aria-label="toggle password visibility">
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={createForm.formState?.errors?.password}
                          {...field}
                          sx={{ mt: '16px' }}
                          label={locales[process.env.REACT_APP_LOCALE].password}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                    <Controller
                      autoComplete="off"
                      control={createForm.control}
                      rules={{
                        required: true,
                        validate: (val) => {
                          if (createForm.watch('password') != val) {
                            return locales[process.env.REACT_APP_LOCALE].passwordNotEqual;
                          }
                        },
                      }}
                      name="repeatPassword"
                      defaultValue=""
                      render={({ field }) => <TextField type={showPassword ? 'text' : 'password'} error={createForm.formState?.errors?.repeatPassword} {...field} sx={{ mt: '16px' }} label={locales[process.env.REACT_APP_LOCALE].repeatPassword} variant="outlined" size="small" />}
                    />
                    <Controller
                      control={createForm.control}
                      rules={{ required: true }}
                      name="name"
                      defaultValue=""
                      render={({ field }) => <TextField error={createForm.formState?.errors?.name} {...field} sx={{ mt: '16px' }} label={locales[process.env.REACT_APP_LOCALE].name} variant="outlined" size="small" autoComplete="off" />}
                    />
                    <Controller
                      control={createForm.control}
                      rules={{ required: false }}
                      name="referralCode"
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{
                            mt: '16px',
                          }}
                          inputProps={{ maxLength: 5, sx: { textTransform: 'uppercase !important' } }}
                          label={locales[process.env.REACT_APP_LOCALE].promoCodeNotReq}
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                        />
                      )}
                    />
                    <Button disabled={loadingLogin} variant="contained" sx={{ mt: '16px', mb: '8px' }} onClick={createForm.handleSubmit(onSubmitRegister)}>
                      {locales[process.env.REACT_APP_LOCALE].register}
                    </Button>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY} onChange={onChange} />

                    {((!loadingCreate && !dataCreate && errorCreate) || createForm.formState?.errors?.gToken) && (
                      <Box sx={{ fontSize: '14px', color: 'error.main', mt: '8px', mx: 'auto' }}>
                        {errorCreate?.error === 'CAPTHA_ERROR' || createForm.formState?.errors?.gToken
                          ? locales[process.env.REACT_APP_LOCALE].reCaptchaFailed
                          : errorCreate?.error === 'USER_EXIST'
                          ? locales[process.env.REACT_APP_LOCALE].emailExist
                          : errorCreate?.error === 'NOT_FOUND_REFERRAL_CODE'
                          ? locales[process.env.REACT_APP_LOCALE].incorrectPromoCode
                          : locales[process.env.REACT_APP_LOCALE].unexpectedError}
                      </Box>
                    )}
                    {showLoginSucc && !errorCreate && <Box sx={{ fontSize: '14px', color: 'success.main', mt: '8px', mx: 'auto', textAlign: 'center' }}>{locales[process.env.REACT_APP_LOCALE].linkSendingToEmail}</Box>}
                  </div>
                </TabPanel>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-16px' }}>
                  <Box sx={{ mb: '8px' }}>{locales[process.env.REACT_APP_LOCALE].or}</Box>
                  <Box {...(value == 1 && { id: 'google-auth-box' })} style={{ marginTop: '6px' }}>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_API_KEY}>
                      <GoogleLogin buttonText={locales[process.env.REACT_APP_LOCALE].signInGoogle} onRequest={handleRequest} onSuccess={handleLogin} onFailure={handleError} />
                    </GoogleOAuthProvider>{' '}
                  </Box>
                  {!googleAuthData && !googleAuthLoading && googleAuthError && (
                    <Box sx={{ fontSize: '14px', color: 'error.main', mt: '8px', mx: 'auto' }}>
                      {googleAuthError?.error === 'USER_EXIST' ? locales[process.env.REACT_APP_LOCALE].emailAlreadyExist : googleAuthError?.error === 'NOT_FOUND_REFERRAL_CODE' ? locales[process.env.REACT_APP_LOCALE].incorrectPromoCode : locales[process.env.REACT_APP_LOCALE].unexpectedError}
                    </Box>
                  )}
                </Box>
              </div>
              {(loadingCreate || loadingLogin) && <Loader />}
              <ResetPassModal open={openResetPassModal} onClose={handleHideResetModal} />
              <SuccessModal open={openResetPassSuccModal} text={locales[process.env.REACT_APP_LOCALE].newPasswordSendToMail} onClose={handleHideResetSuccModal} />
            </>
          )}
        </Box>
        {(googleLoading || googleAuthLoading) && <Loader />}
      </Paper>
    </HomeLayout>
  ) : (
    <Navigate to="/account" />
  );
}
export default AuthPage;
