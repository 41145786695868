import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import ProfileAvatar from './ProfileAvatar';
import { Delete, VisibilityOff, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { changeNickname, getRankUserFromTop, getUser, resetAvatar, switchShowInSupportList, switchShowInTop100, telegramBinding } from '../redux/slices/user.slice';
import { locales } from '../locales';
import TelegramLoginButton from 'telegram-login-button';
const SettingPage = () => {
  const {
    switchShowInTop100State: { data: switchTop100Data, loading: switchTop100Loading },
    switchShowInSupportListState: { data: switchSupportListData, loading: switchSupportListLoading },
    getUserState: { loading, data: userData, error },
    changeNicknameState: { loading: changeNickNameLoading, data: changeNickNameData },
    resetAvatarState: { loading: resetAvatarLoading, data: resetAvatarData },
    getRankUserFromTopState: { data: rankData, loading: rankLoading },
  } = useSelector((state) => state.user);
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue('username', userData.name);
  }, []);
  const dispatch = useDispatch();
  const onSubmitChangeNick = (data) => {
    dispatch(changeNickname({ newNickname: data.username }));
  };
  useEffect(() => {
    dispatch(getRankUserFromTop());
  }, []);
  return (
    <>
      <Box sx={{ pb: 3, mb: 2, borderBottom: '1px solid rgb(81, 81, 81,0.12)' }}>
        <Typography variant="h6" sx={{ mb: 1, textAlign: 'left', fontWeight: '600' }}>
          {locales[process.env.REACT_APP_LOCALE].settingAccount}
        </Typography>
        <Box>
          <Box sx={{ fontWeight: '600 !important', marginBottom: '10px' }}>{locales[process.env.REACT_APP_LOCALE].nickOnSite}</Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <Controller control={control} rules={{ required: true }} name="username" render={({ field }) => <TextField {...field} error={errors?.username} variant="outlined" size="small" disabled={!userData?.nicknameScore || changeNickNameLoading || changeNickNameData} />} />
            <Button onClick={handleSubmit(onSubmitChangeNick)} variant="outlined" sx={{ ml: 1, position: 'relative', height: '40px', width: '89px', '&:disabled': { opacity: 0.5 } }} disabled={!userData?.nicknameScore || changeNickNameLoading || changeNickNameData}>
              {changeNickNameLoading ? (
                <Box sx={{ transform: 'scale(0.5) translate(-50%,-50%)', position: 'absolute', top: '23%', left: '40%' }}>
                  {' '}
                  <CircularProgress color="inherit" />
                </Box>
              ) : (
                locales[process.env.REACT_APP_LOCALE].change
              )}
            </Button>
          </Box>
          <Box sx={{ marginTop: '10px' }}>{userData?.nicknameScore ? locales[process.env.REACT_APP_LOCALE].choiceNickText : locales[process.env.REACT_APP_LOCALE].areYourChoiceNick}</Box>
        </Box>
      </Box>{' '}
      <Box sx={{ pb: 3, mb: 2, borderBottom: '1px solid rgb(81, 81, 81,0.12)' }}>
        <Box sx={{ fontWeight: '600 !important', marginBottom: '10px' }}>{locales[process.env.REACT_APP_LOCALE].avatar}</Box>
        <Box sx={{ marginBottom: '20px' }}>{locales[process.env.REACT_APP_LOCALE].installAvatarText}</Box>
        <ProfileAvatar disabled={resetAvatarLoading} />

        <Button
          variant="outlined"
          sx={{ width: '170px', height: '36px', '&:disabled': { opacity: 0.5 } }}
          disabled={resetAvatarLoading}
          size="medium"
          startIcon={!resetAvatarLoading && <Delete />}
          onClick={() => {
            dispatch(resetAvatar());
          }}>
          {resetAvatarLoading ? (
            <Box sx={{ transform: 'scale(0.5) translate(-50%,-50%)', position: 'absolute', top: '19%', left: '45%' }}>
              {' '}
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            locales[process.env.REACT_APP_LOCALE].removeAvatar
          )}
        </Button>
      </Box>
      <Box>
        <Box sx={{ fontWeight: '600 !important', marginBottom: '10px' }}>{locales[process.env.REACT_APP_LOCALE].ratings}</Box>
        <Box sx={{ marginBottom: '20px' }}>{locales[process.env.REACT_APP_LOCALE].yourCanHideNick}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              dispatch(switchShowInTop100());
            }}
            startIcon={switchTop100Loading ? <></> : (switchTop100Data ? switchTop100Data?.showInTop100 : userData?.showInTop100) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            variant="outlined"
            sx={{ width: '210px', position: 'relative', height: '40px', '&:disabled': { opacity: 0.5 } }}
            disabled={switchTop100Loading}>
            {switchTop100Loading ? (
              <Box sx={{ transform: 'scale(0.5) translate(-50%,-50%)', position: 'absolute', top: '19%', left: '45%' }}>
                {' '}
                <CircularProgress color="inherit" />
              </Box>
            ) : (switchTop100Data ? switchTop100Data?.showInTop100 : userData?.showInTop100) ? (
              locales[process.env.REACT_APP_LOCALE].hideInTop
            ) : (
              locales[process.env.REACT_APP_LOCALE].showInTop
            )}
          </Button>
        </Box>
        {!isNaN(rankData) && !rankLoading && (
          <Box sx={{ mt: 1 }}>
            {locales[process.env.REACT_APP_LOCALE].yourInRating} {rankData}
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: '100px' }}>
          <Button
            onClick={() => {
              dispatch(switchShowInSupportList());
            }}
            startIcon={switchSupportListLoading ? <></> : (switchSupportListData ? switchSupportListData?.showInTopSupport : userData?.showInTopSupport) ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            variant="outlined"
            sx={{ width: '280px', position: 'relative', height: '40px', '&:disabled': { opacity: 0.5 } }}
            disabled={switchSupportListLoading}>
            {switchSupportListLoading ? (
              <Box sx={{ transform: 'scale(0.5) translate(-50%,-50%)', position: 'absolute', top: '19%', left: '45%' }}>
                {' '}
                <CircularProgress color="inherit" />
              </Box>
            ) : (switchSupportListData ? switchSupportListData?.showInTopSupport : userData?.showInTopSupport) ? (
              locales[process.env.REACT_APP_LOCALE].hideInSupport
            ) : (
              locales[process.env.REACT_APP_LOCALE].showInSupport
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SettingPage;
