import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import HomeLayout from '../components/HomeLayout';
import { Box, Container, Typography } from '@mui/material';
import Footer from '../components/Footer';
import { locales } from '../locales';
const GuarantePage = ({ meta }) => {
  return (
    <>
      {meta}
      <HomeLayout>
        <Container sx={{}}>
          <Box sx={{ paddingTop: 4, mb: '30px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
              <Typography variant="h4" sx={{ mt: 2 }}>
                {locales[process.env.REACT_APP_LOCALE].guarantee}
              </Typography>
              <Typography variant="body1" sx={{ background: '#Fff', borderRadius: '10px', padding: '20px', maxWidth: '100%', mt: 2 }}>
                {locales[process.env.REACT_APP_LOCALE].guaranteText}
              </Typography>
            </Box>
          </Box>
        </Container>
      </HomeLayout>
    </>
  );
};

export default GuarantePage;
