import React from 'react';
import Editor from '@monaco-editor/react';
import { Box } from '@mui/material';

const CodeEditor = ({ label, form, name }) => {
  const value = form.watch(name);
  const error = form.formState?.errors?.[name];
  return (
    <Box
      sx={{
        mb: 2,
        '& section': { minHeight: '300px', height: 'auto !important' },
      }}>
      <Box
        sx={{
          ...(error && { color: '#d32f2f' }),
          fontWeight: '600',
          mb: 1,
        }}>
        {label}
      </Box>
      <Box sx={{ ...(error && { border: '1px solid #d32f2f' }) }}>
        <Editor
          className="monaco-custom"
          value={value}
          height="90vh"
          defaultLanguage="html"
          defaultValue="Type content article here ..."
          onChange={(val) => {
            form.setValue(name, val);
          }}
        />
      </Box>
    </Box>
  );
};

export default CodeEditor;
