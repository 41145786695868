import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getVkReviews = createAsyncThunk('vkReviews/getVkReviews', (page, { rejectWithValue }) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/comments?page=${page}`)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getTypePaymentList = createAsyncThunk('vkReviews/getTypePaymentList', (page, { rejectWithValue }) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/type-payment/list`)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
const vkReviewsInitialState = {
  activeNavMenu: { left: 0, width: '56.15px' },
  getVkReviewsState: {
    data: null,
    loading: false,
    error: null,
  },
  getTypePaymentListState: {
    data: null,
    loading: false,
    error: null,
  },
};

const vkReviewsSlice = createSlice({
  name: 'vkReviews',
  initialState: vkReviewsInitialState,
  reducers: {
    setActiveNavMenu: (state, { payload }) => {
      state.activeNavMenu = payload;
    },
    resetVkReviews: (state, { payload }) => {
      state.getVkReviewsState = vkReviewsInitialState.getVkReviewsState;
    },
  },
  extraReducers: {
    [getVkReviews.pending]: (state, action) => {
      state.getVkReviewsState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [getVkReviews.fulfilled]: (state, action) => {
      state.getVkReviewsState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getVkReviews.rejected]: (state, action) => {
      state.getVkReviewsState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getTypePaymentList.pending]: (state, action) => {
      state.getTypePaymentListState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [getTypePaymentList.fulfilled]: (state, action) => {
      state.getTypePaymentListState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getTypePaymentList.rejected]: (state, action) => {
      state.getTypePaymentListState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
  },
});
export const { resetVkReviews, setActiveNavMenu } = vkReviewsSlice.actions;
export const vkReviewsReducer = vkReviewsSlice.reducer;
