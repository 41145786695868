import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box, Tooltip } from '@mui/material';
import { Bolt } from '@mui/icons-material';
const GameTabs = ({ list = [], isInner, withBoost, setValue, value }) => {
  // const list = withBoost
  //   ? [

  //     ]
  //   : [
  //       {
  //         tooltip: 'Поступит моментально',
  //         label: (
  //           <>
  //             <Bolt sx={{ marginBottom: '-6px', transition: 'all 0.3s', color: value ? '#ffd54f' : 'rgba(0,0,0,0.2)' }} /> С бустом
  //           </>
  //         ),
  //         value: true,
  //       },
  //     ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyItems: 'center' }}>
        {list?.map((item) => (
          <Tooltip title={item?.tooltip} placement="bottom">
            <Box
              onClick={() => {
                setValue('isBoost', item?.value);
              }}
              sx={
                isInner
                  ? {
                      '& svg': { color: value ? '#ffd54f' : 'rgba(0,0,0,0.2)' },
                      padding: '4px 7px 0px 7px',
                      userSelect: 'none',
                      cursor: 'pointer',
                      fontWeight: '600 !important',
                      borderRadius: '4px',
                      marginRight: '15px',
                      opacity: '0.7',
                      fontSize: '14px',
                      ...(value == item?.value && { opacity: '1', background: 'rgba(0, 0, 0, 0.10)' }),
                    }
                  : {
                      '& svg': { color: value ? '#ffd54f' : 'rgba(0,0,0,0.2)' },
                      whiteSpace: 'nowrap',
                      marginRight: { xs: '15px', mob: '25px' },
                      background: 'rgba(0, 0, 0, 0.10)',
                      padding: { xs: '8px 20px', mob: '10px 35px' },
                      borderRadius: '6px 6px 0 0 ',
                      fontSize: { xs: '15px', mob: '16px' },
                      fontWeight: '600 !important',
                      cursor: 'pointer',
                      userSelect: 'none',
                      ...(value == item?.value && { background: '#fff' }),
                    }
              }>
              {item?.label}
            </Box>
          </Tooltip>
        ))}
      </Box>
    </>
  );
};

export default GameTabs;
