import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { ButtonBase, Tooltip } from '@mui/material';
const MenuBtn = ({ children, onClick, tooltip, customStyle }) => {
  return (
    <Tooltip title={tooltip} placement="bottom">
      <ButtonBase
        onClick={onClick}
        sx={{
          width: '34px',
          height: '34px',
          borderRadius: '50%',
          backgroundColor: '#181818',
          transition: '0.3s background',
          '&:hover': {
            backgroundColor: '#333',
          },
          ...(customStyle && customStyle),
        }}>
        {children}
      </ButtonBase>
    </Tooltip>
  );
};

export default MenuBtn;
