import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { blue, grey } from '@mui/material/colors';
import { Box, DialogActions, DialogContent, DialogContentText, IconButton, Input, InputAdornment, Tab, Tabs, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { currencyFormat } from '../utils/currencyFormat';
import { useDispatch, useSelector } from 'react-redux';
import { a11yProps, TabPanel } from '../pages/Home';
import { initPaymentCard, initPaymentCardSecond, setAddedCart, setUpdateCartEmpty } from '../redux/slices/user.slice';
import TransitionDialog from './TransitionDialog';
import { locales } from '../locales';
import { useNavigate } from 'react-router';
// import { paymentData } from './PaymentCardModal';
export default function PaymentModal(props) {
  const { onClose, selectedValue, open, typePay } = props;
  const defaultValues = {
    price: '',
  };
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({ defaultValues });
  const {
    getTypePaymentListState: { data: paymentData },
  } = useSelector((state) => state.app);
  const handleClose = () => {
    onClose(selectedValue);
  };
  const navigate = useNavigate();
  const [sum, setSum] = React.useState(5);
  const [cardSum, setCardSum] = React.useState(100);
  const [rate, setRate] = React.useState(null);
  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/payment/rate`).then((data) => setRate(data.data));
  }, []);
  const {
    getUserState: { data: user },
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  return (
    <Dialog
      TransitionComponent={TransitionDialog}
      maxWidth="sm"
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '500px', // Set your width here
        },
      }}>
      <DialogTitle sx={{ pb: '8px' }}>{locales[process.env.REACT_APP_LOCALE].topUpBalance}</DialogTitle>
      <form method="POST" action="https://merchant.webmoney.ru/lmi/payment_utf.asp?at=authtype_16" accept-charset="utf-8">
        <DialogContent sx={{ pb: '16px', pt: '0px', pb: '24px' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', height: 'auto' }}></Box>
          {typePay === 1 ? (
            <Box styleBox={{ p: 1, height: '91px' }}>
              <TextField
                onChange={(e) => {
                  setCardSum(e.target.value);
                }}
                onBlur={(e) => {
                  const valSum = parseInt(e.target.value);
                  if (valSum < 100 || !valSum || isNaN(valSum)) {
                    setCardSum(100);
                  }

                  if (valSum > 75000) {
                    setCardSum(75000);
                  }
                }}
                name="LMI_PAYMENT_AMOUNT"
                prefix={'sdf'}
                value={cardSum}
                type="number"
                inputProps={{ min: 100, style: { textAlign: 'center', paddingLeft: '24px', color: '#fff' } }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #fff !important',
                  },
                  '& .MuiOutlinedInput-root': { backgroundColor: `${grey[900]} !important`, fontSize: '20px' },
                  mt: '16px',
                  mx: 'auto',
                  display: 'flex',
                  maxWidth: '120px',
                  textAlign: 'center',
                  color: '#fff',
                  '& .MuiInputLabel-root ': {
                    fontSize: '20px',
                    // transform: 'translateY(-4px)',
                    top: '-2px',
                    color: '#fff !important',
                  },
                }}
                label={locales[process.env.REACT_APP_LOCALE].sum}
                variant="outlined"
                size="small"
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ '& .MuiTypography-root': { color: '#fff' } }}>
                      ₽
                    </InputAdornment>
                  ),
                }}
              />
              <div className="" style={{ marginTop: '8px', display: 'flex', alignItems: 'center', fontSize: '12px', justifyContent: 'center' }}>
                <p style={{ opacity: '0.6', margin: 0 }}>{locales[process.env.REACT_APP_LOCALE].min}</p> <b>&nbsp;{currencyFormat(100)}</b>
                <p style={{ opacity: '0.6', margin: 0 }}>, {locales[process.env.REACT_APP_LOCALE].max}</p> <b>&nbsp;{currencyFormat(75000)}</b>
              </div>
            </Box>
          ) : (
            <Box styleBox={{ p: 1, height: '91px' }}>
              <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ mt: '16px', color: '#1976d2' }}
                  onClick={() => {
                    if (sum !== 5) setSum(sum - 5);
                  }}>
                  <MinusIcon />
                </IconButton>
                <TextField
                  onChange={(e) => {
                    e.stopPropagation();
                  }}
                  name="LMI_PAYMENT_AMOUNT"
                  prefix={'sdf'}
                  value={sum}
                  type="number"
                  inputProps={{ min: 0, style: { color: '#fff', textAlign: 'center', paddingLeft: '24px' } }}
                  sx={{
                    '& .MuiTypography-root': { color: '#fff' },
                    color: '#Fff',
                    mt: '16px',
                    maxWidth: '100px',
                    textAlign: 'center',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #1976d2 !important',
                    },

                    mt: '16px',
                    mx: 'auto',
                    display: 'flex',
                    maxWidth: '100px',
                    textAlign: 'center',
                    color: '#fff',
                    '& .MuiInputLabel-root': {
                      color: '#1976d2 !important',
                    },
                  }}
                  label={locales[process.env.REACT_APP_LOCALE].sum}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">$</InputAdornment>,
                  }}
                />{' '}
                <IconButton
                  sx={{ mt: '16px', color: '#1976d2' }}
                  onClick={() => {
                    if (sum !== 300) setSum(sum + 5);
                  }}>
                  <AddIcon />
                </IconButton>
              </div>
              <div className="" style={{ marginTop: '8px', display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                <p style={{ opacity: '0.6', margin: 0 }}>{locales[process.env.REACT_APP_LOCALE].willBeCredited}&nbsp;</p>
                <b>{currencyFormat(parseFloat(rate?.replace(',', '.')) * sum)}</b>
              </div>
            </Box>
          )}{' '}
        </DialogContent>

        <DialogActions style={{ padding: '0 16px 20px 16px', justifyContent: 'center' }}>
          <div style={{}}>
            {/* <Button onClick={handleClose} autoFocus variant="text">
              {locales[process.env.REACT_APP_LOCALE].close}
            </Button> */}
            {typePay === 2 ? (
              <Button type="submit" autoFocus variant="contained">
                {locales[process.env.REACT_APP_LOCALE].topUp}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  let cartData = JSON.parse(localStorage.getItem('cart'));
                  if (cartData instanceof Array) {
                    const findInCartExist = cartData?.find((itemCart) => itemCart?.packageId === 0 && parseInt(itemCart?.count) > 0);
                    if (findInCartExist) {
                      findInCartExist.count = parseInt(findInCartExist.count) + cardSum;
                    } else {
                      cartData.push({ packageId: 0, count: cardSum });
                    }

                    localStorage.setItem('cart', JSON.stringify(cartData));
                  } else {
                    const newCart = [{ packageId: 0, count: cardSum }];
                    localStorage.setItem('cart', JSON.stringify(newCart));
                  }
                  dispatch(setUpdateCartEmpty());
                  dispatch(setAddedCart(true));
                  setTimeout(() => {
                    dispatch(setAddedCart(false));
                  }, 1000);
                  handleClose();
                  navigate('/cart');
                }}
                autoFocus
                variant="contained">
                {locales[process.env.REACT_APP_LOCALE].topUp}
              </Button>
            )}
          </div>
        </DialogActions>

        <input type="hidden" name="token" value={localStorage.getItem('token')} />
        <input type="hidden" name="LMI_PAYMENT_DESC" value={`${locales[process.env.REACT_APP_LOCALE].refill} №${user?.id}`} />
        <input type="hidden" name="LMI_PAYMENT_NO" value="1234" />
        <input type="hidden" name="LMI_PAYEE_PURSE" value="Z250362075889" />
        {/* <input type="hidden" name="LMI_SIM_MODE" value="0" /> */}
      </form>
    </Dialog>
  );
}
