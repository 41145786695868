import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import '../styles/GameCard.scss';
import clsx from 'clsx';

import { currencyFormat } from '../utils/currencyFormat';
import { locales } from '../locales';
import { Button } from '@mui/material';
import { priceWithDiscount } from '../utils/priceWithDiscount';
const GameCard = ({ discount, img, disabled, price, gameId, label, value, onClickCard, onClickCart, onClick = () => {}, active, full, isChatPackage }) => {
  const totalPrice = discount ? priceWithDiscount(price, discount) : price;
  return (
    <div className={`game-card ${active && 'game-card--active'}`} onClick={onClickCard} style={{ ...(full && { backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundPosition: '50% 50%' }) }}>
      {!full ? <img src={img} alt="" className="game-card__img" /> : <div style={{ height: '110.27px' }}></div>}
      {discount ? <div className="game-card__discount">{discount}% дисконт</div> : <></>}

      <div className="game-card__middle">
        {!isChatPackage && (
          <img
            src={`/game-icon-${gameId}.png`}
            style={{
              objectFit: 'contain',
              display: 'block',
              width: '22px',
              height: '22px',
            }}
          />
        )}

        {label}
      </div>
      <div className="game-card__bottom">
        <div className="game-card__price-box">
          <div className={clsx('game-card__price', discount && totalPrice > 1000 && totalPrice < 10000 && 'game-card__price-big', totalPrice >= 10000 && 'game-card__price-very-big')}>
            {isChatPackage && 'от '}
            {currencyFormat(totalPrice)}
          </div>
          {discount ? <div className={clsx('game-card__old-price', totalPrice >= 10000 && 'game-card__old-price-very-big')}>{currencyFormat(price)}</div> : <></>}
        </div>

        <div className="game-card__btn-box">
          <Button size="small" variant="contained" color={isChatPackage ? 'success' : 'error'} onClick={onClickCart}>
            {isChatPackage ? locales[process.env.REACT_APP_LOCALE].order : locales[process.env.REACT_APP_LOCALE].buy}
          </Button>
          {/* <button className={isChatPackage ? 'game-card__chat' : 'game-card__cart'} onClick={onClickCart}>
            {isChatPackage ? locales[process.env.REACT_APP_LOCALE].order : locales[process.env.REACT_APP_LOCALE].buy}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default GameCard;
