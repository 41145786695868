import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { currencyFormat } from '../utils/currencyFormat';
import { Box, Tooltip, Typography } from '@mui/material';
import { randomIntFromInterval } from '../utils/randomNumber';
import { useEffect } from 'react';
import { useState } from 'react';
import { pagination } from '../utils/pagination';
import axios from 'axios';
import { locales } from '../locales';

function getStatus(status) {
  switch (status) {
    case 'processing':
    case 'in-progress':
      return locales[process.env.REACT_APP_LOCALE].inProgress;
    case 'completed':
      return locales[process.env.REACT_APP_LOCALE].completed;
    case 'sending':
      return locales[process.env.REACT_APP_LOCALE].sending;
    case 'refunded-success':
      return locales[process.env.REACT_APP_LOCALE].refundedSuccess;
    case 'in-error-card':
    case 'in-error':
    case 'refunded':
      return (
        <div>
          {locales[process.env.REACT_APP_LOCALE].sendError} <br />{' '}
          <a class="link" href="https://donate-gold.ru/support" target="_blank">
            {locales[process.env.REACT_APP_LOCALE].writeToUs}
          </a>
        </div>
      );
    default:
      return status;
  }
}

export default function TrancTable({ title }) {
  const [paginationData, setPaginationData] = useState(null);
  console.log(paginationData);
  const {
    getTransactionsState: { data: transList },
  } = useSelector((state) => state.transaction);
  const [gameList, setGameList] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/servers/game-4`).then((res) => {
      let serverListGame4 = res.data;
      setGameList(serverListGame4);
    });
  }, []);
  console.log(gameList);
  useEffect(() => {
    if (transList) {
      const dataNewPage = pagination(transList, 10, 1);
      setPaginationData({ data: dataNewPage, pages: Math.ceil(transList.length / 10), page: 1 });
    }
  }, [transList]);
  // useEffect(() => {
  //   if (paginationData && transList) {

  //   }
  // }, [paginationData]);

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1, textAlign: 'left', fontWeight: '600' }}>
        {title}
      </Typography>
      <Box sx={{ mb: 2, fontSize: '14px' }}>{locales[process.env.REACT_APP_LOCALE].ifErrorText}</Box>
      <Box sx={{ mb: 2, fontSize: '14px' }}>{locales[process.env.REACT_APP_LOCALE].forSecurityText}</Box>
      {paginationData?.data?.length !== 0 && paginationData?.data ? (
        <div>
          {paginationData?.data && (
            <>
              <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <Table sx={{ minWidth: 0 }} aria-label="simple table">
                  <TableHead sx={{ display: { xs: 'none', mobile: 'table-header-group' } }}>
                    <TableRow>
                      <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].game}</TableCell>
                      <TableCell align="center">ID</TableCell>
                      <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].nick}</TableCell>
                      <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].pack}</TableCell>
                      <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].server}</TableCell>
                      <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].status}</TableCell>
                      <TableCell align="center">{locales[process.env.REACT_APP_LOCALE].price}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginationData.data?.map((row) => (
                      <>
                        <TableRow key={row?.nickname} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { whiteSpace: 'nowrap' }, '& td': { paddingBottom: '0', borderBottom: 0, paddingTop: '8px' } }}>
                          <TableCell align="left" sx={{ whiteSpace: 'nowrap', paddingLeft: 0 }}>
                            {row?.typeGame?.name}
                          </TableCell>
                          <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                            {row?.nickid}
                          </TableCell>
                          <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                            {row?.nickname}
                          </TableCell>
                          <TableCell align="center" sx={{ display: { xs: 'none', mobile: 'table-cell' } }}>
                            {row?.packageName}
                          </TableCell>{' '}
                          <TableCell align="center" sx={{ display: { xs: 'none', mobile: 'table-cell' } }}>
                            {row?.typeGameId != 4 ? (
                              <>{row?.serverid === 2001 ? 'Asia' : row?.serverid === 2011 ? 'NA and EU' : row?.serverid == 1 ? 'America' : row?.serverid == 2 ? 'Europe' : row?.serverid == 3 ? 'Asia' : row?.serverid == 4 ? 'TW, HK, MO' : row?.serverid == 12 ? 'Global' : row?.serverid}</>
                            ) : (
                              <>{gameList?.length == 0 ? <div>{locales[process.env.REACT_APP_LOCALE].loading}</div> : <>{gameList?.find((gameItem) => gameItem.serverId == row?.serverid)?.name}</>}</>
                            )}
                          </TableCell>
                          <TableCell align="center" sx={{ height: '57px', display: { xs: 'none', mobile: 'table-cell' } }}>
                            {getStatus(row.status)}
                          </TableCell>
                          <TableCell align="center" sx={{ display: { xs: 'none', mobile: 'table-cell' } }}>
                            {currencyFormat(row?.price)}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ transform: 'translateY(-8px)', '& td': { paddingBottom: '0', borderBottom: 0, paddingTop: '0px', whiteSpace: 'nowrap' }, display: { xs: 'table-row', mobile: 'none' } }}>
                          <TableCell sx={{ paddingLeft: 0 }} align="left">
                            {row?.packageName}
                          </TableCell>{' '}
                          <TableCell align="center">
                            {row?.typeGameId != 4 ? (
                              <>{row?.serverid === 2001 ? 'Asia' : row?.serverid === 2011 ? 'NA and EU' : row?.serverid == 1 ? 'America' : row?.serverid == 2 ? 'Europe' : row?.serverid == 3 ? 'Asia' : row?.serverid == 4 ? 'TW, HK, MO' : row?.serverid == 12 ? 'Global' : ''}</>
                            ) : (
                              <>{gameList?.length == 0 ? <div>{locales[process.env.REACT_APP_LOCALE].loading}</div> : <>{gameList?.find((gameItem) => gameItem.serverId == row?.serverid)?.name}</>}</>
                            )}
                          </TableCell>
                          <TableCell align="center" sx={{ height: '57px' }}>
                            {getStatus(row.status)}
                          </TableCell>
                          <TableCell align="left" sx={{ width: '100%', maxWidth: 'none' }}>
                            {currencyFormat(row?.price)}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ transform: 'translateY(-8px)' }}>
                          <TableCell sx={{ borderBottom: 0, paddingBottom: '0', paddingTop: '0px', paddingLeft: 0 }} colSpan={'7'} align="left">{`${locales[process.env.REACT_APP_LOCALE].idTransaction}: ${
                            row?.number?.length < 30 ? (row?.mainNumber ? row?.mainNumber : '-') : row?.number
                          }`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontSize: '12px', opacity: 0.8, paddingTop: '0px', paddingLeft: 0 }} colSpan={'7'} align="left">
                            {moment(row?.date).format('DD.MM.YYYY HH:mm')}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {paginationData.pages > paginationData.page && (
                <button
                  onClick={() => {
                    if (paginationData.pages > paginationData.page) {
                      const dataNewPage = pagination(transList, 10, paginationData.page + 1);
                      setPaginationData({ ...paginationData, data: [...paginationData.data, ...dataNewPage], page: paginationData.page + 1 });
                    }
                  }}
                  className="vk-comments__more">
                  {locales[process.env.REACT_APP_LOCALE].showMore}
                </button>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="" style={{ width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'center', fontSize: '24px', fontWeight: '600', opacity: '0.4', marginTop: '64px' }}>
          {locales[process.env.REACT_APP_LOCALE].donateLastThreeDays}
        </div>
      )}
    </>
  );
}
