import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs, Select, OutlinedInput, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import DrawerAppBar from '../components/MainLayout';
import ArticleAddEdit from '../components/ArticleAddEdit';
import { getArticleSingle, resetArticleSingle } from '../redux/slices/article.slice';
import { AccessTime } from '@mui/icons-material';
import moment from 'moment';
import { Interweave } from 'interweave';
import HomeLayout from '../components/HomeLayout';
import { Helmet } from 'react-helmet';

const ArticleSinglePage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const {
    getArticleSingleState: { data: articleSingleData, error: articleSingleError },
  } = useSelector((state) => state.article);
  useEffect(() => {
    dispatch(getArticleSingle(slug));
    return () => {
      dispatch(resetArticleSingle());
    };
  }, [slug]);
  const navigate = useNavigate();

  return (
    <HomeLayout isWhite>
      <Box sx={{ margin: '0 auto', maxWidth: '800px', minHeight: '100%', paddingBottom: '100px' }}>
        {articleSingleData ? (
          <>
            <Helmet>
              <title>{articleSingleData?.name}</title>
              <meta name="description" content={articleSingleData?.name} />
              <link rel="canonical" href="https://donate-gold.ru/" />
            </Helmet>
            <Box sx={{ objectFit: 'cover', aspectRatio: { xs: '1/0.7', mob: '1/0.5' }, mb: '25px', display: 'block', mt: '80px', width: '100%', borderRadius: '32px' }} component={'img'} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${articleSingleData?.image}`}></Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'start' }}>
              {articleSingleData?.articleCategories?.map((cat) => (
                <Box
                  onClick={() => {
                    navigate(`/articles/?category=${cat.id}`);
                  }}
                  sx={{ cursor: 'pointer', marginRight: '8px', marginBottom: '11px', padding: '6px 14px', backgroundColor: 'hsl(0, 0%, 97%)', fontSize: '14px', borderRadius: '50px' }}>
                  {cat?.name}
                </Box>
              ))}
            </Box>
            <Box sx={{ fontSize: '33px', mt: '20px', mb: '11px', fontWeight: '600 !important' }}>{articleSingleData?.name}</Box>{' '}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '11px',
                fontSize: '15px',
                color: '#757575',
                fontWeight: '400 !important',
                mb: '40px',
              }}>
              <AccessTime sx={{ mt: '2px', fontSize: '16px', mr: '4px' }} />
              {moment(articleSingleData?.createdAt).format('MMMM DD, YYYY в HH:mm')}
            </Box>
            <Box
              className="article-content"
              sx={{
                '& p': {
                  lineHeight: '28.8px',
                  margin: '30px 0',
                },
                '& h3': {
                  fontSize: '23px',
                  margin: '30px 0',
                },
                '& strong': {
                  fontWeight: '600 !important',
                },
              }}>
              <Interweave content={articleSingleData?.content} />
            </Box>
          </>
        ) : articleSingleError?.error ? (
          <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', fontWeight: '600', mt: '200px' }}>Статья не найдена</Box>
        ) : (
          <></>
        )}
      </Box>
    </HomeLayout>
  );
};

export default ArticleSinglePage;
