import logo from './logo.svg';
import './App.css';
import Button from '@mui/material/Button';
import DrawerAppBar from './components/MainLayout';
import TrancTable from './components/TrancTable';
import { Typography } from '@mui/material';
import HomePage from './pages/Home';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import AuthPage from './pages/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './redux/slices/user.slice';
import { useEffect } from 'react';
import Loading from './components/Loading';
import SucessPage from './pages/Success';
import ErrorPage from './pages/Error';
import MainPage from './pages/MainPage';
import AboutPage from './pages/AboutPage';
import DonatePage from './pages/DonatePage';
import RulesPage from './pages/RulesPage';
import SupportPage from './pages/SupportPage';
import GamePage from './pages/GamePage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import FaqPage from './pages/FaqPage';
import ReviewsPage from './pages/ReviewsPage';
import GuarantePage from './pages/GuarantePage';
import ReturnPolicyPage from './pages/ReturnPolicyPage';
import ProfilePage from './pages/ProfilePage';
import { gapi, loadAuth2 } from 'gapi-script';
import { useState } from 'react';
import axios from 'axios';
import WorkOnSite from './components/WorkOnSite';
import RatingPage from './pages/RatingPage';
import { getPackage } from './redux/slices/package.slice';
import HomeLayout from './components/HomeLayout';
import { metaTags } from './utils/metaTags';
import Chatra from '@chatra/chatra';
import AdminArticleListPage from './pages/AdminArticleListPage';
import AdminArticleAddEditPage from './pages/AdminArticleAddEditPage';
import AdminArticleCreatePage from './pages/AdminArticleCreatePage';
import ArticleListPage from './pages/ArticleListPage';
import ArticleSinglePage from './pages/ArticleSinglePage';
import CartPage from './pages/CartPage';
import AccountActivatedPage from './pages/AccountActivatedPage';
import AdminTopUp from './pages/AdminTopUp';
function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(location);
  const {
    getUserState: { loading, data, error },
  } = useSelector((state) => state.user);
  const [settingsData, setSettingsData] = useState({ loading: true, data: null, error: null });
  useEffect(() => {
    setTimeout(() => {
      // loadAuth2(gapi, process.env.REACT_APP_GOOGLE_AUTH_API_KEY);
    }, 2000);
    function start() {
      // gapi.client.init({
      //   clientId: process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
      // });
    }

    // gapi.load('client:auth2', start);
    dispatch(getUser());
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/settings`)
      .then((resp) => {
        setSettingsData({ loading: false, data: resp.data, error: null });
      })
      .catch(() => {
        setSettingsData({ loading: false, data: null, error: true });
      });
    dispatch(getPackage());
  }, []);
  useEffect(() => {
    if (data) {
      Chatra('init', {
        ID: 'KtKRqiWqnbtnZJLaC',
        setup: {
          clientId: `${data.id}${data.email}`,
        },
        integration: { name: `${process.env.REACT_APP_LOCALE?.toUpperCase()} (${data.id}) ${data.name}`, email: data.email },
      });
      // Chatra('setIntegrationData',{  name: `(${data.id}) ${data.name}`, email: data.email });
    } else {
      Chatra('init', { ID: 'KtKRqiWqnbtnZJLaC' });
    }
  }, [data]);

  console.log(settingsData);
  useEffect(() => {
    const staticPageRule =
      location.pathname.substring(0, 5) !== '/auth' &&
      location.pathname !== '/about' &&
      location.pathname !== '/donate' &&
      location.pathname !== '/rules' &&
      location.pathname !== '/support' &&
      location.pathname !== '/faq' &&
      location.pathname !== '/reviews' &&
      location.pathname !== '/guarante' &&
      location.pathname !== '/return-policy' &&
      location.pathname !== '/articles' &&
      location.pathname.substring(0, 5) !== '/game' &&
      location.pathname.substring(0, 18) !== '/account-activated' &&
      location.pathname.substring(0, 9) !== '/articles';
    if (!data && !loading && error && staticPageRule) {
      navigate('/');
    }
  }, [data, loading, error]);

  let routes = useRoutes([
    { path: '/account', element: <HomePage meta={metaTags.accountPage} /> },

    { path: '/', element: <MainPage meta={metaTags.homePage} /> },
    { path: '/auth', element: <AuthPage meta={metaTags.authPage} /> },
    { path: '/about', element: <AboutPage meta={metaTags.aboutPage} /> },
    { path: '/donate', element: <DonatePage meta={metaTags.donatePage} /> },
    { path: '/account-activated/:link', element: <AccountActivatedPage meta={metaTags.donatePage} /> },
    { path: '/rules', element: <RulesPage meta={metaTags.rulesPage} /> },

    { path: '/support', element: <SupportPage meta={metaTags.supportPage} /> },
    {
      path: '/game/:id',
      element: data ? (
        <DrawerAppBar isFull>
          <GamePage />
        </DrawerAppBar>
      ) : (
        <HomeLayout isFullContainer>
          <GamePage />
        </HomeLayout>
      ),
    },
    { path: '/terms-of-use', element: <TermsOfUsePage meta={metaTags.termsOfUsePage} /> },
    { path: '/privacy-policy', element: <PrivacyPolicyPage meta={metaTags.privacyPolicyPage} /> },
    { path: '/faq', element: <FaqPage meta={metaTags.faqPage} /> },
    { path: '/reviews', element: <ReviewsPage meta={metaTags.reviewPage} /> },
    { path: '/guarante', element: <GuarantePage meta={metaTags.guarantePage} /> },
    { path: '/return-policy', element: <ReturnPolicyPage meta={metaTags.returnPolicyPage} /> },
    { path: '/cart', element: <CartPage meta={metaTags.cartPage} /> },
    { path: '/profile', element: <ProfilePage meta={metaTags.profilePage} /> },
    {
      path: '/rating',
      element: (
        <DrawerAppBar>
          <RatingPage meta={metaTags.ratingPage} />
        </DrawerAppBar>
      ),
    },
    { path: '/edit/articles', element: <AdminArticleListPage /> },
    {
      path: '/articles',
      element: (
        <HomeLayout isWhite>
          <ArticleListPage meta={metaTags.articlePage} />
        </HomeLayout>
      ),
    },
    { path: '/articles/:slug', element: <ArticleSinglePage /> },
    { path: '/edit/articles/:slug', element: <AdminArticleAddEditPage /> },
    { path: '/edit/articles/create', element: <AdminArticleCreatePage /> },
    { path: '/admin/topup', element: <AdminTopUp /> },
  ]);
  return !settingsData.loading ? settingsData?.data?.[1]?.activeWarning == true ? <WorkOnSite /> : !loading && (data || error) && routes : <></>;

  // return loading ? <></> : !loading && data ? routes : <WorkOnSite />;
}

export default App;
