import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import DrawerAppBar from '../components/MainLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Delete, Edit } from '@mui/icons-material';
import { deleteArticle, getArticleList } from '../redux/slices/article.slice';
import { Link } from 'react-router-dom';

const AdminArticleListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getArticleList());
  }, []);
  const {
    getArticleListState: { data: articleList },
    deleteArticleState: { data: deleteArticleData, loading: deleteArticleLoading },
  } = useSelector((state) => state.article);
  const {
    getUserState: { data: userData },
  } = useSelector((state) => state.user);
  useEffect(() => {
    if (deleteArticleData) {
      dispatch(getArticleList());
    }
  }, [deleteArticleData]);

  return (
    <>
      {userData?.id == process.env.REACT_APP_ARTICLE_EDITOR_ID || userData?.id == process.env.REACT_APP_ARTICLE_EDITOR_ID_SECOND ? (
        <DrawerAppBar>
          <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
              <h2>Список статей</h2>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/edit/articles/create');
                }}>
                Добавить
              </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& td, & th': {
                    padding: '4px',
                  },
                }}
                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="auto">Название</TableCell>
                    <TableCell align="center" width="auto">
                      Слаг
                    </TableCell>
                    <TableCell align="center" width="120px">
                      Категория
                    </TableCell>
                    <TableCell align="center" width="40px"></TableCell>
                    <TableCell align="center" width="40px"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {articleList?.rows?.map((article) => (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {article?.name}
                      </TableCell>
                      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                        <Link to={`/articles/${article?.slug}`}>{article?.slug}</Link>
                      </TableCell>
                      <TableCell align="center" width="120px">
                        {article?.articleCategories?.map((itemCat) => (
                          <>
                            {itemCat.name}
                            <br />
                          </>
                        ))}
                      </TableCell>
                      <TableCell align="center" width="40px">
                        <IconButton
                          disableRipple
                          onClick={() => {
                            navigate(`/edit/articles/${article.slug}`);
                          }}>
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center" width="40px">
                        {' '}
                        {deleteArticleLoading ? (
                          <CircularProgress sx={{ mt: '5px', height: '30px !important', width: '30px !important' }} />
                        ) : (
                          <IconButton
                            disableRipple
                            onClick={() => {
                              dispatch(deleteArticle({ id: article.id }));
                            }}>
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </DrawerAppBar>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminArticleListPage;
