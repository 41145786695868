import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import styles from '../styles/Like.scss';
import { likeComment } from '../redux/slices/user.slice';
const Like = ({ noLike = false, defaultLikes = 0, commentId }) => {
  const [like, setLike] = useState(false);
  const [countLikes, setCountLikes] = useState(defaultLikes);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      setLike(true);
    }, 1000);
  }, []);

  return (
    <div className={clsx('middle-wrapper', noLike && 'noLike')}>
      <div className="like-wrapper">
        <a
          className={`like-button ${like ? 'liked' : ''}`}
          // onClick={() => {
          //   if (!noLike) {
          //     if (!like) {
          //       setLike(true);
          //       dispatch(likeComment({ commentId }));
          //       setCountLikes(defaultLikes + 1);
          //     }
          //   }
          // }}
        >
          <span className="like-icon">
            <div className="heart-animation-1"></div>
            <div className="heart-animation-2"></div>
          </span>
        </a>
      </div>
    </div>
  );
};

export default Like;
