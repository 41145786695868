import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
export const createUser = createAsyncThunk('user/createUser', (user, { rejectWithValue }) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/add`, user)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const googleLoginUser = createAsyncThunk('user/googleLoginUser', (user, { rejectWithValue }) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/google-auth`, user)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const loginUser = createAsyncThunk('user/loginUser', (user, { rejectWithValue }) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/login`, user)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const resetPasswordUser = createAsyncThunk('user/resetPasswordUser', ({ name, email }, { rejectWithValue }) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/reset-password`, {
      name,
      email,
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getUser = createAsyncThunk('user/getUser', (obj, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/get`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const generatePromoCode = createAsyncThunk('user/generatePromocode', (obj, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/user/generate-promo-code`,
      {},
      {
        headers: {
          request_token: token,
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const checkUser = createAsyncThunk('user/checkUser', (ids, { rejectWithValue }) => {
  // const token = localStorage?.getItem('token');
  // if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/check/${ids.playerId}/${ids.serverId}`, {
      params: {
        typeGameId: ids.typeGameId,
      },
      // headers: {
      //   request_token: token,
      // },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const getBonus = createAsyncThunk('user/getBonus', (ids, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/bonus`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const changeNickname = createAsyncThunk('user/changeNickname', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/change-nickname`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const resetAvatar = createAsyncThunk('user/resetAvatar', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/reset-avatar`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const initPaymentCard = createAsyncThunk('user/initPaymentCard', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/init-payment`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const initPaymentCardSecond = createAsyncThunk('user/initPaymentCardSecond', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/init-payment-2`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getTop100Users = createAsyncThunk('user/getTop100Users', (data, { rejectWithValue }) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/top100`, {
      params: {
        page: data.page,
        onlyMonth: data.onlyMonth,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const uploadAvatar = createAsyncThunk('user/uploadAvatar', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/upload-avatar`, data.formData, {
      headers: {
        request_token: token,
        ext: data.ext,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getRankUserFromTop = createAsyncThunk('user/getRankUserFromTop', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/rank`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getSupportList = createAsyncThunk('user/getSupportList', (data, { rejectWithValue }) => {
  // const token = localStorage?.getItem('token');
  // if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/support-list`, {
      // headers: {
      //   request_token: token,
      // },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const switchShowInTop100 = createAsyncThunk('user/switchShowInTop100', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/switch-top-100`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const switchShowInSupportList = createAsyncThunk('user/switchShowInSupportList', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/switch-support-list`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const buyPromoCode = createAsyncThunk('user/buyPromoCode', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/buy-promocode`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const sendPackageInChat = createAsyncThunk('user/sendPackageInChat', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/send-in-chat`, {
      params: {
        packageId: data?.packageId,
        type: data?.type,
      },
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const tryTimePromoCode = createAsyncThunk('user/tryTimePromoCode', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/try-promocode`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getAttemptsTimePromoCode = createAsyncThunk('user/getAttemptsTimePromoCode', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/promocode-list`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const createComment = createAsyncThunk('user/createComment', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/create-comment`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getRandomComment = createAsyncThunk('user/getRandomComment', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/random-comment`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getRegData = createAsyncThunk('user/getRegData', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/user/reg-data`, {
      headers: {
        request_token: token,
      },
      params: {
        managerId: data,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const adminTopup = createAsyncThunk('user/adminTopup', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/admin/topup`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const adminReasonList = createAsyncThunk('user/adminReasonList', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/admin/reason-list`, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const likeComment = createAsyncThunk('user/likeComment', (data, { rejectWithValue }) => {
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/like-comment`, data)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});

export const telegramBinding = createAsyncThunk('user/telegramBinding', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });
  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/user/telegram-binding`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
const userInitialState = {
  createUserState: {
    data: null,
    loading: false,
    error: null,
  },
  getUserState: {
    data: null,
    loading: false,
    error: null,
  },
  loginUserState: {
    data: null,
    loading: false,
    error: null,
  },
  checkUserState: {
    data: null,
    loading: false,
    error: null,
  },
  resetPasswordUserState: {
    data: null,
    loading: false,
    error: null,
  },
  getBonusState: {
    data: null,
    loading: false,
    error: null,
  },
  generatePromoCodeState: {
    data: null,
    loading: false,
    error: null,
  },
  googleLoginUserState: {
    data: null,
    loading: false,
    error: null,
  },
  initPaymentCardState: {
    data: null,
    loading: false,
    error: null,
  },
  initPaymentCardSecondState: {
    data: null,
    loading: false,
    error: null,
  },
  uploadAvatarState: {
    data: null,
    loading: false,
    error: null,
  },
  changeNicknameState: {
    data: null,
    loading: false,
    error: null,
  },
  resetAvatarState: {
    data: null,
    loading: false,
    error: null,
  },
  getTop100UsersState: {
    data: null,
    loading: false,
    error: null,
  },
  getSupportListState: {
    data: null,
    loading: false,
    error: null,
  },
  switchShowInTop100State: {
    data: null,
    loading: false,
    error: null,
  },
  getRankUserFromTopState: {
    data: null,
    loading: false,
    error: null,
  },
  switchShowInSupportListState: {
    data: null,
    loading: false,
    error: null,
  },
  buyPromoCodeState: {
    loading: false,
    data: null,
    error: null,
  },
  sendPackageInChatState: {
    loading: false,
    data: null,
    error: null,
  },
  tryTimePromoCodeState: {
    loading: false,
    data: null,
    error: null,
  },
  getAttemptsTimePromoCodeState: {
    loading: false,
    data: null,
    error: null,
  },
  createCommentState: {
    loading: false,
    data: null,
    error: null,
  },
  getRandomCommentState: {
    loading: false,
    data: null,
    error: null,
  },
  getRegDataState: {
    loading: false,
    data: null,
    error: null,
  },
  likeCommentState: {
    loading: false,
    data: null,
    error: null,
  },
  telegramBindingState: {
    loading: false,
    data: null,
    error: null,
  },
  adminTopupState: {
    loading: false,
    data: null,
    error: null,
  },
  adminReasonListState: {
    loading: false,
    data: null,
    error: null,
  },
  activeUser: null,
  isAuth: false,
  cart: null,
  addedCart: false,
  updateCartEmpty: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setAddedCart: (state, action) => {
      state.addedCart = action.payload;
    },
    setUpdateCartEmpty: (state, action) => {
      state.updateCartEmpty = {};
    },
    loginUserReset: (state, action) => {
      state.loginUserState = userInitialState.loginUserState;
    },
    googleLoginReset: (state, action) => {
      state.googleLoginUserState = userInitialState.googleLoginUserState;
    },
    createUserReset: (state, action) => {
      state.createUserState = userInitialState.createUserState;
    },
    checkUserReset: (state, action) => {
      state.checkUserState = userInitialState.checkUserState;
    },
    setActiveUser: (state, { payload }) => {
      state.activeUser = payload;
    },
    resetPasswordUserReset: (state, action) => {
      state.resetPasswordUserState = userInitialState.resetPasswordUserState;
    },
    resetGetTop100Users: (state, action) => {
      state.getTop100UsersState = userInitialState.getTop100UsersState;
    },
    resetAvatarReset: (state, action) => {
      state.resetAvatarState = userInitialState.resetAvatarState;
    },
    resetInitPaymentCardState: (state, action) => {
      state.initPaymentCardState = userInitialState.initPaymentCardState;
    },
    resetGeneratePromoCode: (state, action) => {
      state.generatePromoCodeState = userInitialState.generatePromoCodeState;
    },
    resetGetSupportList: (state, action) => {
      state.getSupportListState = userInitialState.getSupportListState;
    },
    resetCreateComment: (state, action) => {
      state.createCommentState = userInitialState.createCommentState;
    },
    resetTelegramBindingData: (state, action) => {
      state.telegramBindingState = userInitialState.telegramBindingState;
    },
    resetAdminTopup: (state, action) => {
      state.adminTopupState = userInitialState.adminTopupState;
    },
  },
  extraReducers: {
    // CREATE
    [getUser.pending]: (state, action) => {
      state.getUserState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [getUser.fulfilled]: (state, action) => {
      state.getUserState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getUser.rejected]: (state, action) => {
      localStorage.removeItem('token');
      state.getUserState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // CREATE
    [createUser.pending]: (state, action) => {
      state.createUserState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [createUser.fulfilled]: (state, action) => {
      state.createUserState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [createUser.rejected]: (state, action) => {
      state.createUserState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // UPLOAD AVATAR
    [uploadAvatar.pending]: (state, action) => {
      state.uploadAvatarState = {
        loading: true,
        error: null,
      };
    },
    [uploadAvatar.fulfilled]: (state, action) => {
      state.uploadAvatarState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [uploadAvatar.rejected]: (state, action) => {
      state.uploadAvatarState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // GET BONUS
    [getBonus.pending]: (state, action) => {
      state.getBonusState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [getBonus.fulfilled]: (state, action) => {
      state.getBonusState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getBonus.rejected]: (state, action) => {
      state.getBonusState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // LOGIN
    [checkUser.pending]: (state, action) => {
      state.checkUserState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [checkUser.fulfilled]: (state, action) => {
      state.checkUserState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [checkUser.rejected]: (state, action) => {
      state.checkUserState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // CHANGE USERNAME
    [changeNickname.pending]: (state, action) => {
      state.changeNicknameState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [changeNickname.fulfilled]: (state, action) => {
      state.changeNicknameState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [changeNickname.rejected]: (state, action) => {
      state.changeNicknameState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // RESET AVATAR
    [resetAvatar.pending]: (state, action) => {
      state.resetAvatarState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [resetAvatar.fulfilled]: (state, action) => {
      state.resetAvatarState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [resetAvatar.rejected]: (state, action) => {
      state.resetAvatarState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // LOGIN
    [loginUser.pending]: (state, action) => {
      state.loginUserState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [loginUser.fulfilled]: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.loginUserState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [loginUser.rejected]: (state, action) => {
      state.loginUserState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // RESET PASSWORD
    [resetPasswordUser.pending]: (state, action) => {
      state.resetPasswordUserState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [resetPasswordUser.fulfilled]: (state, action) => {
      state.resetPasswordUserState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [resetPasswordUser.rejected]: (state, action) => {
      state.resetPasswordUserState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },

    [generatePromoCode.pending]: (state, action) => {
      state.generatePromoCodeState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [generatePromoCode.fulfilled]: (state, action) => {
      state.generatePromoCodeState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [generatePromoCode.rejected]: (state, action) => {
      state.generatePromoCodeState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [googleLoginUser.pending]: (state, action) => {
      state.googleLoginUserState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [googleLoginUser.fulfilled]: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.googleLoginUserState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [googleLoginUser.rejected]: (state, action) => {
      state.googleLoginUserState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [initPaymentCard.pending]: (state, action) => {
      state.initPaymentCardState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [initPaymentCard.fulfilled]: (state, action) => {
      state.initPaymentCardState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [initPaymentCard.rejected]: (state, action) => {
      state.initPaymentCardState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [initPaymentCardSecond.pending]: (state, action) => {
      state.initPaymentCardSecondState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [initPaymentCardSecond.fulfilled]: (state, action) => {
      state.initPaymentCardSecondState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [initPaymentCardSecond.rejected]: (state, action) => {
      state.initPaymentCardSecondState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getTop100Users.pending]: (state, action) => {
      state.getTop100UsersState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [getTop100Users.fulfilled]: (state, action) => {
      state.getTop100UsersState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getTop100Users.rejected]: (state, action) => {
      state.getTop100UsersState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    // FAVORITE LIST
    [getSupportList.pending]: (state, action) => {
      state.getSupportListState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [getSupportList.fulfilled]: (state, action) => {
      state.getSupportListState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getSupportList.rejected]: (state, action) => {
      state.getSupportListState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [switchShowInSupportList.pending]: (state, action) => {
      state.switchShowInSupportListState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [switchShowInSupportList.fulfilled]: (state, action) => {
      state.switchShowInSupportListState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [switchShowInSupportList.rejected]: (state, action) => {
      state.switchShowInSupportListState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },

    [switchShowInTop100.pending]: (state, action) => {
      state.switchShowInTop100State = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [switchShowInTop100.fulfilled]: (state, action) => {
      state.switchShowInTop100State = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [switchShowInTop100.rejected]: (state, action) => {
      state.switchShowInTop100State = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },

    [getRankUserFromTop.pending]: (state, action) => {
      state.getRankUserFromTopState = {
        loading: true,
        data: null,
        error: null,
      };
    },

    [getRankUserFromTop.fulfilled]: (state, action) => {
      state.getRankUserFromTopState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getRankUserFromTop.rejected]: (state, action) => {
      state.getRankUserFromTopState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [buyPromoCode.pending]: (state, action) => {
      state.buyPromoCodeState = {
        loading: true,
        error: null,
      };
    },

    [buyPromoCode.fulfilled]: (state, action) => {
      state.buyPromoCodeState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [buyPromoCode.rejected]: (state, action) => {
      state.buyPromoCodeState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [sendPackageInChat.pending]: (state, action) => {
      state.sendPackageInChatState = {
        loading: true,
        error: null,
      };
    },

    [sendPackageInChat.fulfilled]: (state, action) => {
      state.sendPackageInChatState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [sendPackageInChat.rejected]: (state, action) => {
      state.sendPackageInChatState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },

    [tryTimePromoCode.pending]: (state, action) => {
      state.tryTimePromoCodeState = {
        loading: true,
        error: null,
      };
    },

    [tryTimePromoCode.fulfilled]: (state, action) => {
      state.tryTimePromoCodeState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [tryTimePromoCode.rejected]: (state, action) => {
      state.tryTimePromoCodeState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getAttemptsTimePromoCode.pending]: (state, action) => {
      state.getAttemptsTimePromoCodeState = {
        loading: true,
        error: null,
      };
    },

    [getAttemptsTimePromoCode.fulfilled]: (state, action) => {
      state.getAttemptsTimePromoCodeState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getAttemptsTimePromoCode.rejected]: (state, action) => {
      state.getAttemptsTimePromoCodeState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [createComment.pending]: (state, action) => {
      state.createCommentState = {
        loading: true,
        error: null,
      };
    },

    [createComment.fulfilled]: (state, action) => {
      state.createCommentState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [createComment.rejected]: (state, action) => {
      state.createCommentState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getRandomComment.pending]: (state, action) => {
      state.getRandomCommentState = {
        loading: true,
        error: null,
      };
    },

    [getRandomComment.fulfilled]: (state, action) => {
      state.getRandomCommentState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getRandomComment.rejected]: (state, action) => {
      state.getRandomCommentState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getRegData.pending]: (state, action) => {
      state.getRegDataState = {
        loading: true,
        error: null,
      };
    },

    [getRegData.fulfilled]: (state, action) => {
      state.getRegDataState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getRegData.rejected]: (state, action) => {
      state.getRegDataState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [likeComment.pending]: (state, action) => {
      state.likeCommentState = {
        loading: true,
        error: null,
      };
    },

    [likeComment.fulfilled]: (state, action) => {
      state.likeCommentState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [likeComment.rejected]: (state, action) => {
      state.likeCommentState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [telegramBinding.pending]: (state, action) => {
      state.telegramBindingState = {
        loading: true,
        error: null,
      };
    },

    [telegramBinding.fulfilled]: (state, action) => {
      state.telegramBindingState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [telegramBinding.rejected]: (state, action) => {
      state.telegramBindingState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [adminTopup.pending]: (state, action) => {
      state.adminTopupState = {
        loading: true,
        error: null,
      };
    },

    [adminTopup.fulfilled]: (state, action) => {
      state.adminTopupState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [adminTopup.rejected]: (state, action) => {
      state.adminTopupState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [adminReasonList.pending]: (state, action) => {
      state.adminReasonListState = {
        loading: true,
        error: null,
      };
    },

    [adminReasonList.fulfilled]: (state, action) => {
      state.adminReasonListState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [adminReasonList.rejected]: (state, action) => {
      state.adminReasonListState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
  },
});
export const {
  loginUserReset,
  setActiveUser,
  checkUserReset,
  createUserReset,
  resetPasswordUserReset,
  resetGeneratePromoCode,
  googleLoginReset,
  setCart,
  setUpdateCartEmpty,
  resetInitPaymentCardState,
  setAddedCart,
  resetAvatarReset,
  resetGetTop100Users,
  resetGetSupportList,
  resetCreateComment,
  resetTelegramBindingData,
  resetAdminTopup,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
