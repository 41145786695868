import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Box, DialogActions, DialogContent, DialogContentText, Input, Slider, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../utils/currencyFormat';
import TransitionDialog from './TransitionDialog';
import AvatarEditor from 'react-avatar-editor';
import { useRef } from 'react';
import { useState } from 'react';
import { locales } from '../locales';

export default function AvatarModal(props) {
  const { onClose, onSave, prevFile } = props;
  const editorRef = useRef();
  const [zoom, setZoom] = useState(1);
  const handleClose = () => {
    onClose();
  };
  const handleSave = () => {
    const canvas = editorRef.current.getImage().toDataURL();
    const typeDataUrl = canvas.split(';')[0].split('/')[1];

    let imageURL;
    fetch(canvas)
      .then((res) => res.blob())
      .then((blob) => {
        onClose();
        onSave(new File([blob], 'name'), typeDataUrl);
      });
  };
  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setZoom(newValue);
    }
  };
  return (
    <Dialog
      TransitionComponent={TransitionDialog}
      onClose={handleClose}
      open={true}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '550px', // Set your width here
        },
      }}>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', transform: 'translateY(20px)', height: 'calc(100% + 40px)' }}>
          <Typography variant="h5" sx={{ mb: '16px', fontWeight: '600', fontSize: { mob: '24px', textAlign: 'center' } }}>
            {locales[process.env.REACT_APP_LOCALE].editorAvatar}
          </Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', mb: '16px' }}>
            <Typography sx={{ paddingRight: '20px' }}>{locales[process.env.REACT_APP_LOCALE].zoom}</Typography>

            <Slider value={zoom} min={1} step={0.1} max={3} getAriaValueText={() => zoom} valueLabelFormat={() => zoom} onChange={handleChange} valueLabelDisplay="auto" aria-labelledby="non-linear-slider" />
          </Box>
          <Box
            onWheel={(e) => {
              // const direction = e.nativeEvent.wheelDelta;
              // if (direction > 0) {
              //   if (zoom < 3) {
              //     setZoom((parseFloat(zoom) + 0.1).toFixed(1));
              //   }
              // } else {
              //   if (zoom > 1) {
              //     setZoom((parseFloat(zoom) - 0.1).toFixed(1));
              //   }
              // }
            }}>
            <AvatarEditor
              ref={editorRef}
              image={prevFile}
              width={300}
              height={300}
              border={100}
              borderRadius={300}
              color={[0, 0, 0, 0.3]} // RGBA
              scale={zoom}
              rotate={0}
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </Box>
        </div>
      </DialogContent>
      <DialogActions sx={{ mt: '16px', display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '8px', padding: '16px' }}>
        <Button color="error" onClick={handleClose} autoFocus variant="contained" style={{ textTransform: 'none' }}>
          {locales[process.env.REACT_APP_LOCALE].back}
        </Button>
        <Button color="success" onClick={handleSave} autoFocus variant="contained" sx={{ margin: '0 !important', textTransform: 'none' }}>
          {locales[process.env.REACT_APP_LOCALE].save}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
