import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const InputCart = ({ isTopup, onChange, count }) => {
  const [focus, setFocus] = useState(true);
  const [value, setValue] = useState(count);
  return (
    <>
      {' '}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          marginLeft: { mobile: 'auto', xs: '0' },
          marginRight: isTopup ? '0' : { mobile: '50px', xs: 'auto' },
        }}
        onMouseEnter={() => {
          // setFocus(true);
        }}
        onMouseLeave={() => {
          // setFocus(false);
        }}>
        {focus && !isTopup && (
          <Box
            sx={{ borderRadius: '0 0 0 2px', zIndex: 10, '&:hover': { '& svg': { background: 'rgba(0,0,0,0.05)' }, opacity: 1 }, opacity: 0.5 }}
            onClick={() => {
              onChange({ isAdd: true });
            }}>
            <KeyboardArrowUpIcon sx={{ right: '6px', top: '1px', position: 'absolute', fontSize: '14px' }} />
          </Box>
        )}

        <TextField
          onFocus={() => {
            // setFocus(true);
          }}
          onBlur={(event) => {
            if (isTopup) {
              let updateCount = event.target.value;
              if (updateCount < 100 || isNaN(updateCount)) {
                updateCount = 100;
              } else if (updateCount > 75000) {
                updateCount = 75000;
              }
              setValue(updateCount);
              onChange({ value: updateCount });
            }
            // setFocus(false);
          }}
          onChange={(event) => {
            if (isTopup) {
              setValue(event.target.value);
            } else {
              onChange({ value: event.target.value });
            }
          }}
          value={isTopup ? value : count}
          type={'number'}
          size="small"
          sx={{
            mx: '5px',
            width: '55px',
            // padding: 0
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #838689!important',
            },
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none',
            },
            '& input:active ~ svg': {
              display: 'none !important',
            },
            fontSize: '12px',
            '& input': {
              textAlign: isTopup ? 'center' : 'left',
              color: '#000 !important',
              width: '40px',
              fontSize: '12px',
              padding: isTopup ? '4.5px 8px 4.5px 8px' : '4.5px 16px 4.5px 11px',
            },
            '& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button': {
              '-webkit-appearance': 'auto',
            },
          }}
        />
        {focus && !isTopup && (
          <Box
            sx={{ zIndex: 10, '&:hover': { '& svg': { background: 'rgba(0,0,0,0.05)' }, opacity: 1 }, opacity: 0.5 }}
            onClick={() => {
              onChange({ isMinus: true });
            }}>
            {' '}
            <KeyboardArrowDownIcon sx={{ borderRadius: '2px 0 0 0', right: '6px', bottom: '1px', position: 'absolute', fontSize: '14px' }} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default InputCart;
