import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs, Select, OutlinedInput, MenuItem, InputLabel, FormControl, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import DrawerAppBar from './MainLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Delete, Edit } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import UploadEditorFile from './UploadEditorFile';
import UploadImage from './UploadImage';
import CodeEditor from './CodeEditor';
import { createArticle, getArticleCategoryList, resetCreateArticle, resetUpdateArticle, updateArticle } from '../redux/slices/article.slice';

const ArticleAddEdit = ({ data }) => {
  const defaultValues = data
    ? {
        name: data.name,
        slug: data.slug,
        image: data.image,
        content: data.content,
        categories: data.articleCategories?.map((articleCategory) => articleCategory.id),
        isHomeArticle: data.isHomeArticle,
        shortDesc: data.shortDesc,
      }
    : {
        categories: [],
        content: 'Type content article here ...',
        isHomeArticle: false,
      };
  const {
    getArticleCategoryListState: { data: articleCategoryListData },
    createArticleState: { data: createArticleData, loading: createArticleLoading },
    updateArticleState: { data: updateArticleData, loading: updateArticleLoading },
  } = useSelector((state) => state.article);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (dt) => {
    if (data) {
      dispatch(updateArticle({ ...dt, id: data.id }));
    } else {
      dispatch(createArticle(dt));
    }
  };
  const articleForm = useForm({ defaultValues });
  console.log(articleForm.formState.errors);
  console.log(articleForm.watch());
  useEffect(() => {
    dispatch(getArticleCategoryList());
    articleForm.register('content', { required: true });
  }, []);
  useEffect(() => {
    if (createArticleData) {
      dispatch(resetCreateArticle());
      navigate('/edit/articles');
    }
  }, [createArticleData]);
  useEffect(() => {
    if (updateArticleData) {
      dispatch(resetUpdateArticle());
      navigate('/edit/articles');
    }
  }, [updateArticleData]);
  return (
    <>
      {' '}
      <Container>
        <Box sx={{ pt: '20px', pb: '60px' }}>
          <UploadEditorFile />
          <h2>Добавление статьи</h2>
          <Box sx={{ display: 'grid', rowGap: '16px' }}>
            <FormControlLabel control={<Controller name={'isHomeArticle'} control={articleForm.control} render={({ field: props }) => <Checkbox {...props} checked={props.value} onChange={(e) => props.onChange(e.target.checked)} />} />} label={'Разместить статью на главной странице'} />
            <Controller
              control={articleForm.control}
              rules={{
                required: true,
              }}
              name="name"
              defaultValue=""
              render={({ field }) => <TextField {...field} error={articleForm.formState.errors?.name} label={'Название статьи'} variant="outlined" size="small" autoComplete="off" />}
            />
            <Controller
              control={articleForm.control}
              rules={{
                required: true,
              }}
              name="slug"
              defaultValue=""
              render={({ field }) => <TextField {...field} error={articleForm.formState.errors?.slug} label={'Слаг'} variant="outlined" size="small" autoComplete="off" />}
            />

            <FormControl>
              {' '}
              <InputLabel id="categories-id" error={articleForm.formState.errors?.categories}>
                Категории
              </InputLabel>
              <Controller
                control={articleForm.control}
                rules={{
                  required: true,
                }}
                name="categories"
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} labelId="categories-id" error={articleForm.formState.errors?.categories} label={'Категории'} multiple variant="outlined" input={<OutlinedInput label="Name" />}>
                    {articleCategoryListData?.map((articleCategory) => (
                      <MenuItem key={articleCategory?.id} value={articleCategory?.id}>
                        {articleCategory?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            <UploadImage form={articleForm} name="image" label="Главное фото" />
            <Controller
              control={articleForm.control}
              rules={{
                required: true,
              }}
              name="shortDesc"
              defaultValue=""
              render={({ field }) => <TextField multiline rows="5" {...field} error={articleForm.formState.errors?.shortDesc} label={'Краткое описание'} variant="outlined" size="small" autoComplete="off" />}
            />
            <CodeEditor form={articleForm} name="content" label="Контент статьи" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: 'auto' }}>
            {createArticleLoading || updateArticleLoading ? (
              <CircularProgress sx={{ height: '30px !important', width: '30px !important' }} />
            ) : (
              <>
                <Button variant="contained" onClick={articleForm.handleSubmit(onSubmit)}>
                  Сохранить
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => {
                    navigate('/edit/articles');
                  }}>
                  Назад
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ArticleAddEdit;
