import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import Alert from '@mui/material/Alert';
import DrawerAppBar from '../components/MainLayout';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { currencyFormat } from '../utils/currencyFormat';
import { generatePromoCode, getRankUserFromTop, getRegData, getUser, resetGeneratePromoCode, resetTelegramBindingData, telegramBinding } from '../redux/slices/user.slice';
import moment from 'moment';
import Loading from '../components/Loading';
import PaymentModal from '../components/PaymentModal';
import { a11yProps, TabPanel } from './Home';
import TrancTable from '../components/TrancTable';
import PaymentTable from '../components/PaymentTable';
import BonusMenu from '../components/BonusMenu';
import ProfileAvatar from '../components/ProfileAvatar';
import AvatarModal from '../components/AvatarModal';
import SettingPage from '../components/SettingPage';
import { locales } from '../locales';
import TelegramLoginButton from 'telegram-login-button';
import { Telegram } from '@mui/icons-material';
import Loader from '../components/Loader';
const ProfilePage = ({ meta }) => {
  const {
    getUserState: { loading, data, error },
    generatePromoCodeState: { loading: loadingGenPromo, data: dataGenPromo, error: errorGenPromo },
    getRegDataState: { data: regData },
    telegramBindingState: { data: telegramBindingData, loading: telegramBindingLoading },
  } = useSelector((state) => state.user);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [typePay, setTypePay] = useState(1);
  const [promoActive, setPromoActive] = useState(null);
  const [diffDaysPromo, setDiffDaysPromo] = useState(null);
  const [openCopyPromo, setOpenCopyPromo] = React.useState(false);
  const [openPay, setOpenPay] = React.useState(false);
  const dispatch = useDispatch();
  const handleTooltipClose = () => {
    setOpenCopy(false);
  };
  const handleClickOpenPay = (typePayParam) => {
    setOpenPay(true);
    setTypePay(typePayParam);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClosePay = (value) => {
    setOpenPay(false);
  };
  useEffect(() => {
    if (data?.selfReferralCode) {
      const diffDaysPromoVal = moment(data?.selfReferralCode?.dateEnd).diff(moment(), 'days');
      const promoActive = moment().isBefore(data?.selfReferralCode?.dateEnd);
      setDiffDaysPromo(diffDaysPromoVal);
      setPromoActive(promoActive);
    }
    if (data) {
      dispatch(getRegData(data?.id));
    }
  }, [data]);

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(data?.id);
    setOpenCopy(true);
    setTimeout(() => {
      setOpenCopy(false);
    }, 1000);
  };
  const handleTooltipClosePromo = () => {
    setOpenCopyPromo(false);
  };

  useEffect(() => {
    if (dataGenPromo) {
      dispatch(getUser());
      dispatch(resetGeneratePromoCode());
    }
  }, [dataGenPromo]);
  useEffect(() => {
    if (telegramBindingData) {
      dispatch(getUser());
      dispatch(resetTelegramBindingData());
    }
  }, [telegramBindingData]);
  const handleTooltipOpenPromo = () => {
    navigator.clipboard.writeText(data?.selfReferralCode?.code);
    setOpenCopyPromo(true);
    setTimeout(() => {
      setOpenCopyPromo(false);
    }, 1000);
  };
  const onExit = () => {
    localStorage.removeItem('token');
    dispatch(getUser());
  };

  const handleGeneratePromoCode = () => {
    dispatch(generatePromoCode());
  };
  console.log(typePay);
  return (
    <DrawerAppBar>
      {meta}
      <Paper sx={{ height: 'auto', marginTop: '22px' }}>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', height: 'auto' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ justifyContent: 'center' }}>
              <Tab label={locales[process.env.REACT_APP_LOCALE].cabinet} {...a11yProps(0)} />
              <Tab label={locales[process.env.REACT_APP_LOCALE].donates} {...a11yProps(1)} />
              <Tab label={locales[process.env.REACT_APP_LOCALE].payments} {...a11yProps(2)} />
              <Tab label={locales[process.env.REACT_APP_LOCALE].settings} {...a11yProps(3)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Container>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '500px', mt: 3, mx: 'auto' }}>
                <Typography variant="h4">{locales[process.env.REACT_APP_LOCALE].account}</Typography>
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {` ${data?.email}`}
                </Typography>
                <Typography variant="body1" sx={{}}>
                  {`${data?.name}`}
                </Typography>
                <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
                  {locales[process.env.REACT_APP_LOCALE].ourIdOnSite}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                  <Typography variant="h5" sx={{ fontWeight: '700 !important' }}>
                    {data?.id}
                  </Typography>

                  <Box sx={{ position: 'absolute', left: '120%', top: '55%', transform: 'translateY(-50%)' }}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: 'success.main',
                          },
                        },
                      }}
                      onClose={handleTooltipClose}
                      open={openCopy}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={<div style={{ whiteSpace: 'nowrap' }}>{locales[process.env.REACT_APP_LOCALE].idCopy}</div>}>
                      <IconButton onClick={handleTooltipOpen} disableRipple={true} sx={{ pl: 0.5 }}>
                        <ContentCopyIcon sx={{ fontSize: '18px', opacity: '0.7' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Box sx={{ marginTop: '30px' }}>
                  {data?.telegram ? (
                    <>
                      <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
                        Ваш телеграм
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'center' }}>
                        <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: '700 !important' }}>
                          @{data?.telegram}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <TelegramLoginButton botName="Dgauth_bot" dataOnauth={(user) => dispatch(telegramBinding(user))} />
                  )}
                </Box>
                <Box sx={{ mt: 5, textAlign: 'center' }}>
                  <Button
                    onClick={() => {
                      handleClickOpenPay(1);
                    }}
                    sx={{ mt: 3 }}
                    variant="outlined"
                    size="large">
                    {locales[process.env.REACT_APP_LOCALE].topUpAccount}
                  </Button>

                  <Typography variant="body1" sx={{ mb: 1, marginTop: '10px' }}>
                    <Alert severity="success">{locales[process.env.REACT_APP_LOCALE].topUpAccountText}</Alert>
                  </Typography>
                </Box>
                {/* <Box sx={{ mt: 5, textAlign: 'center' }}>
                  <Button
                    onClick={() => {
                      handleClickOpenPay(2);
                    }}
                    sx={{ mt: 3 }}
                    variant="outlined"
                    size="large">
                    {locales[process.env.REACT_APP_LOCALE].topUpReserve}
                  </Button>

                  <Typography variant="body1" sx={{ mb: 1, marginTop: '10px' }}>
                    <Alert severity="success">{locales[process.env.REACT_APP_LOCALE].currentMethodText}</Alert>
                  </Typography>
                </Box> */}
                {/* <Button
                  onClick={() => {
                    handleClickOpenPay(3);
                  }}
                  sx={{ mt: 3 }}
                  variant="outlined"
                  size="large">
                  Пополнить счет
                </Button> */}
                {/* <Box sx={{ mt: 5, textAlign: 'center' }}>
                  <BonusMenu />
                  <Typography variant="body1" sx={{ mb: 1, marginTop: '10px' }}>
                    <Alert severity="success">{locales[process.env.REACT_APP_LOCALE].moneyBoxText}</Alert>
                  </Typography>
                </Box> */}
                <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
                  Начисления
                </Typography>
                <Box sx={{ width: '100%', maxWidth: '300px' }}>
                  {data?.topups?.map((topup) => (
                    <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'start', marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                      <Box sx={{ fontSize: '14px' }}>{moment(topup.createdAt).format('DD.MM.YYYY')}</Box>
                      <Box sx={{ fontSize: '14px', marginTop: '2px' }}>{topup.reason.name}</Box>
                      <Box sx={{ color: 'success.light', fontSize: '16px' }}>+{currencyFormat(topup.sum)}</Box>
                    </Box>
                  ))}
                </Box>
                <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
                  {locales[process.env.REACT_APP_LOCALE].refProgramm}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, textAlign: 'center', fontSize: '12px' }}>
                  {locales[process.env.REACT_APP_LOCALE].refProgrammText}
                </Typography>{' '}
                {promoActive !== null && (
                  <>
                    <Typography variant="h5" sx={{ mt: 5, mb: 2, marginTop: '-3px' }}>
                      {locales[process.env.REACT_APP_LOCALE].ourPromo}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '2px solid ',
                        borderColor: promoActive ? 'success.main' : 'error.main',
                        px: 3,
                        py: 2,
                        borderRadius: 1,
                        textTransform: 'uppercase',
                        fontSize: '24px',
                        textAlign: 'center',

                        minWidth: '150px',
                      }}>
                      <Box sx={{ pl: promoActive ? '20px' : '0', fontWeight: '600 !important', color: promoActive ? '#000' : 'error.main' }}>{data?.selfReferralCode?.code}</Box>{' '}
                      <Tooltip
                        PopperProps={{
                          sx: {
                            width: '200px',
                            marginTop: '50px !important',
                            marginLeft: '-47px !important',

                            '& .MuiTooltip-tooltip': {
                              backgroundColor: 'success.main',
                            },
                          },
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClosePromo}
                        open={openCopyPromo}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={<div style={{ whiteSpace: 'nowrap' }}>{locales[process.env.REACT_APP_LOCALE].promocodeCopy}</div>}>
                        <IconButton sx={{ p: 0, ml: 1, display: promoActive ? 'block' : 'none' }} onClick={handleTooltipOpenPromo} disableRipple={true}>
                          <ContentCopyIcon sx={{ fontSize: '18px', opacity: '0.7' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Typography variant="body1" sx={{ mt: 0.5, mb: 2, whiteSpace: 'nowrap', fontSize: '14px', color: promoActive ? '#000' : 'error.main' }}>
                      {promoActive ? (
                        <>
                          {' '}
                          {locales[process.env.REACT_APP_LOCALE].activeDuring}{' '}
                          <Box sx={{ display: 'inline-block', fontWeight: '600 !important', color: 'success.main' }}>{diffDaysPromo < 1 ? locales[process.env.REACT_APP_LOCALE].nowDay : `${diffDaysPromo} ${locales[process.env.REACT_APP_LOCALE].days}`}</Box>
                        </>
                      ) : (
                        locales[process.env.REACT_APP_LOCALE].promoNotActive
                      )}
                    </Typography>{' '}
                    {!promoActive && (
                      <Button sx={{ color: '#fff' }} variant="contained" size="large" color="success" onClick={handleGeneratePromoCode}>
                        {locales[process.env.REACT_APP_LOCALE].generateNew}
                      </Button>
                    )}
                  </>
                )}
              </Box>
              {promoActive !== null && (
                <Box sx={{ display: 'grid', justifyContent: 'space-evenly', mt: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      {locales[process.env.REACT_APP_LOCALE].accruedAllTime}
                    </Typography>
                    {<Box sx={{ fontWeight: '600 !important', fontSize: '20px' }}>{data?.totalRefferal ? currencyFormat(parseFloat(data?.totalRefferal)) : currencyFormat(0)}</Box>}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ mb: 1, mt: 3 }}>
                      {locales[process.env.REACT_APP_LOCALE].accruedToday}
                    </Typography>
                    {<Box sx={{ fontWeight: '600 !important', fontSize: '20px' }}>{data?.totalRefferalToday ? currencyFormat(parseFloat(data?.totalRefferalToday)) : currencyFormat(0)}</Box>}
                  </Box>
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 8 }}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ width: '200px' }}
                  size="large"
                  onClick={() => {
                    onExit();
                  }}>
                  {locales[process.env.REACT_APP_LOCALE].exitFromAccount}
                </Button>
              </Box>
              {(loadingGenPromo || telegramBindingLoading) && <Loader />}
            </Container>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TrancTable title={locales[process.env.REACT_APP_LOCALE].lastDonates} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PaymentTable title={locales[process.env.REACT_APP_LOCALE].refillShort} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <SettingPage />
          </TabPanel>
        </Box>
      </Paper>
      <PaymentModal open={openPay} typePay={typePay} onClose={handleClosePay} />
      {(data?.id == '7259' || data?.id == '324') && (
        <Box sx={{ fontSize: '24px', marginBottom: '60px' }}>
          <Box sx={{ margin: '30px auto', textAlign: 'center' }}> {moment().format('MMMM YYYY').toLocaleUpperCase()}</Box>
          <Box sx={{ border: '1px solid #000', display: 'grid', gridTemplateColumns: 'min-content min-content', justifyContent: 'center', margin: '0 auto', width: 'min-content', borderBottom: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px 20px 10px 10px', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Дата</Box>
            <Box sx={{ borderBottom: '1px solid #000', padding: '10px 10px 10px 20px' }}>Кол-во зарегестрированных пользователей</Box>
            {regData?.map((itemReg) => (
              <>
                <Box sx={{ padding: '10px 20px 10px 10px', borderRight: '1px solid #000', borderBottom: '1px solid #000', fontWeight: '600 !important' }}>{moment(itemReg?.Date).format('DD.MM').toString()}</Box>
                <Box sx={{ borderBottom: '1px solid #000', padding: '10px 10px 10px 20px', textAlign: 'center', fontWeight: '600 !important' }}>{itemReg?.count}</Box>
              </>
            ))}
          </Box>
        </Box>
      )}
    </DrawerAppBar>
  );
};

export default ProfilePage;
