import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import '../styles/Loader.scss';
const Loader = ({ size = 65, style = {}, isDark = false, noOverlay = false }) => {
  return (
    <>
      {!noOverlay ? (
        <Backdrop sx={{ color: 'primary.main', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <svg class="spinner" width={size + 'px'} height={size + 'px'} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" style={style}>
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
          </svg>
        </Backdrop>
      ) : (
        <svg class="spinner" width={size + 'px'} height={size + 'px'} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" style={style}>
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      )}
    </>
  );
};

export default Loader;
