import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box, IconButton } from '@mui/material';
import { Close, Downloading, Photo, PhotoCamera } from '@mui/icons-material';
import { getUser, resetAvatarReset, uploadAvatar } from '../redux/slices/user.slice';
import AvatarModal from './AvatarModal';
const ProfileAvatar = ({ disabled = false }) => {
  const {
    getUserState: { loading, data, error },
    uploadAvatarState: { data: dataUpload, loading: loadingUpload },
    resetAvatarState: { loading: resetAvatarLoading, data: resetAvatarData },
  } = useSelector((state) => state.user);
  const [uploadedImage, setUploadedImage] = useState(null);
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const onClickUpload = () => {
    hiddenFileInput.current.click();
  };
  const onImageChange = (e) => {
    const [file] = e.target.files;
    setUploadedImage({ file, prevFile: URL.createObjectURL(file) });
  };
  useEffect(() => {
    if (dataUpload) {
      //   dispatch(getUser());
    }
  }, [dataUpload]);

  return (
    <>
      <Box onClick={onClickUpload} sx={{ position: 'relative', width: '100px', height: '100px', borderRadius: '50%', border: `2px solid  ${(data?.avatar || dataUpload) && !loadingUpload && !resetAvatarData ? 'transparent' : 'rgb(81, 81, 81,0.12)'}`, marginBottom: '20px' }}>
        {loadingUpload ? (
          <Downloading sx={{ fontSize: '40px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }} />
        ) : (data?.avatar || dataUpload) && !loadingUpload && !resetAvatarData ? (
          <img style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${dataUpload ? dataUpload : data?.avatar}`} />
        ) : (
          <PhotoCamera sx={{ fontSize: '40px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }} />
        )}
      </Box>
      <input type="file" disabled={disabled} onChange={onImageChange} style={{ display: 'none' }} ref={hiddenFileInput} accept="image/png, image/jpeg, image/jpg" />
      {uploadedImage && (
        <AvatarModal
          prevFile={uploadedImage?.prevFile}
          onClose={() => {
            setUploadedImage(null);
          }}
          onSave={(resizeFile, typeDataUrl) => {
            const formData = new FormData();
            formData.append('image', resizeFile);
            // formData.append('ext', typeDataUrl);

            dispatch(resetAvatarReset());
            dispatch(uploadAvatar({ formData, ext: typeDataUrl }));
          }}
        />
      )}
    </>
  );
};

export default ProfileAvatar;
