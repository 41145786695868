import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs, Select, OutlinedInput, MenuItem, InputLabel, FormControl, CircularProgress, FormControlLabel, Checkbox, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import DrawerAppBar from '../components/MainLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Delete, Edit } from '@mui/icons-material';
import { deleteArticle, getArticleList } from '../redux/slices/article.slice';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { adminReasonList, adminTopup, resetAdminTopup } from '../redux/slices/user.slice';
import { NumericFormat } from 'react-number-format';
import Loading from '../components/Loading';
import Loader from '../components/Loader';

const AdminTopUp = () => {
  const defaultValues = {};
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const form = useForm({ defaultValues });
  const dispatch = useDispatch();
  const {
    adminTopupState: { data: adminTopupData, error: adminTopupError, loading: adminTopupLoading },
    adminReasonListState: { data: reasonList },
    getUserState: { data: userData },
  } = useSelector((state) => state.user);
  const onSubmit = (data) => {
    dispatch(adminTopup({ ...data, adminId: userData?.id }));
  };

  useEffect(() => {
    dispatch(adminReasonList());
    return () => {
      dispatch(resetAdminTopup());
    };
  }, []);
  useEffect(() => {
    if (adminTopupData) {
      setOpenSuccess(true);
      dispatch(resetAdminTopup());
      form.setValue('sum', '');
      form.setValue('userId', '');
      form.setValue('reasonId', '');
    }
  }, [adminTopupData]);
  useEffect(() => {
    if (adminTopupError) {
      setOpenError(true);
      dispatch(resetAdminTopup());
    }
  }, [adminTopupError]);
  return (
    <>
      {userData?.id == process.env.REACT_APP_ARTICLE_EDITOR_ID || userData?.id == process.env.REACT_APP_ARTICLE_EDITOR_ID_SECOND || userData?.id == process.env.REACT_APP_ARTICLE_EDITOR_ID_THIRD ? (
        <DrawerAppBar>
          <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', maxWidth: '600px', margin: '0 auto' }}>
              <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: '20px' }}>
                Начислить
              </Typography>{' '}
              <Controller
                control={form.control}
                rules={{
                  required: true,
                }}
                name="userId"
                defaultValue=""
                render={({ field }) => <TextField {...field} error={form.formState.errors?.userId} label={'ID'} sx={{ marginBottom: '10px' }} variant="outlined" size="medium" autoComplete="off" />}
              />
              <Controller control={form.control} rules={{ required: true }} name="sum" render={({ field }) => <NumericFormat sx={{ marginBottom: '10px' }} customInput={TextField} label={'Сумма'} error={form.formState.errors?.sum} size="medium" {...field} type={'text'} autoComplete="off" />} />
              <FormControl>
                {' '}
                <InputLabel id="categories-id" error={form.formState.errors?.categories}>
                  Причина
                </InputLabel>
                <Controller
                  control={form.control}
                  rules={{
                    required: true,
                  }}
                  name="reasonId"
                  defaultValue=""
                  render={({ field }) => (
                    <Select {...field} labelId="categories-id" error={form.formState.errors?.reasonId} label={'Причина'} size="medium" variant="outlined" input={<OutlinedInput label="Name" />}>
                      {reasonList?.map((articleCategory) => (
                        <MenuItem key={articleCategory?.id} value={articleCategory?.id}>
                          {articleCategory?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <Button variant="contained" onClick={form.handleSubmit(onSubmit)} size="large" sx={{ marginTop: '30px' }}>
                Начислить
              </Button>
            </Box>
          </Container>
          {adminTopupLoading && <Loader />}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openError}
            autoHideDuration={3000}
            sx={{
              '& .MuiPaper-root': { maxWidth: '300px', background: '#f44336' },
            }}
            onClose={() => {
              setOpenError(false);
            }}
            message="Пользователя не существует"
          />{' '}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openSuccess}
            autoHideDuration={3000}
            sx={{
              '& .MuiPaper-root': { maxWidth: '300px', background: '#388e3c' },
            }}
            onClose={() => {
              setOpenSuccess(false);
            }}
            message="Баланс успешно начислен"
          />
        </DrawerAppBar>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminTopUp;
