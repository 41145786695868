import Helmet from 'react-helmet';
import { locales } from '../locales';

export const metaTags = {
  homePage: locales[process.env.REACT_APP_LOCALE].homePage,
  accountPage: locales[process.env.REACT_APP_LOCALE].accountPage,
  authPage: locales[process.env.REACT_APP_LOCALE].authPage,
  aboutPage: locales[process.env.REACT_APP_LOCALE].aboutPage,
  donatePage: locales[process.env.REACT_APP_LOCALE].donatePage,
  rulesPage: locales[process.env.REACT_APP_LOCALE].rulesPage,
  supportPage: locales[process.env.REACT_APP_LOCALE].supportPage,
  termsOfUsePage: locales[process.env.REACT_APP_LOCALE].termsOfUsePage,
  privacyPolicyPage: locales[process.env.REACT_APP_LOCALE].privacyPolicyPage,
  faqPage: locales[process.env.REACT_APP_LOCALE].faqPage,
  reviewPage: locales[process.env.REACT_APP_LOCALE].reviewPage,
  guarantePage: locales[process.env.REACT_APP_LOCALE].guarantePage,
  returnPolicyPage: locales[process.env.REACT_APP_LOCALE].returnPolicyPage,
  profilePage: locales[process.env.REACT_APP_LOCALE].profilePage,
  cartPage: locales[process.env.REACT_APP_LOCALE].cartPage,
  articlePage: locales[process.env.REACT_APP_LOCALE].articlePage,
  ratingPage: locales[process.env.REACT_APP_LOCALE].ratingPage,
  topupPage: locales[process.env.REACT_APP_LOCALE].topupPage,
};
