import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import HomeLayout from '../components/HomeLayout';
import { Button, Container, TextField } from '@mui/material';
import axios from 'axios';
import { getVkReviews, resetVkReviews } from '../redux/slices/app.slice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import VK, { Comments } from 'react-vk';
import moment from 'moment';
import 'moment/locale/ru'; // without this line it didn't work
import { locales } from '../locales';
import { Controller, useForm } from 'react-hook-form';
import { createComment, resetCreateComment } from '../redux/slices/user.slice';
import { PhotoCamera } from '@mui/icons-material';
import Like from '../components/Like';
moment.locale('ru');
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const location = useLocation();
  console.log(location?.pathname);
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ px: { xs: 0, mob: 2 }, py: { xs: 1, mob: 2 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ReviewsPage = ({ meta }) => {
  // const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(null);
  const [hideMoreBtn, setHideMoreBtn] = useState(true);
  const [viewComments, setViewComments] = useState([]);
  const {
    getUserState: { loading, data, error },
    createCommentState: { loading: createCommentLoading, data: createCommentData, error: createCommentError },
  } = useSelector((state) => state.user);
  const { control, handleSubmit, reset, setValue } = useForm();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const dispatch = useDispatch();
  const {
    getVkReviewsState: { data: comments, loading: commentsLoading, error: checkError },
  } = useSelector((state) => state.app);
  useEffect(() => {
    if (page !== null) {
      dispatch(getVkReviews(page));
    }
  }, [page]);
  useEffect(() => {
    setPage(1);
    return () => {
      dispatch(resetVkReviews());
      setViewComments([]);
    };
  }, []);
  useEffect(() => {
    if (createCommentData) {
      dispatch(resetCreateComment());
      setViewComments([]);
      setPage(1);
      dispatch(getVkReviews(1));
      setValue('comment', '');
    }
  }, [createCommentData]);

  useEffect(() => {
    if (comments && !commentsLoading) {
      if (comments?.rows?.length == 0) {
        setHideMoreBtn(false);
      } else {
        setViewComments([...viewComments, ...comments?.rows]);
        setHideMoreBtn(true);
      }
    }
  }, [comments]);

  const onSubmit = (data) => {
    dispatch(createComment({ comment: data.comment }));
  };
  const navigate = useNavigate();
  return (
    <>
      {meta}
      <HomeLayout>
        <Container sx={{ marginBottom: '55px' }}>
          <Typography variant="h4" sx={{ mt: 2, fontSize: '24px' }}>
            Отзывы o Donate Gold
          </Typography>

          <Typography variant="p" sx={{ display: 'block', mt: 1, fontSize: '14px', marginBottom: '60px' }}>
            Нам доверяют тысячи пользователей. Тут вы можете высказаться.
          </Typography>
          <Controller
            control={control}
            rules={{ required: true }}
            name="comment"
            render={({ field }) => <TextField sx={{ display: 'block', '& .MuiOutlinedInput-root': { width: '100%', backgroundColor: '#fff' } }} label={'Ваш отзыв'} variant="outlined" size="small" multiline rows={5} {...field} />}
          />
          <Button onClick={handleSubmit(onSubmit)} disabled={(!data && !loading && error) || createCommentLoading} variant="contained" sx={{ marginTop: '20px' }}>
            Отправить
          </Button>
          {!data && !loading && error && (
            <Box sx={{ marginTop: '10px', color: '#ff0000' }}>
              <Box
                sx={{ textDecoration: 'underline', display: 'inline-block', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/auth');
                }}>
                Войдите в аккаунт
              </Box>
              , чтобы оставить отзыв.
            </Box>
          )}
          {createCommentError?.error == 'COMMENT_NOT_DONATE' ? <Box sx={{ marginTop: '10px', color: '#ff0000' }}>Отзыв можно написать, если вы сделали донат сегодня.</Box> : <></>}
          {createCommentError?.error == 'COMMENT_TODAY_EXIST' ? <Box sx={{ marginTop: '10px', color: '#ff0000' }}>Вы уже оставляли отзыв сегодня.</Box> : <></>}
          {comments?.count ? <Box sx={{ marginTop: '10px', marginBottom: '25px', fontSize: '24px', textAlign: 'center' }}>{comments?.count + ' отзывов'}</Box> : <></>}

          {viewComments?.map((comment, commentIndex) => {
            return (
              <Box sx={{ marginBottom: '25px', display: 'flex', alignItems: 'start', padding: { xs: '15px 0px 40px 15px', mob: '15px 0px 20px 15px' }, backgroundColor: '#fff', borderRadius: '10px' }}>
                {comment?.avatar || comment?.user?.avatar ? (
                  <Box
                    component={'img'}
                    src={comment?.avatar ? comment?.avatar : comment?.user?.avatar ? `${process.env.REACT_APP_SERVER_DOMAIN}/images/${comment?.user?.avatar}` : ''}
                    sx={{ width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover', display: 'block', marginRight: { xs: '10px', mob: '15px' } }}
                  />
                ) : (
                  <Box sx={{ marginRight: { xs: '15px', mob: '20px' }, position: 'relative', minWidth: '40px', height: '40px', borderRadius: '50%', border: `2px solid rgba(81, 81, 81,0.12)` }}>
                    <PhotoCamera sx={{ fontSize: '19px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }} />
                  </Box>
                )}

                <Box>
                  <Box sx={{ display: { xs: 'flex', mob: 'block' }, alignItems: 'center', height: { xs: '44px', mob: 'auto' }, marginBottom: '3px', fontSize: '15px', color: '#000', fontWeight: '600 !important' }}>{comment?.name || comment?.user?.name}</Box>
                  <Box sx={{ fontSize: '14px', color: '#000', marginTop: { xs: '13px', mob: 0 }, width: { xs: 'calc(100% + 36px)', mob: 'auto' }, marginLeft: { xs: '-51px', mob: '0' } }}>{comment?.text}</Box>
                  <Box sx={{ marginTop: '8px', fontSize: '14px', color: ' #9e9e9e', fontStyle: 'italic', width: { xs: 'calc(100% + 51px)', mob: 'auto' }, marginLeft: { xs: '-51px', mob: '0' } }}>{moment(comment?.date).format('DD.MM.YY HH:mm').toString()}</Box>
                </Box>
              </Box>
            );
          })}
          {hideMoreBtn && !commentsLoading && (
            <button
              onClick={() => {
                setPage(page + 1);
                setHideMoreBtn(false);
              }}
              className="vk-comments__more">
              {locales[process.env.REACT_APP_LOCALE].showMore}
            </button>
          )}
          {/* <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={locales[process.env.REACT_APP_LOCALE].vk} {...a11yProps(0)} />
                <Tab label={locales[process.env.REACT_APP_LOCALE].onSite} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ul class="vk-comments__list">
                {viewComments?.map((comment) => {
                  let commentName;
                  let commentPhoto;
                  let commentLink;
                  let commentDate = moment(comment?.date * 1000).format('DD MMMM YYYY в HH:mm');
                  if (comment?.profile) {
                    commentName = `${comment?.profile?.first_name} ${comment?.profile?.last_name}`;
                    commentPhoto = comment?.profile?.photo_50;
                    commentLink = `https://vk.com/id${comment?.profile?.id}`;
                  }
                  if (comment?.group) {
                    commentName = comment?.group?.name;
                    commentPhoto = comment?.group?.photo_50;
                    commentLink = `https://vk.com/${comment?.group?.screen_name}`;
                  }

                  return (
                    <li class="vk-comments__item">
                      <a href={commentLink} target="_blank">
                        <img src={commentPhoto} alt="" class="vk-comments__avatar" />
                      </a>
                      <div class="vk-comments__content">
                        <a href={commentLink} target="_blank" class="vk-comments__name">
                          {commentName}
                        </a>
                        <div class="vk-comments__text">{comment?.text}</div>
                        {comment?.attachments?.map((attach) => {
                          if (attach?.type === 'photo') {
                            return <img class="vk-comments__img" src={attach?.photo?.sizes[4]?.url} />;
                          }
                        })}

                        <div class="vk-comments__date">{commentDate}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {hideMoreBtn && !commentsLoading && (
                <button
                  onClick={() => {
                    setPage(page + 1);
                    setHideMoreBtn(false);
                  }}
                  className="vk-comments__more">
                  {locales[process.env.REACT_APP_LOCALE].showMore}
                </button>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <VK apiId={process.env.REACT_APP_VK_WIDGET_APP_KEY}>
                <div className="" id="vk-comm"></div>
                <Comments elementId={'vk-comm'} />
              </VK>
            </TabPanel>
          </Box> */}
        </Container>
      </HomeLayout>
    </>
  );
};

export default ReviewsPage;
