import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box, Button, Typography } from '@mui/material';
import { locales } from '../locales';
const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: '#060606', color: '#fff', marginTop: 'auto', flexShrink: '1', pt: 2, borderTop: '1px solid rgba(255,255,255,0.2)' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="subtitle2">Donate Gold © 2022 - 2024</Typography>
        <Box sx={{ padding: '10px 40px', maxWidth: '900px', margin: '0 auto', fontSize: '12px', textAlign: 'center', color: '#979696' }}>{locales[process.env.REACT_APP_LOCALE].mainPageText}</Box>
        {/* <Box sx={{ display: 'grid', gridTemplateColumns: { mobile: 'repeat(6,88px)', xs: 'repeat(2,88px)' }, gridGap: '20px', mt: 2 }}>
          <img key={2} src="/visa.png" style={{ height: '31px', width: '88px', objectFit: 'cover' }} />
          <img key={3} src="/mc.png" style={{ height: '31px', width: '88px', objectFit: 'cover' }} />
          <img key={4} src="/mir.png" style={{ height: '31px', width: '88px', objectFit: 'cover' }} />
          <img key={5} src="/qiwi.png" style={{ height: '31px', width: '88px', objectFit: 'cover' }} />
          <img key={6} src="/yoomoney.png" style={{ height: '31px', width: '88px', objectFit: 'cover' }} />
          <a href="https://www.megastock.com/" target="_blank">
            <img src="https://www.webmoney.ru/img/icons/88x31_wm_black.png" alt="www.megastock.com" border="0" />
          </a>
        </Box> */}
        <Box sx={{ display: 'grid', gridTemplateColumns: { mobile: 'repeat(7,auto)', xs: 'repeat(2,auto)' }, gridGap: { mobile: '20px', xs: '10px' }, mt: 2 }}>
          <Button disableRipple={true} onClick={() => navigate('/about')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, color: 'white', display: 'block', fontSize: '14px' }} size="small">
            {locales[process.env.REACT_APP_LOCALE].aboutService}
          </Button>
          {/*process.env.REACT_APP_LOCALE != 'en' && (
            <Button disableRipple={true} onClick={() => navigate('/rating')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, color: 'white', display: 'block', fontSize: '14px' }} size="small">
              {locales[process.env.REACT_APP_LOCALE].rating}
            </Button>
          )*/}
          <Button disableRipple={true} onClick={() => navigate('/donate')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, color: 'white', display: 'block', fontSize: '14px' }} size="small">
            {locales[process.env.REACT_APP_LOCALE].instruction}
          </Button>
          <Button disableRipple={true} onClick={() => navigate('/faq')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, color: 'white', display: 'block', fontSize: '14px', minWidth: 0 }} size="small">
            {locales[process.env.REACT_APP_LOCALE].faq}
          </Button>
          {/*process.env.REACT_APP_LOCALE != 'en' && (
            <Button disableRipple={true} onClick={() => navigate('/reviews')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, color: 'white', display: 'block', fontSize: '14px' }} size="small">
              {locales[process.env.REACT_APP_LOCALE].reviews}
            </Button>
          )*/}

          <Button disableRipple={true} onClick={() => navigate('/guarante')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, color: 'white', display: 'block', fontSize: '14px' }} size="small">
            {locales[process.env.REACT_APP_LOCALE].guarantee}
          </Button>
          {/* <Button disableRipple={true} onClick={() => navigate('/support')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, color: 'white', display: 'block', fontSize: '14px' }} size="small">
            {locales[process.env.REACT_APP_LOCALE].support}
          </Button>*/}
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: { mobile: 'repeat(3,auto)', xs: 'repeat(1,auto)' }, gridGap: { mobile: '10px', xs: '5px' }, mt: 1 }}>
          <Button disableRipple={true} onClick={() => navigate('/terms-of-use')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, opacity: 0.8, color: 'white', display: 'block', fontSize: '12px' }} size="small">
            {locales[process.env.REACT_APP_LOCALE].termsOfUse}
          </Button>
          <Button disableRipple={true} onClick={() => navigate('/privacy-policy')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, opacity: 0.8, color: 'white', display: 'block', fontSize: '12px' }} size="small">
            {locales[process.env.REACT_APP_LOCALE].privacyPolicy}
          </Button>
          <Button disableRipple={true} onClick={() => navigate('/return-policy')} sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'transparent' }, opacity: 0.8, color: 'white', display: 'block', fontSize: '12px' }} size="small">
            {locales[process.env.REACT_APP_LOCALE].returnPolicy}
          </Button>
        </Box>
        <Typography style={{ fontSize: '12px', opacity: '0.3', margin: '0 auto' }}>v 3.1</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
