import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import MinusIcon from '@mui/icons-material/Remove';
import { blue } from '@mui/material/colors';
import { Box, DialogActions, DialogContent, DialogContentText, IconButton, Input, InputAdornment, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { currencyFormat } from '../utils/currencyFormat';
import { useDispatch, useSelector } from 'react-redux';
import { initPaymentCard, resetInitPaymentCardState, setCart, setUpdateCartEmpty } from '../redux/slices/user.slice';
import Loading from './Loading';
import axios from 'axios';
import md5 from 'md5';
import { useEffect } from 'react';
import TransitionDialog from './TransitionDialog';
import { locales } from '../locales';
import { Check, CheckCircle, ChevronLeft } from '@mui/icons-material';
import GameTabs from './GameTabs';
import { calcPrice } from '../utils/calcPrice';

export default function SelectPackageModal(props) {
  const { tabs, setValueForm, playerId, server, onClose, open, onNext, gameId, isBoost } = props;
  const handleClose = () => {
    onClose();
  };
  const [value, setValue] = React.useState('1');
  const {
    getPackageState: { data: packageList },
  } = useSelector((state) => state.package);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (packageList && gameId) {
      const defaultPackage = packageList?.find((item, index) => item?.typeGameId === gameId);
      setValue(defaultPackage?.code);
    }
  }, [packageList, gameId, open]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="h5" sx={{ maxWidth: { xs: '203px', mob: 'none' }, mb: '15px', fontWeight: '600', fontSize: { mob: '24px', xs: '18px !important' }, textAlign: 'start' }}>
          {locales[process.env.REACT_APP_LOCALE].choicePack}
        </Typography>
        <GameTabs list={tabs} value={isBoost} setValue={setValueForm} isInner />
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group" sx={{ mb: '8px', mx: 'auto' }}></FormLabel>
          <RadioGroup sx={{ display: 'flex', flexDirection: 'column' }} aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
            {[...(packageList?.filter((pack) => pack?.typeGameId === gameId && !pack?.isChatPackage && !!(isBoost ? pack?.priceNEcards : pack?.priceMoogold)) || [])]
              ?.sort(function (x, y) {
                return x.order - y.order;
              })
              ?.map(
                (packageItem) =>
                  packageItem?.typeGameId === gameId && (
                    <FormControlLabel
                      sx={{
                        '& .Mui-checked svg': {
                          color: '#0089ff',
                          background: 'transparent',
                        },

                        '& img': { userSelect: 'none', pointerEvents: 'none', boxSizing: 'border-box', p: '0px', width: '140px', height: '80px', objectFit: 'contain', display: 'block' },
                      }}
                      value={packageItem?.code}
                      control={
                        <Radio
                          sx={{
                            '& svg': { color: 'rgba(0, 0, 0, 0.07)' },
                            '&:after': {
                              content: '""',
                              display: 'block',
                              width: '20px',
                              height: '20px',
                              borderRadius: '50%',

                              background: 'rgba(0, 0, 0, 0.02)',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%,-50%)',
                            },
                          }}
                        />
                      }
                      label={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2px' }}>
                          <Box class="game-card__middle" sx={{ marginBottom: '5px', fontSize: '14px' }}>
                            <img
                              src={`/game-icon-${packageItem?.typeGameId}.png`}
                              style={{
                                objectFit: 'contain',
                                display: 'block',
                                width: '22px',
                                height: '22px',
                              }}
                            />
                            <Box sx={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}> {packageItem?.name}</Box>
                          </Box>
                          <Box sx={{ whiteSpace: 'nowrap', fontWeight: '600 !important', fontSize: '18px', marginLeft: '12px' }}>{currencyFormat(calcPrice({ isBoost, priceMoogold: packageItem?.priceMoogold, priceNEcards: packageItem?.priceNEcards, discount: packageItem?.discount }))}</Box>
                        </div>
                      }
                    />
                  ),
              )}
          </RadioGroup>
        </FormControl>
      </div>

      <Box style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '50px 170px', columnGap: '20px', marginTop: '20px' }}>
        <Box
          sx={{
            gridColumn: '1/3',
            marginLeft: 'auto',
            marginBottom: '10px',
            border: '1px solid #2e7d32',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            whiteSpace: 'nowrap',
            width: 'min-content',
            fontSize: '12px',
            padding: '2px 5px',
            color: '#000',
            borderRadius: '4px',
          }}>
          <CheckCircle sx={{ fontSize: '14px', color: 'success.light', marginRight: '4px' }} />
          <Box>{server} •&nbsp;</Box> <Box> {playerId}</Box>
        </Box>
        <Box
          onClick={onClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.10)',
            height: '36.5px',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'all 0.3s',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.2)',
              boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
          }}>
          <ChevronLeft style={{ opacity: '0.6', transform: 'scale(1.3)' }} />
        </Box>

        <Button
          color="success"
          autoFocus
          variant="contained"
          sx={{ margin: '0 !important', color: '#fff' }}
          onClick={() => {
            onNext(value);
          }}>
          Задонатить
        </Button>
      </Box>
    </>
  );
}
