import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs, Select, OutlinedInput, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import DrawerAppBar from '../components/MainLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Delete, Edit } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import UploadEditorFile from '../components/UploadEditorFile';
import UploadImage from '../components/UploadImage';
import CodeEditor from '../components/CodeEditor';
import { createArticle, getArticleCategoryList, getArticleSingle, resetCreateArticle } from '../redux/slices/article.slice';
import ArticleAddEdit from '../components/ArticleAddEdit';

const AdminArticleAddEditPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const {
    getUserState: { data: userData },
  } = useSelector((state) => state.user);
  const {
    getArticleSingleState: { data: articleSingleData, loading: articleSingleLoading, error: articleSingleError },
  } = useSelector((state) => state.article);
  useEffect(() => {
    dispatch(getArticleSingle(slug));
  }, []);

  return userData?.id == process.env.REACT_APP_ARTICLE_EDITOR_ID || userData?.id == process.env.REACT_APP_ARTICLE_EDITOR_ID_SECOND ? (
    <DrawerAppBar>
      {articleSingleData ? <ArticleAddEdit data={articleSingleData} /> : articleSingleLoading ? <></> : articleSingleError ? <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', fontWeight: '600' }}>Статья не найдена</Box> : <></>}{' '}
    </DrawerAppBar>
  ) : (
    <></>
  );
};

export default AdminArticleAddEditPage;
