import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import clsx from 'clsx';
import { Box, Button, Container } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HomeLayout from '../components/HomeLayout';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import axios from 'axios';
import Loading from '../components/Loading';
import Loader from '../components/Loader';
const AccountActivatedPage = () => {
  const [activeStatus, setActiveStatus] = useState('loading');
  const navigate = useNavigate();
  const { link } = useParams();
  const loc = useLocation();
  console.log(loc);
  useEffect(() => {
    console.log(`${process.env.REACT_APP_SERVER_URL}/activate/${link}`);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/activate/${link}`)
      .then((response) => {
        setActiveStatus('success');
      })
      .catch((error) => {
        // console.log(error);
        navigate('/');
      });
  }, [link]);

  return (
    <>
      <HomeLayout>
        <Container>
          {activeStatus == 'success' ? (
            <Box sx={{ padding: '250px 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <LockOpenOutlinedIcon sx={{ opacity: 0.5, fontSize: '40px', marginBottom: '15px' }} />
              <Box sx={{ textAlign: 'center', marginBottom: '20px', fontSize: '22px' }}>Ваша учетная запись активирована</Box>
              <Box sx={{ marginBottom: '30px', opacity: '0.8', maxWidth: '600px', textAlign: 'center' }}>Приятных покупок!</Box>
              <Button
                onClick={() => {
                  navigate('/');
                }}
                variant="contained">
                На главную
              </Button>
            </Box>
          ) : (
            <div style={{ height: '687px' }}>
              {' '}
              <div className="" style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                {' '}
                <Loader noOverlay />
              </div>
            </div>
          )}
        </Container>
      </HomeLayout>
    </>
  );
};

export default AccountActivatedPage;
