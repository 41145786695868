import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const createArticle = createAsyncThunk('article/createArticle', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/article/create`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const updateArticle = createAsyncThunk('article/updateArticle', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/article/update`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const deleteArticle = createAsyncThunk('article/deleteArticle', (data, { rejectWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });

  return axios
    .post(`${process.env.REACT_APP_SERVER_URL}/article/delete`, data, {
      headers: {
        request_token: token,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getArticleCategoryList = createAsyncThunk('article/getArticleCategoryList', (data, { rejectWithValue }) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/article-category/list`)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getArticleList = createAsyncThunk('article/getArticleList', (data, { rejectWithValue }) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/article/list`, {
      params: {
        page: data?.page,
        category: data?.category,
        isHomeArticle: data?.isHomeArticle,
      },
    })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
export const getArticleSingle = createAsyncThunk('article/getArticleSingle', (slug, { rejectWithValue }) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}/article/${slug}`)
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
});
const articleInitialState = {
  createArticleState: {
    data: null,
    loading: false,
    error: null,
  },
  updateArticleState: {
    data: null,
    loading: false,
    error: null,
  },
  getArticleCategoryListState: {
    data: null,
    loading: false,
    error: null,
  },
  getArticleListState: {
    data: null,
    loading: false,
    error: null,
  },
  getArticleSingleState: {
    data: null,
    loading: false,
    error: null,
  },
  deleteArticleState: {
    data: null,
    loading: false,
    error: null,
  },
  activeArticleCategory: undefined,
};

const articleSlice = createSlice({
  name: 'article',
  initialState: articleInitialState,
  reducers: {
    setActiveArticleCategory(state, action) {
      state.activeArticleCategory = action.payload;
    },
    resetCreateArticle(state) {
      state.createArticleState = articleInitialState.createArticleState;
    },
    resetArticleList(state) {
      state.getArticleListState = articleInitialState.getArticleListState;
    },
    resetArticleSingle(state) {
      state.getArticleSingleState = articleInitialState.getArticleSingleState;
    },
    resetUpdateArticle(state) {
      state.updateArticleState = articleInitialState.updateArticleState;
    },
    resetDeleteArticle(state) {
      state.deleteArticleState = articleInitialState.deleteArticleState;
    },
  },
  extraReducers: {
    [createArticle.pending]: (state, action) => {
      state.createArticleState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [createArticle.fulfilled]: (state, action) => {
      state.createArticleState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [createArticle.rejected]: (state, action) => {
      state.createArticleState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [deleteArticle.pending]: (state, action) => {
      state.deleteArticleState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [deleteArticle.fulfilled]: (state, action) => {
      state.deleteArticleState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [deleteArticle.rejected]: (state, action) => {
      state.deleteArticleState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [updateArticle.pending]: (state, action) => {
      state.updateArticleState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [updateArticle.fulfilled]: (state, action) => {
      state.updateArticleState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [updateArticle.rejected]: (state, action) => {
      state.updateArticleState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getArticleCategoryList.pending]: (state, action) => {
      state.getArticleCategoryListState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [getArticleCategoryList.fulfilled]: (state, action) => {
      state.getArticleCategoryListState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getArticleCategoryList.rejected]: (state, action) => {
      state.getArticleCategoryListState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getArticleList.pending]: (state, action) => {
      state.getArticleListState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [getArticleList.fulfilled]: (state, action) => {
      state.getArticleListState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getArticleList.rejected]: (state, action) => {
      state.getArticleListState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    [getArticleSingle.pending]: (state, action) => {
      state.getArticleSingleState = {
        loading: true,
        data: null,
        error: null,
      };
    },
    [getArticleSingle.fulfilled]: (state, action) => {
      state.getArticleSingleState = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    [getArticleSingle.rejected]: (state, action) => {
      state.getArticleSingleState = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
  },
});
export const { resetCreateArticle, resetUpdateArticle, resetDeleteArticle, setActiveArticleCategory, resetArticleSingle, resetArticleList } = articleSlice.actions;
export const articleReducer = articleSlice.reducer;
