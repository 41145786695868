import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs, Select, OutlinedInput, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import DrawerAppBar from '../components/MainLayout';
import ArticleAddEdit from '../components/ArticleAddEdit';
import { getArticleCategoryList, getArticleList, resetArticleList, setActiveArticleCategory } from '../redux/slices/article.slice';
import ArticleItem from '../components/ArticleItem';
import { useSearchParams } from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';

const ArticleListPage = ({ meta }) => {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(1);
  const [data, setData] = useState([]);
  const {
    getArticleListState: { data: articleList, loading: articleListLoading },
    getArticleCategoryListState: { data: articleCategories, loading: articleCategoryLoading },
    activeArticleCategory,
  } = useSelector((state) => state.article);
  useEffect(() => {
    dispatch(getArticleCategoryList());
    return () => {
      dispatch(resetArticleList());
    };
  }, []);

  useEffect(() => {
    const categoryParams = searchParams.get('category');
    if (categoryParams) {
      dispatch(setActiveArticleCategory(searchParams.get('category')));
    } else {
      dispatch(setActiveArticleCategory(null));
    }
  }, [searchParams]);
  useEffect(() => {
    if (activeArticleCategory !== undefined) {
      dispatch(getArticleList({ page: 1, category: activeArticleCategory || undefined }));
      setActivePage(1);
      setData([]);
    }
  }, [activeArticleCategory]);
  useEffect(() => {
    if (activePage > 1) {
      dispatch(getArticleList({ page: activePage, category: activeArticleCategory || undefined }));
    }
  }, [activePage]);
  useEffect(() => {
    if (articleList) {
      setData((prevData) => [...prevData, ...articleList?.rows]);
    }
  }, [articleList]);

  return (
    <>
      <Container>
        {meta}
        <h2>Статьи Donate Gold</h2>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'start', mb: '40px' }}>
          {articleCategories?.map((articleCategory) => (
            <Box
              sx={{
                marginRight: '8px',
                marginBottom: '11px',
                padding: '6px 14px',
                backgroundColor: 'hsl(0, 0%, 97%)',
                fontSize: '14px',
                borderRadius: '50px',
                userSelect: 'none',
                cursor: 'pointer',
                transition: 'all 0.3s',
                ...(activeArticleCategory == articleCategory.id && {
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  color: 'rgba(25, 118, 210, 1)',
                }),
              }}
              onClick={() => {
                if (!articleCategoryLoading) {
                  if (activeArticleCategory == articleCategory.id) {
                    setSearchParams({});
                  } else {
                    setSearchParams({ category: articleCategory.id });
                  }
                }
              }}>
              {articleCategory?.name}
            </Box>
          ))}
        </Box>
        <Box sx={{ rowGap: '60px', columnGap: '40px', display: 'grid', gridTemplateColumns: 'repeat(auto-fill,minmax(260px,1fr))' }}>
          {data?.map((article) => (
            <ArticleItem key={article.id} {...article} />
          ))}{' '}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: '156.5px' }}>
          {' '}
          {!articleListLoading && Math.ceil(articleList?.count / 6) > activePage && (
            <Button
              sx={{ mt: '40px' }}
              disableRipple
              onClick={() => {
                if (activePage) {
                  setActivePage(activePage + 1);
                } else {
                  setActivePage(2);
                }
              }}>
              Показать ещё
            </Button>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ArticleListPage;
