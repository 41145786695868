import { Helmet } from 'react-helmet';

export const en = {
  aboutService: 'About Service',
  main: 'Main',
  mainPage: 'Main Page',
  rating: 'Rating',
  ratings: 'Ratings',
  instruction: 'Instruction',
  faq: 'FAQ',
  reviews: 'Reviews',
  mainPage: 'Main Page',
  choicePack: 'Choose a pack',
  choiceNickText: 'You can only change your nickname on the site once',
  hideInTop: 'Hide from TOP 100',
  showInTop: 'Show in TOP 100',
  lastDonates: 'Last Donates',
  account: 'My account',
  top100: 'Top 100',
  forMonth: 'For the month',
  usSupport: 'We are supported',
  top100MonthText: 'The table shows users with the largest total donation of game currency for the month. The icons on the right show which game the user donated the most. The rating is updated in real time. You can hide yourself from the rankings in your account settings.',
  top100Text:
    'The table shows the TOP 100 users with the largest total donation of game currency for the entire time of using the site. The icons on the right show which game the user donated the most. The rating is updated in real time. You can hide yourself from the rankings in your account settings.',
  hideInSupport: 'Hide from support list',
  donateSuccess: 'Donate successfully sent to the game',
  showInSupport: 'Show in support list',
  yourInRating: 'Your ranking is: ',
  yourCanHideNick: 'You can hide yourself from the top 100 ratings and support list.',
  areYourChoiceNick: 'You already changed your nickname',
  guarantee: 'Guarantees',
  support: 'Support',
  listGame: 'List of games',
  cabinet: 'Cabinet',
  accountNotFound: (
    <>
      Account not found. <br />
      Check your account ID before paying
    </>
  ),
  enterYourId: 'Enter your game ID',
  promocode1Title: 'Genshin Impact',
  promocode1Subtitle: 'Star Festival Wings #6',
  promocode1Text: 'Activation code set #6 expires on April 19, 2024 23:59 (UTC+8)',

  promocode2Title: 'Gold Fish (+100 ₽)',
  promocode2Subtitle: 'One attempt per day',
  promocode2text: (
    <>
      {' '}
      3 goldfish swim at random times every day. <br /> Try your luck and try to catch one!
    </>
  ),
  promocode1SuccessTitle: 'Hurrah! Caught! 100 ₽ has been credited to your account!',
  promocode1SuccessText: 'Reload the page for the sum to appear.',
  promocode1ErrorTitle: 'Alas, you are out of luck today. Try your luck tomorrow!',
  promocode1ErrorText: 'New promo codes coming later.',
  promocode1Btn: "I'm lucky",
  idInGame: 'Game ID',
  yourId: 'Your ID:',
  idNotFound: 'ID not found',
  countPackages: 'Number of stamps',
  order: 'Order',
  buy: 'Buy',
  slider11: 'Season 25 Essence 2 is now available!',
  slider12: "New Hunter - Ithaqua, Night's Watch",
  slider21: 'Update 3.5. the second phase is already in the game!',
  slider22: 'Ayaka and Shen He',
  slider31: 'MLBB X Transformers Crossover',
  slider32: 'The new battle between Autobots and Decepticons is already in the game!',
  slider41: 'New costume "Drop of Eternal Moon"',
  slider42: 'and the upgradeable M249 weapon are now available!',
  insufficientFunds: 'Not enough funds',
  termsOfPageText: (
    <>
      These rules govern the terms of the relationship between the user and the site service{' '}
      <a href="https://donate-gold.com" class="link">
        https://donate-gold.ru
      </a>
      . By using this website, you automatically agree to this User Agreement.
      <br />
      <br />
      <b>1. Terms</b>
      <br />
      Service (we, our) - website{' '}
      <a href="https://donate-gold.com" class="link">
        https://donate-gold.ru
      </a>{' '}
      (Donate Gold). User (you, your) - a person using the Donate Gold website. Parties - service and user together, and separately - a party. Personal account - a virtual account of the Donate Gold website user. Game currency - the general name of the virtual currency that is used to acquisition of
      virtual goods in various mobile and computer games. Game account - a personal game account of the user of the corresponding mobile or computer game, with a unique identifier (ID).
      <br />
      <br />
      <b>2. Subject of the Agreement</b>
      <br />
      The service provides services for replenishing the game currency of game accounts of users. To provide the game currency replenishment service, the user debits the cost of the game currency in rubles from his personal account, independently choosing the game and the denomination of the game
      currency. Replenishment procedure game currency passes automatically through the API of the developer of the corresponding game. To replenish the personal account, the user makes a payment in rubles with their subsequent transfer to the balance of the personal account. Replenishment of the
      personal account and replenishment of the game currency in the game is final and unchangeable, funds and game currency cannot be returned to the user.
      <br />
      <br />
      <b>3. Rights and obligations of the parties</b>
      <br />
      The user is obliged to carry out all actions on the Donate Gold website independently and to fulfill the direct goals of the service. The user is prohibited from circumventing the technical limitations of the service, copying, decompiling or disassembling the Donate Gold website and performing
      actions aimed at change the functioning and performance of the site. The user has the right to receive complete and up-to-date information about the services of the service on the site. The service undertakes to provide the user with the declared services qualitatively and in full. The parties
      undertake to independently provide confidentiality of information and information required to access the service (login and password), and not to disclose such information.
      <br />
      <br />
      <b>4. Disclaimer</b>
      <br />
      If the user has entered incorrect data to replenish the game currency of his game account (ID - the player's identification number), after the replenishment procedure, a refund is not possible. The service is not responsible for the user's losses that arose as a result of illegal actions from
      third parties, including those related to illegal access to the user account. The service is not responsible for replenishing the game currency to the user's game account, which was stolen, lost or misused without the user's permission. Software and hardware errors of the service that occurred
      when using the service by the user and led to the difficulty of providing the service are force majeure circumstances and remove responsibility from the service for failure to fulfill obligations.
      <br />
      <br />
      <b>5. Personal data</b>
      <br />
      By accepting this Agreement, the user agrees to the processing of information, including the user's personal data, provided when using the service, for the purposes of executing the subject of the agreement. Processing of personal data - any action (operation) or set of actions (operations)
      performed with or without the use of automation tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), retrieval, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction
      of personal data. The user has the right to withdraw consent to the processing of personal data by sending a letter to the e-mail address indicated on the Donate Gold website.
      <br />
      <br />
      <b>6. Other Agreements</b>
      <br />
      The service is not the copyright holder of the game currency of the games presented, does not determine the order of their use and functioning in the game. This User Agreement includes links to the individual Game Developers User Agreements. The user also expresses his consent with them when
      replenishing the game currency of the corresponding game.
      <br />
      Identity V -{' '}
      <a href=" https://game.longeplay.com.tw/member/complete_agreement" class="link">
        https://game.longeplay.com.tw/member/complete_agreement
      </a>{' '}
      <br />
      <br />
      <b>7. Cookies</b>
      <br />
      We use cookies on our website. By using the Donate Gold website, you agree to the use of cookies in accordance with our privacy policy.
      <br />
      <br />
      <b>8. Dispute Resolution</b>
      <br />
      The parties will endeavor to resolve all disputes and disagreements by negotiation by e-mail. If the parties have not reached an agreement, a further decision is subject to judicial resolution at the location of the owner of the service.
      <br />
      <br />
      <b>9. Changes to the terms of the agreement</b>
      <br />
      We have the right to unilaterally change the terms of this user agreement without notice to the user. The current version of the user agreement is located at{' '}
      <a href="https://donate-gold.ru/rules" class="link">
        {' '}
        given{' '}
      </a>{' '}
      page.
      <br />
      <br />
    </>
  ),
  totalPayable: 'Total payable',
  nick: 'Nick',
  pay: 'Pay',
  date: 'Date',
  removeAvatar: 'Remove Avatar',
  numPayment: 'Payment number',
  game: 'Game',
  writeToUs: 'write to us',
  forSecurityText: 'For security reasons, your donations are displayed for the last three days. To receive the archive of donations',
  ifErrorText:
    'If there was an error sending a donation, write to us and we will quickly deal with it. In some games, statuses can take up to two hours to update, such as Genshin Impact. In fact, the donation may already come to you, and the site will have the status "in progress". It will update over time.',
  sendError: 'Send error',
  writeToUs: 'Write to us',
  avatar: 'Avatar',
  nick: 'Nick',
  resetPassword: 'Reset password',
  settingAccount: 'Account Settings',
  nickOnSite: 'Nickname on site',
  installAvatarText: 'To install an avatar, click on it',
  resetThePassword: 'Reset password',
  paymentNumber: 'Payment number',
  userNotExist: 'This user does not exist',
  getNewPassowordText: 'To get a new password, enter your nickname on the website and your registration email',
  newPromocodeText: 'New promo codes coming later.',
  activeInGameText: 'Activate it in game or official site.',
  pack: '',
  server: 'Server',
  status: 'Status',
  supportListText: 'Cinnamon rolls support us. You are automatically included in this list if you sent a cash heart when paying. We thank you!',
  rulesText: (
    <>
      These rules govern the terms of the relationship between the user and the site service{' '}
      <a href="https://donate-gold.com" class="link">
        https://donate-gold.ru
      </a>
      . By using this website, you automatically agree to this User Agreement.
      <br />
      <br />
      <b>1. Terms</b>
      <br />
      Service (we, our) - website{' '}
      <a href="https://donate-gold.com" class="link">
        https://donate-gold.ru
      </a>{' '}
      (Donate Gold). User (you, your) - a person using the Donate Gold website. Parties - service and user together, and separately - a party. Personal account - a virtual account of the Donate Gold website user. Game currency - the general name of the virtual currency that is used to acquisition of
      virtual goods in various mobile and computer games. Game account - a personal game account of the user of the corresponding mobile or computer game, with a unique identifier (ID).
      <br />
      <br />
      <b>2. Subject of the Agreement</b>
      <br />
      The service provides services for replenishing the game currency of game accounts of users. To provide the game currency replenishment service, the user debits the cost of the game currency in rubles from his personal account, independently choosing the game and the denomination of the game
      currency. Replenishment procedure game currency passes automatically through the API of the developer of the corresponding game. To replenish the personal account, the user makes a payment in rubles with their subsequent transfer to the balance of the personal account. Replenishment of the
      personal account and replenishment of the game currency in the game is final and unchangeable, funds and game currency cannot be returned to the user.
      <br />
      <br />
      <b>3. Rights and obligations of the parties</b>
      <br />
      The user is obliged to carry out all actions on the Donate Gold website independently and to fulfill the direct goals of the service. The user is prohibited from circumventing the technical limitations of the service, copying, decompiling or disassembling the Donate Gold website and performing
      actions aimed at change the functioning and performance of the site. The user has the right to receive complete and up-to-date information about the services of the service on the site. The service undertakes to provide the user with the declared services qualitatively and in full. The parties
      undertake to independently provide confidentiality of information and information required to access the service (login and password), and not to disclose such information.
      <br />
      <br />
      <b>4. Disclaimer</b>
      <br />
      If the user has entered incorrect data to replenish the game currency of his game account (ID - the player's identification number), after the replenishment procedure, a refund is not possible. The service is not responsible for the user's losses that arose as a result of illegal actions from
      third parties, including those related to illegal access to the user account. The service is not responsible for replenishing the game currency to the user's game account, which was stolen, lost or misused without the user's permission. Software and hardware errors of the service that occurred
      when using the service by the user and led to the difficulty of providing the service are force majeure circumstances and remove responsibility from the service for failure to fulfill obligations.
      <br />
      <br />
      <b>5. Personal data</b>
      <br />
      By accepting this Agreement, the user agrees to the processing of information, including the user's personal data, provided when using the service, for the purposes of executing the subject of the agreement. Processing of personal data - any action (operation) or set of actions (operations)
      performed with or without the use of automation tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), retrieval, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction
      of personal data. The user has the right to withdraw consent to the processing of personal data by sending a letter to the e-mail address indicated on the Donate Gold website.
      <br />
      <br />
      <b>6. Other Agreements</b>
      <br />
      The service is not the copyright holder of the game currency of the games presented, does not determine the order of their use and functioning in the game. This User Agreement includes links to the individual Game Developers User Agreements. The user also expresses his consent with them when
      replenishing the game currency of the corresponding game.
      <br />
      Identity V -{' '}
      <a href=" https://game.longeplay.com.tw/member/complete_agreement" class="link">
        https://game.longeplay.com.tw/member/complete_agreement
      </a>{' '}
      <br />
      <br />
      <b>7. Cookies</b>
      <br />
      We use cookies on our website. By using the Donate Gold website, you agree to the use of cookies in accordance with our privacy policy.
      <br />
      <br />
      <b>8. Dispute Resolution</b>
      <br />
      The parties will endeavor to resolve all disputes and disagreements by negotiation by e-mail. If the parties have not reached an agreement, a further decision is subject to judicial resolution at the location of the owner of the service.
      <br />
      <br />
      <b>9. Changes to the terms of the agreement</b>
      <br />
      We have the right to unilaterally change the terms of this user agreement without notice to the user. The current version of the user agreement is located at{' '}
      <a href="https://donate-gold.ru/rules" class="link">
        https://donate-gold.ru/rules
      </a>
    </>
  ),
  ourIdOnSite: 'Your Site ID',
  vk: 'Vkontakte',
  onSite: 'On site',
  exitFromAccount: 'Logout',
  guaranteText: (
    <>
      {' '}
      <b style={{ fontWeight: '600 !important' }}>Data Security</b>
      <br />
      Our database does not store your personal data and we do not ask you for it. We only store your email, which is required when registering an account. We do not send you spam or promotional third-party offers. Our service works on the secure https protocol and uses SSL security certificate. All
      transactions on the site take place through official banking gateways, where the transmitted data is securely protected and encrypted.
      <br />
      <br />
      Game currency replenishment guarantees
      <br />
      Game currency through our service comes to your game accounts instantly. Exceptions may be some games, and this is indicated in the game card. All deposits are absolutely legal, there will be no bans on your game accounts. We work either directly through the developer's API, or we work with
      official integrators such as OffGamers, Codashop, Razer, SEA Gamer and more. All replenishment goes only to your game ID. We do not request or store login and password data from your game account.
    </>
  ),

  faqTitle: 'Questions and answers',
  instructionContent: (
    <>
      <iframe style={{ width: '100%', height: '600px' }} src="https://vk.com/video_ext.php?oid=-217542887&id=456239022&hash=d53fb04986567dec&hd=4" width="1920" height="1080" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
    </>
  ),
  faqPageText: (
    <>
      How does the service work?
      <br />
      Our website is fully automated. You can buy game currency and donate yourself to your game, indicating only the game ID.
      <br />
      <br />
      It is safe?
      <br />
      Yes, it is absolutely safe, since all deposits go through the developer's API or through official integrators such as OffGamers, Codashop, Razer, SEA Gamer and others. Your accounts will not be banned.
      <br />
      <br />
      Will I receive bonus in-game currency?
      <br />
      Yes, the bonus game currency comes in exactly the same way as if you bought packs through the game itself. Below is an example of buying seals in the game Identity V pack of 60 seals. As you can see, the player receives 63 seals, where 3 seals are bonus.
      <br />
      <br />
      <img src="/idv_bonus.jpg" style={{ height: 'auto', width: '100%' }} />
      <br />
      <br />
      <img src="/idv_bonus2.jpg" style={{ height: 'auto', width: '100%' }} />
      <br />
      <br />
      Do you provide receipts for both the App Store and Google Play?
      <br />
      There are certainly checks for replenishing the balance of your personal account. And instead of donation checks, we provide a unique number of your transaction to the game. This number is recorded in the developer's database and indicates that it was you who made the donation. Also in your
      personal account you have a date donate and the amount of game currency you received.
      <br />
      <br />
    </>
  ),
  technicalWorkText: 'This site is currently undergoing technical work. The site will be up and running soon',
  aboutPageText: (
    <>
      {' '}
      Our service allows you to donate to mobile and PC games directly to developers through their API. All your transactions are absolutely legal. There will be no bans on your accounts, verified by thousands of users.
      <br />
      <br />
      Let me briefly explain how this works. Every modern game has an in-game currency, be it crystals, coins, seals, gold and other names. This is a donated currency that is bought with real money by players. The player essentially exchanges real currency for virtual currency, and buy various
      content in the game with it. To buy donated currency in games, there are shops where the player, choosing the appropriate denomination (currency pack), makes a purchase of currency (donat). The purchase itself takes place through an application store, such as the App Store or Google Play.
      These platforms deduct a large percentage of the player's transfer from the developer. And in order to reduce their costs, each game developer has the so-called direct sales of game currency (Direct Payment or Direct Top Up). <br />
      <br />
      <img src="/shema.jpg" style={{ maxWidth: '100%' }} />
      <br />
      <br />
      In this case, the game currency is not sold through the platforms of the giants App Store and Google Play, but either directly through the developer and its web resource or through payment aggregators. At the same time, the technical mechanics of replenishing an account with a virtual game
      currency in the game remains the same. All Transactions occur through the developer's API. The only difference is that you indicate your game account ID where you want to donate. Our service does just that.
      <br />
      <br />
    </>
  ),
  mainPageText: ' Donate Gold is a service that allows you to independently donate to mobile and computer games using a single personal account. Read the instructions and rules of service. To open a personal account, please register.',
  settings: 'Settings',
  payments: 'Payments',
  donates: 'Donates',
  donateLastThreeDays: 'There were no donations in the last three days',
  price: 'Price',
  sum: 'Sum',
  replenishmentOfPersonal: 'There have been no replenishments to the personal account in the last three days',
  showMore: 'Show more',
  promocodeText: ` On the page you can find various promotional codes for games, services and shops. Some promo codes are permanent, some are temporary. Some are free, others are purchased. To buy a promotional code, you must have enough funds on your personal account balance. Click buy, money
        will be debited from your account balance and you will receive a promo code. You can replenish the balance through your personal account.`,
  forSecurityText: (
    <>
      {' '}
      For security reasons, information about your personal account replenishment is displayed for the last three days. To receive payment archive{' '}
      <a class="link" href="https://donate-gold.ru/support">
        write to us
      </a>
      .
    </>
  ),
  packageNotAvailable: (
    <>
      This package is temporarily unavailable, contact us at{' '}
      <a style={{ color: '#e08418' }} href="https://vk.com/im?sel=-213480825">
        in contact with
      </a>{' '}
      or in{' '}
      <a style={{ color: '#e08418' }} href="https://t.me/DonateGold">
        telegram
      </a>{' '}
      and we will send you the seals, debiting the money from your personal account on the site. We apologize for the temporary inconvenience.
    </>
  ),
  emailAlreadyExist: 'This email already exists',
  linkSendingToEmail: (
    <>
      Confirmation link <br /> sent to email
    </>
  ),
  unexpectedError: 'An unexpected error has occurred',
  clearCart: 'Clear Cart',
  emptyCart: 'Cart is empty',
  afterPaymentText: ' After payment, the money will be credited to your website account. Next, do not forget to donate the game currency to the game, the "Donate" button under the ID input. The money will be debited from your personal account.',
  editorAvatar: 'Avatar editor',
  back: 'Back',
  inCart: 'Add to cart',
  addPackToCart: 'Do you want to add pack to cart?',
  promocodeCopy: 'promocode copied',
  activeDuring: 'active during',
  promoNotActive: 'promo code is inactive',
  generateNew: 'Generate new',
  accruedAllTime: 'Acrued in total for the program',
  accruedToday: ' Accrued today',
  refillShort: 'Refill account',
  refill: 'Refill personal account',
  currentMethodText: 'This deposit method is more of a reserve method. It sometimes fails to pay. It is better to use the account replenishment through the game page - the "buy" button in the pack block, having collected the order basket.',
  moneyBoxText: '0.5% of all donations come to your piggy bank, after reaching a certain amount you can take them.',
  numPack: 'Number of packs',
  refProgramm: 'Referral program',
  refProgrammText: ` Below is your referral program code. If a user has registered under your code, then you will receive 1% of his donation to your personal account for the duration of the code. The code is valid for 30 days, then you need to create a new one (the corresponding button will appear). For administrators
                  communities have special offers. If you have a community, please contact us for exclusive terms.`,
  ourPromo: 'Your promo code',
  promocode: 'Promocodes',
  idCopy: 'ID copied',
  idNotFound: 'ID not found',
  newPasswordSendToMail: 'New password sent to email',

  games: 'Games',
  incorrectPromoCode: 'Incorrect promo code',
  emailExist: 'This email already exists',
  reCaptchaFailed: 'reCaptcha failed',
  promoCodeNotReq: 'Promo code (optional)',
  repeatPassword: 'Repeat password',
  passwordNotEqual: "Passwords don't match",
  signIn: 'Input',
  signInGoogle: 'Sign in with Google',
  or: 'or',
  register: 'Register',
  password: 'Password',
  moneyOver: 'Insufficient funds',
  promoCodesOver: 'Promo codes are over',
  min: 'min',
  max: 'max',
  willBeCredited: 'Will be credited',
  selectServer: 'Select a server',
  idTransaction: 'Transaction ID',
  loading: 'Loading...',
  refundedSuccess: 'Refunded',
  enter: 'Enter',
  sending: 'Sent',
  completed: 'Completed',
  inProgress: 'Progressing',
  inputServer: 'Your Zone ID',
  ourGameId: 'Your Game ID',
  inputAgain: 'Enter again',
  ourNick: 'Your nickname',
  donateOnlyAndroid: 'Donate is only available for accounts created on Android',
  acceptDonate: 'Are we confirming the donation?',
  yourDonateSending: (
    <>
      {' '}
      Your donation is being sent, wait until <br /> ends and don't close the page
    </>
  ),
  topUpAccountText: 'Through this button you can top up your personal account on the site for any amount. Payment by card, Qiwi, YooMoney.',
  topUpAccount: ' Top Up Account',
  nowDay: 'today',
  days: 'days',
  topUp: 'Top Up',
  topUpReserve: 'Top up account (reserve)',
  topUpBalance: 'Top Up Balance',
  topUpYourAccount: 'Top up your account with',
  save: 'Save',
  take: 'Take',
  yes: 'Yes',
  no: 'No',
  moneyBox: 'Piggy bank',
  saving: 'Savings',
  close: 'Close',
  supportUs: 'Support us',
  change: 'Change',
  name: 'Name',
  donate: 'Donate',
  pageNotFound: 'Page not found',
  termsOfUse: 'User Agreement',
  privacyPolicy: 'Privacy Policy',
  privacyPolicyText: (
    <>
      The confidentiality of your data is one of the priorities of our service. This document contains a list of the information we collect and how we use it.
      <br />
      <br />
      Cookies
      <br />
      We use cookies on our website. By using the Donate Gold website, you agree to the use of cookies in accordance with our privacy policy.
      <br />
      <br />
      Logging
      <br />
      We use standard log files to record actions on the site. When a user is on the site, the standard methods of logging actions on the site are recorded in log files. The IP of users, browser type, date and time of visiting the page, transitions to other pages and clicks. This data does not
      identify the user in any way. This information is intended only for general analysis of user behavior on the site.
      <br />
      <br />
      What data we collect and store
      <br />
      - User email - used for user registration and required for authorization.
      <br />
      We do not verify the accuracy of this data provided by users, and do not exercise control over their legal capacity. However, we proceed from the fact that the user provides reliable information, the data indicated by him during registration belongs to him personally and maintains this
      information up to date. The risk of providing false information is borne by the user who provided it.
      <br />
      <br />
      - User balance on the site, amounts, dates, number and name of purchases.
      <br />
      The specified data is collected and stored for statistical information, as well as to provide this data to a specific user of his data.
      <br />
      <br />
      All the above data is kept strictly confidential, except in cases where the user voluntarily provides information about himself for general access to an unlimited number of people.
      <br />
      <br />
      The Service has the right to transfer the specified user data to third parties if the transfer is provided for by Russian or other applicable legislation within the framework of the procedure established by law.
      <br />
      <br />
      The user has the right to completely delete his account with all his user data.
      <br />
      <br />
      We reserve the right to make changes to this privacy policy. The new version of the privacy policy comes into force from the moment it is published on this page.
      <br />
      <br />
    </>
  ),
  returnPolicy: 'Return Policy',
  refundPolicy: 'Refund Policy',
  refundPolicyText: (
    <>
      {' '}
      Return of in-game currency
      <br />
      Any replenishment of game currency is final.
      <br />
      If you entered the wrong game ID, then after replenishing the game currency, a refund is not possible.
      <br />
      We are not responsible for the loss or theft of your game account.
      <br />
      <br />
      The right to a refund from the balance of your personal account
      <br />
      Funds from your personal balance of our service can be returned in full. If your personal account was funded from a WebMoney wallet, a full refund will be made to the same wallet from which you transferred funds.
      <br />
      To return, contact us by e-mail support@donate-gold.ru
      <br />
      <br />
    </>
  ),
  idSuccess: 'ID verified',
  incorrectLoginOrPass: 'Incorrect login or password',
  check: 'Check',
  acceptEmail: 'Confirm email',
  rememberMail: 'Remember mail',
  forgotPassword: 'Forgot your password?',
  zoom: 'Zoom',
  email: 'Mail',
  technicalSupport: 'Technical support',
  technicalSupportText: (
    <>
      For site inquiries please contact:
      <br />
      ◦ Chat support (bottom right)
      <br />◦ Telegram{' '}
      <a class="link" href="https://t.me/DonateGold" target="_blank">
        @DonateGold
      </a>{' '}
      <br />
      ◦ email - support@donate-gold.ru
      <br />
      <br />
      Opening hours: daily from 10:00 to 24:00
      <br />
      <br />
      <br />
      <img src="/v_white_on_transp_ru.png" />
      <br />
      <a class="link" href="https://passport.webmoney.ru/asp/certview.asp?wmid=386255644072" target="_blank">
        <span>Check passport</span>
      </a>
      <br />
      <br />
      Our mascot is a Japanese cat Maneki-neko, who wishes happiness to those who use the service. The author of the logo and idea is our service user{' '}
      <a class="link" href="https://vk.com/my.chicken.nuggets" target="_blank">
        {' '}
        Yang Wei.
      </a>
      <br />
      <br />
    </>
  ),
  accept: 'Confirm',
  game1Name: 'Donate to the game Identity V',
  game1BottomDesc: (
    <>
      Identity V is an asymmetrical multiplayer survival horror game developed by the Chinese company NetEase Games. The game is available on mobile phones and PC.
      <br />
      <br />
      There are two factions in the game - Survivors and Hunters. On various maps, the Survivors try to escape from the Hunter, using their individual abilities, and get out of the territory. The hunter, in turn, must catch them before they escape. The game is designed in the Gothic style of the
      early 20th century, the developers were inspired by the style of Tim Burton and the game mechanics of Dead by Daylight.
      <br />
      <br />
      <a class="link" href="https://identityvgame.com">
        Official website of the game
      </a>
      <br />
      <br />
      <img src="/idv-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game1Desc: (
    <>
      <ul className="game_list">
        <li className="game__list-item"> Seals are added to the game instantly, each pack contains bonus seals</li>
        <li className="game__list-item"> Your donation is completely legal, seals are counted towards Miss Nightingale's accumulative game account</li>
        <li className="game__list-item"> If there was an error when donating, try again or write to us</li>
        <li className="game__list-item"> We are not responsible if you provide the wrong ID</li>
        <li className="game__list-item"> Money is first credited to your personal account of the site, then you donate it to the game</li>
      </ul>
    </>
  ),
  game1Privacy: (
    <>
      You will donate game currency on this page in favor of NetEase Games. We are not the copyright holder of the game currency Identity V, we do not determine the procedure for its use and functioning in the game. By making a donation to the game Identity V you agree with this{' '}
      <a class="link" href="https://game.longeplay.com.tw/member/complete_agreement">
        user agreement
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        user agreement Donate Gold
      </a>{' '}
      and our{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        privacy policy
      </a>
      .
    </>
  ),
  game1Meta: (
    <Helmet>
      <title>Donate to Identity V</title>
      <meta name="description" content="How to donate to Identity V? You need to specify an ID and a server. The donation comes instantly. Donate to Identity V in 2024." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game2Name: 'Donate to Genshin Impact game',
  game2BottomDesc: (
    <>
      Genshin Impact is an open-world action-adventure video game with RPG elements developed by the Chinese company miHoYo Limited.
      <br />
      <br />
      According to the plot, a hero named Traveler wanders in the fantasy world of Teyvat in search of his twin sister (or brother). Along the way, participating in the affairs of this world. He is accompanied by his companion Paimon and other playable characters. Each character has unique abilities
      based on one of the seven elements.
      <br />
      <br />
      <a class="link" href="https://genshin.hoyoverse.com">
        Official website of the game
      </a>
      <br />
      <br />
      <img src="/genshin-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game2Desc: (
    <>
      <ul className="game_list">
        <li className="game__list-item"> On this page you can donate to the game Genshin Impact yourself, specifying only the UID</li>
        <li className="game__list-item"> Donations are absolutely legal, our site automates replenishment through official providers (Codashop, RazerGold, etc.)</li>
        <li className="game__list-item"> If you donate to the game for the first time, you will receive 2 crystals from each pack</li>
        <li className="game__list-item"> With each pack you get bonus crystals</li>
        <li className="game__list-item"> Donations come from 1 minute to ~30. Time depends on server load</li>
        <li className="game__list-item"> For PlayStation users, after donating, you need to log into your account from a PC or phone, linking it to PlayStation beforehand, and the crystals will come to your account</li>
        <li className="game__list-item"> If there was an error when donating, try again or write to us</li>
        <li className="game__list-item"> We are not responsible if you provided an invalid UID</li>
        <li className="game__list-item"> Money is first credited to your personal account of the site, then you donate it to the game</li>
      </ul>
    </>
  ),
  game2privacy: (
    <>
      You will donate game currency on this page in favor of HoYoverse (miHoYo). We are not the copyright holder of the game currency Genshin Impact, we do not determine the procedure for its use and functioning in the game. By making a donation to the game Genshin Impact you agree with this{' '}
      <a class="link" href="https://genshin.hoyoverse.com/en/company/privacy">
        {' '}
        user agreement
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        user agreement Donate Gold
      </a>{' '}
      and our{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        privacy policy
      </a>
      .
    </>
  ),
  game2Meta: (
    <Helmet>
      <title>Genshin Impact Donat</title>
      <meta name="description" content="How to donate to Genshin Impact? You need to specify the UID and the server. The donation comes instantly. Donate to Genshin Image in 2024. Buy primogems." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  game3Name: 'Donate to Onmyoji Arena game',
  game3BottomDesc: (
    <>
      Onmyoji Arena is a MOBA mobile game developed by Chinese company NetEasy Games. It is a spin-off of the Onmyoji series.
      <br />
      <br />
      The game is based on the Japanese movie "Sorcerer", which tells about the events of the Heian period. There are many Shikigami characters in the game with their own unique traits and classes. Among the game modes there are both classic 5v5 matches and 3v3v3 Battle Royale. The game is
      constantly updated.
      <br />
      <br />
      <a class="link" href="https://www.onmyojiarena.us">
        Official website of the game
      </a>
      <br />
      <br />
      <img src="/onmyoji-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game3Desc: (
    <>
      <ul className="game_list">
        <li className="game__list-item"> On this page, you can donate to the Onmyoji Arena game yourself, specifying only the ID</li>
        <li className="game__list-item"> Donations are absolutely legal, our site automates replenishment through official providers (Codashop, RazerGold, etc.)</li>
        <li className="game__list-item"> With each pack you get a bonus game currency</li>
        <li className="game__list-item"> Donations come instantly</li>
        <li className="game__list-item"> If there was an error when donating, try again or write to us</li>
        <li className="game__list-item"> We are not responsible if you provide the wrong ID</li>
        <li className="game__list-item"> Money is first credited to your personal account of the site, then you donate it to the game</li>
      </ul>
    </>
  ),
  game3privacy: (
    <>
      You will donate game currency on this page in favor of NetEase Games. We are not the copyright holder of the Onmyoji Arena game currency, we do not determine the procedure for its use and functioning in the game. By making a donation to the game Onmyoji Arena you agree to the following{' '}
      <a class="link" href="https://www.onmyojiarena.us/law.html">
        user agreement
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        user agreement Donate Gold
      </a>{' '}
      and our{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        privacy policy
      </a>
      .
    </>
  ),
  game3Meta: (
    <Helmet>
      <title>Donate at Onmyoji Arena</title>
      <meta name="description" content="How to donate to Onmyoji Arena? You need to provide an ID and a server. The donation comes instantly. Donate to Onmyoji Arena in 2024." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),

  game4Name: 'Donate to The Lord of the Rings: Rise to War',
  game4BottomDesc: (
    <>
      The Lord of the Rings: Rise to War is a mobile strategy based on the cult trilogy of JRR Tolkien developed by NetEase Games. In Russian translation, it sounds like The Lord of the Rings: The Battle of the Hosts.
      <br />
      <br />
      During seasonal games, you will have to gather commanders and expeditionary units, expand your possessions, collect valuable resources and defeat numerous armies of enemies. Experience and strength will help you overcome any surprises and dangers on your way.
      <br />
      <br />
      <a class="link" href="https://www.lotr-risetowar.com">
        Official website of the game
      </a>
      <br />
      <br />
      <img src="/onmyoji-arena-bg.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game4Desc: (
    <>
      <ul className="game_list">
        <li className="game__list-item"> On this page you donate to the game The Lord of the Rings: Battle of the Armies by yourself, specifying only the ID and the server</li>
        <li className="game__list-item"> Donations are absolutely legal, our site automates replenishment through official providers (Codashop, RazerGold, etc.)</li>
        <li className="game__list-item"> With each pack you get a bonus game currency</li>
        <li className="game__list-item"> You will receive double gems of each pack when you purchase them for the first time on your account</li>
        <li className="game__list-item"> If you have exceeded the purchase limit of an item in the game, it will be converted into gems</li>
        <li className="game__list-item"> Donations come instantly</li>
        <li className="game__list-item"> If there was an error when donating, try again or write to us</li>
        <li className="game__list-item"> We are not responsible if you provide the wrong ID</li>
        <li className="game__list-item"> Money is first credited to your personal account of the site, then you donate it to the game</li>
        <li className="game_list-item">
          {' '}
          <span>Season Pass is purchased for the duration of the season, if you buy it a second time, it will turn into gems</span>
        </li>
        <li className="game__list-item"> Build Queue is bought for 30 days, if you buy it a second time, it will turn into gems</li>
      </ul>
    </>
  ),
  game4privacy: (
    <>
      You will donate game currency on this page in favor of NetEase Games. We are not the copyright holder of the game currency The Lord of the Rings: Rise to War, we do not determine the procedure for its use and functioning in the game. By making a donation to the game The Lord of the Rings: Rise
      to War you agree with data{' '}
      <a class="link" href="https://unisdk.update.netease.com/html/latest_v30.html">
        user agreement
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        user agreement Donate Gold
      </a>{' '}
      and our{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        privacy policy
      </a>
      .
    </>
  ),
  game4Meta: (
    <Helmet>
      <title>Donate to The Lord of the Rings: Rise to War</title>
      <meta name="description" content="How to donate to The Lord of the Rings? You need to specify the ID and the server. The donation comes instantly. Donate to The Lord of the Rings in 2024. Donate to the Lord of the Rings Battle of the Armies." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),

  game5Name: 'Donate to the game Mobile legends',
  game5BottomDesc: (
    <>
      Mobile Legends: Bang Bang is a mobile multiplayer online MOBA game developed by Moonton.
      <br />
      <br />
      Two 5 vs 5 teams fight in the arena, destroying the enemy's castle while defending their own. Each player controls their character as a hero, who has various unique abilities. Each party of the game is unique, and includes both tactical and strategic techniques. In the game more 100
      characters. In addition to the classic mode, there are arcade games and 3 vs 3 mode.
      <br />
      <br />
      <a class="link" href="https://mobilelegends.com">
        Official website of the game
      </a>
      <br />
      <br />
      <img src="/ml-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game5Desc: (
    <>
      <ul className="game_list">
        <li className="game__list-item"> On this page you can donate to the game Mobile legends Bang Bang yourself, specifying only the ID</li>
        <li className="game__list-item"> Donations are absolutely legal, our site automates replenishment through official providers (Codashop, RazerGold, etc.)</li>
        <li className="game__list-item"> With each pack you get a bonus game currency</li>
        <li className="game__list-item"> Donations come instantly</li>
        <li className="game__list-item"> If there was an error when donating, try again or write to us</li>
        <li className="game__list-item"> We are not responsible if you provide the wrong ID</li>
        <li className="game__list-item"> Money is first credited to your personal account of the site, then you donate it to the game</li>
      </ul>
    </>
  ),
  game5Privacy: (
    <>
      You will donate game currency on this page in favor of Moonton. We are not the copyright holder of the game currency Mobile legends: Bang Bang, we do not determine the procedure for its use and functioning in the game. By making a donation to the game Mobile legends: Bang Bang you agree to the
      following{' '}
      <a class="link" href="https://m.mobilelegends.com/en/newsdetail/475">
        user agreement
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        user agreement Donate Gold
      </a>{' '}
      and our{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        privacy policy
      </a>
      .
    </>
  ),
  game5Meta: (
    <Helmet>
      <title>Donate to the game Mobile legends</title>
      <meta name="description" content="How to donate to Mobile legends: Bang Bang? You need to specify an ID and a server. The donation comes instantly. Donate to Mobile legends in 2024." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),

  game6Name: 'Donate to PUBG Mobile (Global)',
  game6BottomDesc: (
    <>
      PUBG Mobile is a mobile multiplayer online battle royale game developed by LightSpeed ​​& Quantum Studio. The game is a mobile version of the original PlayerUnknown's Battlegrounds developed by PUBG Corporation.
      <br />
      <br />
      Players are parachuted onto an island where up to a hundred players fight each other. There must be the only survivor or the only team left.
      <br />
      <br />
      <a class="link" href="https://www.pubgmobile.com">
        Official website of the game
      </a>
      <br />
      <br />
      <img src="/pubg-about.jpg" style={{ maxWidth: '100%' }} />
    </>
  ),
  game6Desc: (
    <>
      <ul className="game_list">
        <li className="game__list-item"> On this page you can donate to the PUBG Mobile (Global) game yourself, specifying only the ID</li>
        <li className="game__list-item"> Donations are absolutely legal, our site automates replenishment through official providers (Codashop, RazerGold, etc.)</li>
        <li className="game__list-item"> With each pack you get a bonus game currency</li>
        <li className="game__list-item"> Donations arrive within 1-5 minutes</li>
        <li className="game__list-item"> If there was an error when donating, try again or write to us</li>
        <li className="game__list-item"> We are not responsible if you provide the wrong ID</li>
        <li className="game__list-item"> Money is first credited to your personal account of the site, then you donate it to the game</li>
      </ul>
    </>
  ),
  game6Privacy: (
    <>
      You will donate game currency on this page in favor of LightSpeed ​​& Quantum Studio. We are not the copyright holder of the game currency LightSpeed ​​& Quantum Studio, we do not determine the procedure for its use and functioning in the game. By donating to the game The Lord of the Rings:
      Rise to War you agree with this{' '}
      <a class="link" href="http://www.pubgmobile.com/terms.html">
        user agreement
      </a>
      ,{' '}
      <a class="link" href=" https://donate-gold.ru/terms-of-use">
        user agreement Donate Gold
      </a>{' '}
      and our{' '}
      <a class="link" href="https://donate-gold.ru/privacy-policy">
        privacy policy
      </a>
      .
    </>
  ),
  game6Meta: (
    <Helmet>
      <title>Donate to PUBG Mobile (Global)</title>
      <meta name="description" content="How to donate to PUBG Mobile (Global)? Just enter your ID. Donate comes instantly. Donate to PUBG Mobile (Global) in 2024. Donate to pubg mobile." />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  homePage: (
    <Helmet>
      <title>Main Page</title>
      <meta name="description" content="Donate Gold is a service that allows you to donate to mobile and computer games yourself. How to donate to a game? There is an answer!" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  accountPage: (
    <Helmet>
      <title>List of games</title>
      <meta name="description" content="Games you can donate to" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  authPage: (
    <Helmet>
      <title>Authorization</title>
      <meta name="description" content="Login" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  aboutPage: (
    <Helmet>
      <title>About the service</title>
      <meta name="description" content="The service allows you to donate to games" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  donatePage: (
    <Helmet>
      <title>Instructions on how to donate</title>
      <meta name="description" content="Read and see carefully how to donate to games" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  rulesPage: (
    <Helmet>
      <title>Terms of use of the service</title>
      <meta name="description" content="Web site documentation" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  supportPage: (
    <Helmet>
      <title>Support</title>
      <meta name="description" content="Support Contacts" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  termsOfUsePage: (
    <Helmet>
      <title>User Agreement</title>
      <meta name="description" content="Web site documentation" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  privacyPolicyPage: (
    <Helmet>
      <title>Privacy Policy</title>
      <meta name="description" content="Web site documentation" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  faqPage: (
    <Helmet>
      <title>Questions and answers</title>
      <meta name="description" content="FAQ" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  reviewPage: (
    <Helmet>
      <title>Reviews from our users</title>
      <meta name="description" content="Donation reviews" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  guaranteedPage: (
    <Helmet>
      <title>Guarantees</title>
      <meta name="description" content="Our guarantees" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  returnPolicyPage: (
    <Helmet>
      <title>Return Policy</title>
      <meta name="description" content="Refund policy" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
  profilePage: (
    <Helmet>
      <title>User Profile</title>
      <meta name="description" content="User settings and other data" />
      <link rel="canonical" href="https://donate-gold.ru/" />
    </Helmet>
  ),
};
