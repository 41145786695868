import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import MinusIcon from '@mui/icons-material/Remove';
import { blue } from '@mui/material/colors';
import { Box, DialogActions, DialogContent, DialogContentText, IconButton, Input, InputAdornment, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { currencyFormat } from '../utils/currencyFormat';
import { useDispatch, useSelector } from 'react-redux';
import { initPaymentCard, resetInitPaymentCardState, setCart, setUpdateCartEmpty } from '../redux/slices/user.slice';
import Loading from './Loading';
import axios from 'axios';
import md5 from 'md5';
import { useEffect } from 'react';
import TransitionDialog from './TransitionDialog';
import { LocalSeeSharp } from '@mui/icons-material';
import { locales } from '../locales';
import { initPaymentCardSecond } from '../redux/slices/user.slice';
import Loader from './Loader';
// export const paymentData = [
//   { id: '1', image: '/pay-1.png', name: 'Карта', value: 'card', type: '3' },
//   { id: '2', image: '/pay-5.svg', name: 'СБП', value: 'sbp', type: '3' },
//   { id: '3', image: '/pay-6.svg', name: 'Киви', value: 'qiwi', type: '3' },
//   // { id: '4', image: '/pay-3.png', name: 'Юмани', value: 'yoomoney', type: '3' },
// ];
export default function PaymentCardModal(props) {
  const { onClose, price, open, supportCount } = props;
  const [sum, setSum] = React.useState(1);

  const {
    initPaymentCardState: { loading: loadingPayment, data: dataPayment, error: errorPayment },
    initPaymentCardSecondState: { loading: loadingPaymentSecond, data: dataPaymentSecond, error: errorPaymentSecond },
    cart,
  } = useSelector((state) => state.user);
  const handleClose = () => {
    onClose();
  };
  const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();
  const {
    getUserState: { data: user },
  } = useSelector((state) => state.user);
  const {
    getTypePaymentListState: { data: paymentData },
  } = useSelector((state) => state.app);
  const onSubmit = () => {
    if (value) {
      const totalPrice = cart?.map((cartItem) => cartItem?.price * cartItem?.count).reduce((partialSum, a) => partialSum + a, 0);
      const supportSum = supportCount * 50;
      const selectPayment = paymentData?.find((pay) => pay?.id == value);
      dispatch(initPaymentCardSecond({ price: totalPrice, priceSupport: supportSum, type: selectPayment?.value, typePay: selectPayment?.type }));
    }
  };
  useEffect(() => {
    if (paymentData?.length >= 1) {
      setValue(paymentData?.[0]?.id);
    }
  }, [paymentData]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (dataPayment?.result) {
      window.location.href = dataPayment?.result;
      localStorage.removeItem('cart');
      dispatch(setUpdateCartEmpty());
      dispatch(resetInitPaymentCardState());
    }
  }, [dataPayment]);
  useEffect(() => {
    if (dataPaymentSecond?.url) {
      window.location.href = dataPaymentSecond?.url;
      localStorage.removeItem('cart');
      dispatch(setUpdateCartEmpty());
      dispatch(resetInitPaymentCardState());
    }
  }, [dataPaymentSecond]);
  console.log(cart);

  return (
    <Dialog
      TransitionComponent={TransitionDialog}
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '520px', // Set your width here
        },
      }}>
      {}
      <DialogContent sx={{ height: { xs: '300px', mob: '300px' } }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h5" sx={{ maxWidth: { xs: '203px', mob: 'none' }, marginBottom: '20px', mt: '10px', mx: 'auto', fontWeight: '600', fontSize: { mob: '24px', textAlign: 'center' } }}>
            {locales[process.env.REACT_APP_LOCALE].topUpYourAccount} <br />
            {`${currencyFormat(cart?.map((cartItem) => cartItem?.price * cartItem?.count).reduce((partialSum, a) => partialSum + a, 0) + supportCount * 50)}?`}
          </Typography>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group" sx={{ mb: '8px', mx: { xs: 'auto', mob: '0' }, color: '#fff !important' }}>
              Способ пополнения
            </FormLabel>
            <RadioGroup
              sx={{ display: 'grid', gridTemplateColumns: { xs: 'auto ', mob: 'auto auto auto' }, margin: { xs: '0 auto', mob: '0' }, rowGap: '20px', columnGap: '10px', justifyContent: 'start' }}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}>
              {paymentData?.map((item, index) => (
                <div>
                  <FormControlLabel
                    sx={{ '& img': { userSelect: 'none', pointerEvents: 'none', boxSizing: 'border-box', p: '0px', width: '100px', height: '60px', objectFit: 'contain', display: 'block' } }}
                    value={item?.id}
                    control={
                      <Radio
                        sx={{
                          '& [data-testid="RadioButtonUncheckedIcon"]': {
                            color: item?.id == value ? 'primary.main' : 'rgba(256,256,256,0.2)',
                          },
                        }}
                      />
                    }
                    label={
                      <>
                        <img src={item?.image} style={{}} />
                        <Box style={{ textAlign: 'center', marginTop: '8px' }}>{item?.name}</Box>
                      </>
                    }
                  />
                </div>
              ))}
            </RadioGroup>
          </FormControl>
          {(loadingPayment || dataPayment) && <Loader />}
        </div>
      </DialogContent>
      <DialogActions sx={{ mt: '16px', display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '8px', padding: '16px' }}>
        <Button color="error" onClick={handleClose} autoFocus variant="contained">
          {locales[process.env.REACT_APP_LOCALE].back}
        </Button>
        <Button color="success" autoFocus variant="contained" sx={{ margin: '0 !important', color: '#fff' }} onClick={onSubmit}>
          {locales[process.env.REACT_APP_LOCALE].topUp}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
