import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { AccountBalance, Person, PhotoCamera } from '@mui/icons-material';
import { setActiveNavMenu } from '../redux/slices/app.slice';
const TopHeader = () => {
  const dispatch = useDispatch();
  const menuData = [
    {
      name: 'Статьи',
      link: '/articles',
    },
    {
      name: 'Отзывы',
      link: '/reviews',
    },

    {
      name: 'Поддержка',
      link: '/support',
    },
  ];
  const menuDataAuth = [
    {
      name: 'Статьи',
      link: '/articles',
    },
    {
      name: 'Отзывы',
      link: '/reviews',
    },
    {
      name: 'Рейтинг',
      link: '/rating',
    },
    {
      name: 'Поддержка',
      link: '/support',
    },
    {
      name: 'Личный кабинет',
      link: '/profile',
      isProfile: true,
    },
  ];

  // const [menu, setMenu] = useState(menuData);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { activeNavMenu } = useSelector((state) => state.app);
  const {
    getUserState: { loading, data, error },
  } = useSelector((state) => state.user);

  const itemRefs = useRef([]);

  const menu = data ? menuDataAuth : menuData;

  useEffect(() => {
    if (pathname && itemRefs.current?.length >= 1) {
      const indexItem = menu?.findIndex((item) => item?.link == pathname);
      if (indexItem != -1) {
        const styleItem = window.getComputedStyle(itemRefs.current[indexItem]);
        let width = parseFloat(styleItem.width?.replace('px')) + 20;
        if (indexItem == itemRefs?.current?.length - 1) {
          width = width - 39;
        }
        let leftAll = 0;
        menu?.map((item, index) => {
          if (index < indexItem) {
            leftAll += parseFloat(window.getComputedStyle(itemRefs.current[index]).width?.replace('px')) + 20;
          }
        });

        const activeNavObj = {
          left: leftAll,
          width: `${width}px`,
        };
        dispatch(setActiveNavMenu(activeNavObj));
      } else {
        dispatch(setActiveNavMenu(null));
      }
      // if (indexItem >= 1) {

      // }
    }
  }, [pathname, itemRefs]);

  return (
    <>
      <Box sx={{ display: { mobile: 'flex', xs: 'none' }, justifyContent: 'end', borderBottom: '1px solid #222', height: '44.44px' }}>
        <Box
          onClick={() => {
            navigate('/');
          }}
          component={'img'}
          src="/logotext.png"
          sx={{ objectFit: 'contain', width: '104px', height: '10px', position: 'absolute', left: '30px', top: '18px' }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'end', padding: '10px 16px 10px 0', position: 'relative' }}>
          <Box sx={{ height: '24px', width: activeNavMenu, background: 'rgb(237, 188, 11)', borderRadius: '6px', position: 'absolute', left: `${activeNavMenu?.left}px`, transition: 'all 0.3s' }}></Box>
          {menu?.map((item, index) => (
            <Box
              ref={(el) => (itemRefs.current[index] = el)}
              sx={{
                zIndex: 2,
                padding: '0 10px',
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                userSelect: 'none',
                cursor: 'pointer',
                color: '#fff',
                transition: 'all 0.3s',
                '&:hover': {
                  color: 'rgb(237, 188, 11)',
                },
              }}
              onClick={(e, data) => {
                navigate(item?.link);
              }}>
              {item?.name}
              {item?.isProfile ? (
                <Box sx={{ marginLeft: '15px' }}>
                  {data?.avatar ? (
                    <img style={{ width: '20px', height: '20px', objectFit: 'cover', borderRadius: '50%' }} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${data?.avatar}`} />
                  ) : (
                    <Box sx={{ transition: 'all 0.3s', position: 'relative', width: '20px', height: '20px', borderRadius: '50%', border: `2px solid ${item?.link !== pathname ? 'rgba(255, 255, 255,0.3)' : 'rgb(237, 188, 11)'}` }}>
                      <Person sx={{ fontSize: '16px', color: '#666', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute', color: item?.link !== pathname ? 'rgba(255, 255, 255,0.3)' : 'rgb(237, 188, 11)' }} />
                    </Box>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default TopHeader;
