import { Add, Delete, FavoriteOutlined, Remove } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Container, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { locales } from '../locales';
import { getPackage } from '../redux/slices/package.slice';
import { getPayments } from '../redux/slices/payment.slice';
import { createTransactionReset, getTransactions } from '../redux/slices/transaction.slice';
import { getUser, sendPackageInChat, setCart, setUpdateCartEmpty } from '../redux/slices/user.slice';
import { currencyFormat } from '../utils/currencyFormat';
import DrawerAppBar from '../components/MainLayout';
import TotalCart from '../components/TotalCart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InputCart from '../components/InputCart';
const CartPage = ({ meta }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [supportCount, setSupportCount] = useState(0);

  const dispatch = useDispatch();
  const {
    getTypePaymentListState: { data: paymentData, loading: loadingTypePayment },
  } = useSelector((state) => state.app);
  const {
    getUserState: { loading, data, error },
    cart,
  } = useSelector((state) => state.user);
  const {
    getCreditCardState: { error: errorCreditCard },
  } = useSelector((state) => state.creditCard);
  const {
    getPackageState: { data: dataPackages, error: errorGetPackage },
  } = useSelector((state) => state.package);

  const navigate = useNavigate();

  const dispath = useDispatch();

  // const onChangeCartCount = ({}) => {
  //   let updateCount = parseInt(event.target.value);

  //   if (updateCount < 1 || isNaN(updateCount)) {
  //     updateCount = 1;
  //   } else if (updateCount > 100) {
  //     updateCount = 100;
  //   }
  //   let cartData = JSON.parse(localStorage.getItem('cart'));
  //   if (cartData instanceof Array) {
  //     const findDeletedItem = cartData?.find((cartDataItem) => cartDataItem.packageId === itemCart?.packageId);
  //     if (findDeletedItem !== -1) {
  //       findDeletedItem.count = updateCount;
  //       localStorage.setItem('cart', JSON.stringify(cartData));
  //       dispatch(setUpdateCartEmpty());
  //     }
  //   }
  // };

  return (
    <>
      {meta}
      <DrawerAppBar>
        {paymentData && (
          <>
            {' '}
            {cart?.length > 0 ? (
              <Box sx={{ paddingTop: 4, marginBottom: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                  <Typography variant="h4" sx={{ mt: { mobile: 2, xs: 1 } }}>
                    Корзина
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: { mobile: '16px', xs: '14px' }, mb: 4, maxWidth: '100%', mt: { mobile: 2, xs: 1 } }}>
                    После оплаты деньги поступят на ваш лицевой счет сайта. Далее, вы можете задонатить игровую валюту в любую игру, кнопка "Задонатить" под вводом игровых данных. Деньги спишутся с вашего лицевого счета.
                  </Typography>{' '}
                  <Box sx={{ display: 'grid', gridTemplateColumns: { mobile: '1fr 1fr', sx: '1fr' }, width: '100%', columnGap: '20px', rowGap: '40px' }}>
                    {' '}
                    <Box>
                      {cart?.map((itemCart, indexCart) => (
                        <div style={{ marginBottom: indexCart == cart?.length - 1 ? '0' : '10px', background: '#fff', borderRadius: '6px', padding: '15px 15px 15px 20px', paddingBottom: '14px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', flexWrap: { mobile: 'nowrap', xs: 'wrap' } }}>
                            <Box className="game-card__middle" sx={{ marginBottom: { mobile: '0', xs: '10px' }, display: 'flex', alignItems: 'center', margin: '0px', fontSize: '14px', flexBasis: { mobile: 'auto', xs: '100%' } }}>
                              <img
                                src={itemCart?.packageId === 0 ? '/rub.svg' : `/game-icon-${dataPackages?.find((findPack) => findPack?.id === itemCart?.packageId)?.typeGameId}.png`}
                                style={{
                                  objectFit: 'contain',
                                  display: 'block',
                                  ...(itemCart?.packageId === 0 ? { width: '20px', height: '20px', marginRight: '5px', marginLeft: '-2px' } : { width: '22px', height: '22px' }),
                                }}
                              />
                              {itemCart?.name}
                            </Box>
                            <InputCart
                              isTopup={itemCart?.packageId === 0}
                              count={itemCart?.count}
                              onChange={({ value, isAdd, isMinus }) => {
                                const minCount = itemCart?.packageId === 0 ? 100 : 1;
                                const maxCount = itemCart?.packageId === 0 ? 75000 : 100;
                                let cartData = JSON.parse(localStorage.getItem('cart'));
                                if (cartData instanceof Array) {
                                  const findDeletedItem = cartData?.find((cartDataItem) => cartDataItem.packageId === itemCart?.packageId);
                                  if (findDeletedItem !== -1) {
                                    let updateCount;
                                    if (value) {
                                      updateCount = parseInt(value);
                                    } else if (isAdd) {
                                      updateCount = parseInt(findDeletedItem.count) + 1;
                                    } else if (isMinus) {
                                      updateCount = parseInt(findDeletedItem.count) - 1;
                                    }

                                    if (updateCount < minCount || isNaN(updateCount)) {
                                      updateCount = minCount;
                                    } else if (updateCount > maxCount) {
                                      updateCount = maxCount;
                                    }
                                    findDeletedItem.count = updateCount;
                                    localStorage.setItem('cart', JSON.stringify(cartData));
                                    dispatch(setUpdateCartEmpty());
                                  }
                                }
                              }}
                            />

                            <div style={{ display: 'flex', alignItems: 'center', width: itemCart?.packageId === 0 ? '30px' : '94px', whiteSpace: 'nowrap', justifyContent: 'end' }}>
                              {itemCart?.packageId !== 0 && <div style={{ fontSize: '18px' }}>{currencyFormat(itemCart?.price * itemCart?.count)}</div>}

                              <IconButton
                                onClick={() => {
                                  let cartData = JSON.parse(localStorage.getItem('cart'));
                                  if (cartData instanceof Array) {
                                    const findDeletedItem = cartData?.findIndex((cartDataItem) => cartDataItem.packageId === itemCart?.packageId);
                                    if (findDeletedItem !== -1) {
                                      cartData.splice(findDeletedItem, 1);
                                      localStorage.setItem('cart', JSON.stringify(cartData));
                                      dispatch(setUpdateCartEmpty());
                                    }
                                  }
                                }}
                                size="small"
                                disableRipple
                                sx={{ marginLeft: '5px' }}>
                                <CloseIcon sx={{ fontSize: '18px', opacity: '0.5', color: '#000' }} />
                              </IconButton>
                            </div>
                          </Box>
                        </div>
                      ))}
                      {/* <Box sx={{ background: '#fff', borderRadius: '6px', padding: '15px 15px 15px 20px', paddingBottom: '14px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', flexWrap: { mobile: 'nowrap', xs: 'wrap' } }}>
                          <Box className="game-card__middle" sx={{ marginBottom: { mobile: '0', xs: '10px' }, display: 'flex', alignItems: 'center', margin: '0px', fontSize: '14px', flexBasis: { mobile: 'auto', xs: '100%' } }}>
                            <FavoriteOutlined sx={{ width: '20px', height: '20px', color: '#9c2628', marginRight: '4px' }} />
                            {locales[process.env.REACT_APP_LOCALE].supportUs}
                          </Box>
                          <InputCart
                            count={supportCount}
                            onChange={({ value, isAdd, isMinus }) => {
                              let updateCount;
                              if (value) {
                                updateCount = parseInt(value);
                              } else if (isAdd) {
                                updateCount = parseInt(supportCount) + 1;
                              } else if (isMinus) {
                                updateCount = parseInt(supportCount) - 1;
                              }

                              if (updateCount < 0 || isNaN(updateCount)) {
                                updateCount = 0;
                              } else if (updateCount > 100) {
                                updateCount = 100;
                              }

                              setSupportCount(updateCount);
                            }}
                          />

                          <div style={{ width: '94px' }}>
                            <div style={{ fontSize: '18px', marginTop: '0px', paddingRight: '33px', whiteSpace: 'nowrap', marginLeft: 'auto', width: 'min-content' }}>{currencyFormat(50 * supportCount)}</div>
                          </div>
                        </Box>
                      </Box> */}
                      <Button
                        onClick={() => {
                          localStorage.removeItem('cart');
                          dispatch(setUpdateCartEmpty());
                        }}
                        color="error"
                        sx={{ '&:hover': { backgroundColor: 'transparent' }, marginTop: '16px', textTransform: 'none' }}>
                        <Delete sx={{ marginRight: '5px' }} />
                        {locales[process.env.REACT_APP_LOCALE].clearCart}
                      </Button>
                    </Box>
                    <TotalCart total={cart?.map((cartItem) => cartItem?.price * cartItem?.count).reduce((partialSum, a) => partialSum + a, 0) + 50 * supportCount} supportCount={supportCount} />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box style={{ position: 'relative', height: '100%', minHeight: '350px' }}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ textAlign: 'center', marginBottom: '30px', fontSize: '24px', whiteSpace: 'nowrap' }}>Корзина пуста</Box>
                  <Button
                    onClick={() => {
                      navigate('/');
                    }}
                    variant="contained">
                    На главную
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </DrawerAppBar>
    </>
  );
};

export default CartPage;
