import { Box, Button, Container, IconButton, TextField, Tooltip, Typography, Paper, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import Alert from '@mui/material/Alert';
import DrawerAppBar from '../components/MainLayout';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { currencyFormat } from '../utils/currencyFormat';
import { generatePromoCode, getUser, resetGeneratePromoCode } from '../redux/slices/user.slice';
import moment from 'moment';
import Loading from '../components/Loading';
import PaymentModal from '../components/PaymentModal';
import { a11yProps, TabPanel } from './Home';
import TrancTable from '../components/TrancTable';
import PaymentTable from '../components/PaymentTable';
import BonusMenu from '../components/BonusMenu';
import ProfileAvatar from '../components/ProfileAvatar';
import AvatarModal from '../components/AvatarModal';
import SettingPage from '../components/SettingPage';
import Top100Table from '../components/Top100Table';
import SupportListTable from './SupportListTable';
import { locales } from '../locales';
const RatingPage = ({ meta }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {meta}
      {/* <DrawerAppBar> */}
      <Paper sx={{ height: 'auto', marginTop: '22px' }}>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', height: 'auto' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ justifyContent: 'center' }}>
              <Tab label={locales[process.env.REACT_APP_LOCALE].top100} {...a11yProps(0)} />
              <Tab label={locales[process.env.REACT_APP_LOCALE].forMonth} {...a11yProps(1)} />
              <Tab label={locales[process.env.REACT_APP_LOCALE].usSupport} {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0} sx={{}}>
            {value == 0 && <Top100Table />}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {value == 1 && <Top100Table onlyMonth />}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SupportListTable />
          </TabPanel>
        </Box>
      </Paper>
      {/* </DrawerAppBar> */}
    </>
  );
};

export default RatingPage;
