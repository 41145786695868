import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import { Box } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AccessTime } from '@mui/icons-material';
const ArticleItem = ({ slug, name, image, articleCategories, shortDesc, createdAt }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: '100%',
          cursor: 'pointer',
          '&:hover span': {
            color: '#1976d2 !important',
          },
        }}
        onClick={() => {
          navigate(`/articles/${slug}`);
        }}>
        <Box component={'img'} sx={{ objectFit: 'cover', aspectRatio: '1.1/1', borderRadius: '40px', display: 'block', width: '100%', mb: '18px' }} src={`${process.env.REACT_APP_SERVER_DOMAIN}/images/${image}`} />
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'start' }}>
          {articleCategories?.map((cat) => (
            <Box sx={{ marginRight: '8px', marginBottom: '11px', padding: '6px 14px', backgroundColor: 'hsl(0, 0%, 97%)', fontSize: '14px', borderRadius: '50px' }}>{cat?.name}</Box>
          ))}
        </Box>
        <Box
          sx={{
            mt: '11px',
            fontSize: '22px',
            span: {
              transition: 'all 0.3s',
              fontWeight: '600 !important',
            },
          }}>
          <Box component={'span'} style={{ color: '#000', textDecoration: 'none' }}>
            {name}
          </Box>
        </Box>

        <Box sx={{ mt: '11px', lineHeight: '28.8px', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{shortDesc}</Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderTop: '1px solid rgba(117, 117, 117, 0.15)',
            paddingTop: '20px',
            marginTop: '20px',
            fontSize: '14px',
            color: '#757575',
            fontWeight: '400 !important',
          }}>
          <AccessTime sx={{ mb: '1px', fontSize: '16px', mr: '4px' }} />
          {moment(createdAt).format('MMMM DD, YYYY в HH:mm')}
        </Box>
      </Box>
    </>
  );
};

export default ArticleItem;
